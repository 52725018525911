/**
 * Apply z-indexes to the elements in $z-indexes in order starting from 1.
 * To use, put the element in the appropriate ordinal place and call zIndex() with it as the argument.
 */

$z-indexes: (
  'select-after',
  'select',
  'checkbox',
  'slider-nav',
  'loader',
  'filter-detail-sticky',
  'newsletter-sticky',
  'accept-cookies',
  'header-sticky',
  'filter-menu-sticky',
  'navigation-bar',
  'minisearch',
  'minicart',
  'loading',
  'modals',
  'notices'
);

$z-index-map: ();
$count: 1;

@each $item in $z-indexes {
  $z-index-map: map-merge($z-index-map, ($item: $count));
  $count: $count + 1;
}

@mixin zIndex($key, $important: '') {
  z-index: map-get($z-index-map, $key) #{$important};
}

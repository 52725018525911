@import '../settings/layout';
@import '../settings/colours';
@import '../tools/mixins';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
/**
 * Competitions And News
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--competitions-and-news {
  $_text-padding: 30px;
  $_text-gutter: 10px;

  @include pb-reset-elements;

  padding: 100px 0 !important;

  [data-content-type='column-group'] {
    @include site-wrapper(!important);

    // section header
    &:first-child {
      text-align: center;

      [data-content-type='column'] {
        padding: 0 $_text-gutter !important;
      }

      [data-content-type='heading'] {
        @include text--heading-3;
        margin-bottom: 25px !important;

        @include media('>=md') {
          @include text--heading-2;
        }
      }

      [data-content-type='text'] {
        max-width: 700px;
        margin: auto !important;
      }

      [data-content-type='button-item'] {
        margin-top: 15px;
      }
    }

    // promotion rows
    &:not(:first-child) {
      flex-direction: column;
      margin: 60px auto 0 !important;

      @include media('>=tablet') {
        flex-direction: row;
      }

      [data-content-type='column'] {
        width: 100% !important;

        &:last-child {
          justify-content: center !important;
          padding: $_text-padding $_text-gutter 0 !important;
        }
      }

      [data-content-type='image'] {
        img {
          width: 100%;
        }
      }

      [data-content-type='heading'] {
        @include text--competitions-and-news-heading;
        margin: 5px 0 20px !important;
      }

      [data-content-type='button-item'] {
        margin-top: 10px;
      }

      &:nth-child(even) {
        [data-content-type='column']:last-child {
          @include media('>=tablet') {
            padding: 0 0 0 $_text-padding !important;
          }
        }
      }

      &:nth-child(odd) {
        @include media('>=tablet') {
          flex-direction: row-reverse;
        }

        [data-content-type='column']:last-child {
          @include media('>=tablet') {
            padding: 0 $_text-padding 0 0 !important;
          }
        }
      }
    }
  }

  [data-content-type='text'] {
    &:not(:first-child) {
      @include text--home-paragraph-text;

      @include media('>=md', '<tablet') {
        font-size: 18px;
      }

      @include media('>=desktop') {
        font-size: 19px;
      }
    }

    > * {
      @include first-last-no-end-margins(!important);
    }
  }

  [data-content-type='button-item'] {
    @include button--more-cta-link-with-arrow--light-uppercase(
      $anchor-selector: '> a'
    );
  }
}

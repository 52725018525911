@import '@aligent/frontend-toolkit/scss/mixins/reset-ul';
@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-typography';
@import '../tools/z-index';
/**
 * Styling for the sticky header
 */

.header-sticky {
  $top-bar-height: 36px;
  $top-bar-height--desktop: 52px;
  $main-height: 75px;
  $main-height--tablet: 85px;
  $main-height--desktop: 95px;
  $logo-height: 30px;
  $logo-height--tablet: 40px;
  $logo-height--desktop: 35px;

  @include transition('border-color');
  @include zIndex('header-sticky');
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $color--neutral--white;
  box-shadow: $color--ui--shadow-header;

  .search-toggle::before {
    @include icon('search', 'solid');
  }

  .search-close {
    @include button-style--icon;
    position: absolute;
    top: 20px;
    right: 0;

    &::before {
      @include icon('close', 'light');
    }

    @include media('>=tablet') {
      right: 20px;
    }
  }

  .header__search {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
    pointer-events: none;

    .header__search__curtain {
      @include curtain;
    }

    .header__search__form {
      @include transition('transform');
      position: fixed;
      width: 100%;
      background: $color--ui--sticky-bg;
      box-shadow: $color--ui--shadow-sticky-top;
      transform: translateY(-110%);

      &::after {
        @include gold-strip;
      }
    }

    .search-autocomplete {
      opacity: 0;
    }

    &.toggle-search--active {
      display: block;
      pointer-events: auto;

      .header__search__curtain {
        @include curtain--active;
      }

      .header__search__form {
        transform: translateY(0);
      }

      .search-autocomplete {
        opacity: 1;
      }
    }
  }

  .header__top-bar {
    @include text--tiny;
    @include transition('height');
    background: $color--ui--bg-dark;
    height: $top-bar-height;
    font-weight: 700;
    overflow: hidden;

    .content {
      flex-direction: row;
      align-items: center;
    }

    @include media('>=desktop') {
      @include text--small;
      font-weight: 700;
      height: $top-bar-height--desktop;
    }
  }

  .header__top-links {
    @include reset-ul;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .header__top-links--grouped {
      @include reset-ul;
      display: flex;

      .header-top-link:not(:last-child) {
        margin-right: 24px;
      }
    }

    .header-top-link {
      text-transform: uppercase;
      text-align: center;

      a {
        @include link('dark', 'light');
      }

      .highlight {
        @include gold-text-gradient;
      }
    }

    @include media('<tablet') {
      justify-content: center;

      li:not(.header-top-link--nearest-store) {
          display: none;
      }

      &,
      .header-top-link {
        @include pos-abs-fill;
      }

      .header-top-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
      }
    }
  }

  .header__links {
    @include reset-ul;
    display: flex;
    align-items: center;

    .header-link {
      &:not(:first-child) {
        margin-left: 25px;

        @include media('>sm') { // !>= as want 320 iPhone5 to have smaller margins
          margin-left: 35px;
        }
      }

      .search-toggle,
      .account-dropdown-menu__icon,
      .minicart-toggle__button {
        @include button-style--icon;
        @include link('dark', 'light');
      }
    }

    /**
     * Header links are in two places top-bar and main
     * Style variances and toggle display on desktop
     */
    &.header__links--top-bar {
      margin-left: 50px;

      @include media('<desktop') {
        display: none;
      }
    }

    &.header__links--main {
      @include transition('margin');
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin-top: -5px;

      .search-toggle,
      .account-dropdown-menu__icon,
      .minicart-toggle__button {
        @include link;
        font-size: 20px;
      }

      @include media('>=desktop') {
        display: none;
      }
    }
  }

  .header__main {
    @include transition('height');
    height: $main-height;

    .content {
      position: relative;
    }

    @include media('>=tablet') {
      height: $main-height--tablet;
    }

    @include media('>=desktop') {
      height: $main-height--desktop;
    }
  }

  .header__logo {
    @include transition('margin');
    display: flex;
    position: absolute;
    top: 20px;
    margin-top: -5px;
    z-index: 1;

    .logo {
      display: flex;
    }

    .logo__img {
      @include transition('height');
      height: $logo-height;

      @include media('>=tablet') {
        height: $logo-height--tablet;
      }

      @include media('>=desktop') {
        height: $logo-height--desktop;
      }
    }

    .header__nav-toggle {
      @include button-style--icon;
      @include animation-hover-zoom(1.2, 1);
      font-size: 26px;
      margin-right: 15px;

      &::before {
        @include icon('menu');
      }

      @include media('>=tablet') {
        margin-right: 20px;
      }

      @include media('>=desktop') {
        display: none;
      }
    }
  }

  .header__welcome-text {
    @include transition('opacity, margin');
    @include text--micro;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 5px;
    text-transform: uppercase;

    @include media('>=md') {
      @include text--tiny;
      margin-left: 38px;
    }

    @include media('>=tablet') {
      margin-left: 43px;
    }

    @include media('>=desktop') {
      @include text--small;
      font-size: 13px;
      letter-spacing: 0.65px;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }

  /**
   * hc-sticky-scroll-classes-manager.js
   */
  &.hc-sticky-scroll--sticky {
    border-color: transparent;

    .header__main {
      height: 45px;
    }

    .header__links--main {
      margin: 0;
    }

    .header__logo {
      margin: 0;
      top: 50%;
      transform: translateY(-50%);

      .logo__img {
        height: 30px;
      }
    }

    .header__welcome-text {
      opacity: 0;
      margin-bottom: -20px;
    }

    &.hc-sticky-scroll--scrolling-down {
      .header__top-bar {
        height: 0;
      }
    }
  }
}

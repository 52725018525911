@import '../tools/mixins-typography';

.stockists-store-view {

  .store-location__top-banner {
    $color--banner-background: $color--ui--bg-dark;

    position: relative;
    left: 50%;
    transform: translateX(-50vw);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $color--banner-background no-repeat center;
    padding: 30px;
    min-height: 300px;

    @include media('>=lg') {
      min-height: 400px;
    }

    @include media('>=tablet') {
      min-height: 500px;
    }

    .location-name {
      @include text--heading-2;
      color: $color--ui--text-light-on-dark;
      margin-bottom: 20px;
      text-align: center;
    }

    .location-city {
      @include text--store-location-city;
      margin-top: 0;
    }

    .description-container {
      @include text--store-location-description;
      max-width: 600px;
      text-align: center;
    }
  }

  .announcement-bar-text {
    background: $color--brand--primary;
    position: relative;
    left: 50%;
    transform: translateX(-50vw);
    width: 100vw;
    text-align: center;
    padding: 19px 0;
    line-height: 1;
  }

  .store-location__info-container {
    padding-top: 50px;

    @include media('>=tablet') {
      padding-top: 80px;
    }
  }

  .store-location__info {
    display: grid;
    grid-row-gap: 30px;
    grid-template-areas: 'address'
    'phone'
    'trading'
    'special';

    @include media('>=tablet') {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'address trading'
      'phone special';
      grid-row-gap: 20px;
    }

    .address {
      grid-area: address;

      &:before {
        @include icon('map-pin', 'solid');
        color: $_color-gold-blend-to;
        vertical-align: top;
        margin: 8px 5px 0 0;
      }
    }

    .address-container {
      display: inline-block;
    }

    .link {
      color: $_color-gold-blend-to;
      text-decoration: underline;
      display: block;
    }

    .address__line {
      font-style: normal;
      margin: 0;
    }

    .phone-number {
      @include link('light');
      grid-area: phone;

      &:before {
        @include icon('phone', 'solid');
        color: $_color-gold-blend-to;
        margin-right: 5px
      }
    }

    .trading-hours {
      grid-area: trading;
    }

    .special-hours {
      grid-area: special;
    }

    .opening-hours__title {
      font-size: 1.2rem;
      margin: 0 0 10px;
    }

    .opening-hours__table {
      width: 100%;
    }

    .opening-hours__cell {
      width: 50%;
    }
  }

  .store-location__map {
    margin-top: 40px;
    height: 500px;
  }
}

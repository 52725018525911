/**
 * Contextual styling for wishlist
 * This page is part of the Account pages, so will receive some styling from that context too
 */

.wishlist-index-index {
  @import 'aligent-scss-placeholder/contexts/wishlist';

  .products-grid {

  }
  .actions-toolbar {

  }
}

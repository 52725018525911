@import '../settings/colours';
@import '../settings/layout';
@import '../settings/typography';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-button';
/**
 * PageBuilder mixins
 * !important used frequently to override inline styles
 */

// Use selector when needing to increase specificity
@mixin pb-reset-elements($selector: '&') {
  #{$selector} {
    [data-content-type='row'],
    [data-element='inner'],
      // if within site-wrapper clear inner site wrap applied to column-group from full-width page styling
    .site-wrapper [data-content-type='column-group'],
    [data-content-type='column-group'],
    [data-content-type='column'],
    [data-content-type='block'],
    [data-content-type='buttons'] {
      padding: 0 !important;
      margin: 0 !important;
      border: 0 !important;
    }
  }
}

@mixin pb-set-button-styles {
  &.button-style--default   { @include button-style--default; }
  &.button-style--primary   { @include button-style--primary; }
  &.button-style--secondary { @include button-style--secondary; }
  &.button-style--ghost     { @include button-style--ghost; }
  &.button-style--link      { @include button-style--link; }
  &.button-style--link-chevron {
    @include button--more-cta-link-with-arrow(
      $anchor-selector: '> a'
    );
  }
  &.button-style--link-chevron--light {
    @include button--more-cta-link-with-arrow--light-uppercase(
      $anchor-selector: '> a'
    );
  }
}

@mixin pb-columns($columns, $spacing-row: 0, $spacing-col: 0) {
  display: flex;
  flex-wrap: wrap;
  width: auto !important; // allows negative right margin to extend width without having to add with calc
  margin: (-$spacing-row/2) (-$spacing-col/2) !important;

  > div {
    display: flex;
    position: relative;
    width: (100% / $columns) !important;
    padding: ($spacing-row/2) ($spacing-col/2) !important;
  }
}

@mixin col-x($columns) {
  @for $i from 1 through $columns {
    .col-#{$i} {
      @include pb-columns($i);
    }
  }
}
@include col-x(10);

@mixin pagebuilder-mobile-only {
  display: block;

  @include media('>=lg') {
    display: none;
  }
}
.pagebuilder-mobile-only {
  @include pagebuilder-mobile-only;
}

@mixin pagebuilder-mobile-hidden {
  display: none;

  @include media('>=lg') {
    display: block;
  }
}
.pagebuilder-mobile-hidden {
  @include pagebuilder-mobile-hidden;
}

@mixin pagebuilder-desktop-only {
  @include media('<=tablet') {
    display: none !important;
  }
}
.pagebuilder-desktop-only {
  @include pagebuilder-desktop-only;
}

@mixin pagebuilder-desktop-hidden {
    @include media('>tablet') {
        display: none !important;
    }
}
.pagebuilder-desktop-hidden {
    @include pagebuilder-desktop-hidden;
}

@mixin pb-hero-title-description {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  max-width: $max-site-width;
  min-height: 300px;
  margin: auto;
  padding: 70px 30px !important;
  color: $color--neutral--alabaster;

  [data-content-type='heading'] {
    @include text--heading-3;
    color: inherit;

    @include media('>=md') {
      @include text--heading-2;
      color: inherit;
    }
    @include media('>=tablet') {
      @include text--heading-1;
      color: inherit;
    }
  }

  [data-content-type='text'] {
    @include text--base;
    color: inherit;
    max-width: 500px;
    margin: auto !important;

    > *:last-child {
      margin-bottom: 0;
    }

    @include media('>=lg') {
      @include text--medium;
      color: inherit;
    }
  }
}

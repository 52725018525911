@import '../settings/colours';
@import '../settings/typography';
@import '../tools/mixins';
@import '../tools/mixins-animation';
/**
 * Typography mixins
 */

/**
 * Typography styles
 */
@mixin text-style (
  $font-family: $font-family-body,
  $font-size: $base-font-size,
  $font-weight: 400,
  $letter-spacing: 1px,
  $line-height: $base-line-height,
  $color: $color--ui--text-body
) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
  color: $color;
}

@mixin text-heading-style (
  $font-family: $font-family-heading,
  $font-size: $base-font-size,
  $font-weight: 400,
  $letter-spacing: 1px,
  $line-height: $base-line-height,
  $color: $color--ui--text-heading
) {
  display: block;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
  color: $color;
  margin: 0;
  padding: 0;

  strong {
    font-weight: inherit;
  }
}

@mixin text-subheading-style (
  $font-family: $font-family-subheading,
  $font-size: $base-font-size,
  $font-weight: 400,
  $letter-spacing: 1px,
  $line-height: $base-line-height,
  $color: $color--ui--text-subheading
) {
  @include text-heading-style(
    $font-family: $font-family,
    $font-size: $font-size,
    $font-weight: $font-weight,
    $letter-spacing: $letter-spacing,
    $line-height: $line-height,
    $color: $color
  );
}

/**
 * Apply typography types
 */
@mixin text--base {
  @include text-style;
}

@mixin text--micro {
  @include text-style(
    $font-size: 10px,
    $font-weight: 300,
    $line-height: 1.4
  );
}

@mixin text--tiny {
  @include text-style(
    $font-size: 12px,
    $font-weight: 300,
    $line-height: 1.3
  );
}

@mixin text--small {
  @include text-style(
    $font-size: 14px,
    $line-height: 1.6
  );
}

@mixin text--medium {
  @include text-style(
    $font-size: 18px
  );
}

@mixin text--large {
  @include text-style(
    $font-size: 22px
  );
}

@mixin text--notices($color) {
  @include text-style(
    $line-height: 22px,
    $color: $color
  );
}

// HEADINGS
@mixin text--heading-1 {
  @include text-heading-style(
    $font-size: 32px,
    $line-height: 1.2
  );

  @include media('>=tablet') {
    @include text-heading-style(
      $font-size: 56px,
      $letter-spacing: 1.6px,
      $line-height: 1.3
    );
  }
}

@mixin text--heading-1--pdp {
  @include text--heading-1;
  line-height: 60px;
}

@mixin text--heading-2 {
  @include text-heading-style(
    $font-size: 44px,
    $letter-spacing: 1.5px,
    $line-height: 1.1
  );
}

@mixin text--heading-3 {
  @include text-heading-style(
    $font-size: 34px,
    $letter-spacing: -0.49px,
    $line-height: 1.2
  );
}

@mixin text--heading-3--mosaic-text-overlay {
  @include text--heading-3;
  letter-spacing: 1.5px;
}

@mixin text--heading-3--pdp {
  @include text--heading-3;
  line-height: 38px;
}

@mixin text--heading-4 {
  @include text-heading-style(
    $font-size: 28px,
    $letter-spacing: 2px,
    $line-height: 1.1
  );
}

@mixin text--heading-5 {
  @include text-subheading-style(
    $font-size: 22px,
    $letter-spacing: -0.16px
  );
}

@mixin text--heading-6 {
  @include text-subheading-style(
    $font-weight: 700,
    $line-height: 19px
  );
}

@mixin text--lead-text {
  @include text-subheading-style(
    $font-size: 18px,
    $font-weight: 700
  );
  text-transform: uppercase;
}

@mixin text--lead-text--regular {
  @include text--lead-text;
  font-weight: 400;
  letter-spacing: 0;
}

@mixin text--label-heading {
  @include text-style(
    $font-size: 18px,
    $font-weight: 300
  );
  text-transform: uppercase;
}

@mixin text--blog-listing-title {
  @include text-style(
    $font-size: 20px,
    $letter-spacing: 0.6px,
    $line-height: 1.3
  );
}

@mixin text--blog-listing-info {
  @include text-style(
    $font-size: 14px,
    $font-weight: 300
  );
  text-transform: uppercase;
}

@mixin text--custom-attribute-display {
  @include text-style(
    $font-size: 14px,
    $font-weight: 300,
    $line-height: 1.5
  );
  text-transform: uppercase;
}

@mixin text--custom-attribute-display-link {
  text-decoration: underline;

  &:hover {
    @include gold-text-gradient;
  }
}

@mixin text--hero-banner-heading {
  @include text-heading-style(
    $font-size: 34px,
    $letter-spacing: 1.1px,
    $line-height: 1.3
  );

  @include media('>md') {
    font-size: 44px;
  }
  @include media('>lg') {
    line-height: 1.4;
  }
  @include media('>=desktop') {
    font-size: 56px;
  }
}

@mixin text--competitions-and-news-heading {
  @include text-heading-style(
    $font-size: 28px,
    $letter-spacing: -0.49px,
    $line-height: 1.1
  );

  @include media('>=tablet') {
    font-size: 34px;
    line-height: 1.2;
  }
}

@mixin text--instant-search-heading {
  @include text-heading-style(
    $font-size: 30px,
    $letter-spacing: 1.1px,
    $line-height: 1.3
  );

  @include media('>=lg') {
    font-size: 34px;
    line-height: 1.2;
    letter-spacing: 1.5px;
  }
}

@mixin text--search-title-heading {
  @include text-style(
    $font-size: 22px,
    $font-weight: 300,
    $letter-spacing: 0
  );
  @include gold-text-gradient;

  @include media('>=md') {
    font-size: 28px;
    line-height: 1.1;
  }
}

@mixin text--search-title-keywords {
  @include text-heading-style(
    $font-size: 44px,
    $letter-spacing: 1.6px,
    $line-height: 1.1
  );

  @include media('>=lg') {
    font-size: 56px;
    line-height: 1.1;
  }
}

@mixin text--minicart-product-item-name {
  @include text-style(
    $font-weight: 300,
    $letter-spacing: 0,
    $line-height: 19px
  );
}

@mixin text--minicart-product-item-options {
  @include text--tiny;
  text-transform: uppercase;
}

// COMPONENTS
@mixin text--button {
  @include text-style(
    $font-weight: 700,
    $line-height: 19px
  );
  text-align: center;
  text-transform: uppercase;
}

@mixin text--page-messages {
  @include text-style(
    $font-weight: 600,
    $line-height: 22px
  );
  text-align: center;
  text-transform: uppercase;
}

@mixin text--minicart-price {
  @include text-style (
    $font-family: $font-family-serif,
    $letter-spacing: -0.11px,
    $line-height: 32px
  );
}

@mixin text--more-cta-link {
  @include text-style(
    $font-size: 14px,
    $font-weight: 700,
    $letter-spacing: 1.71px,
    $line-height: 1.6
  );
}

@mixin text--accordion-level-0 {
  @include text-style(
    $font-size: 22px,
    $font-weight: 600,
    $letter-spacing: 0.73px
  );
}

@mixin text--megamenu-top-level {
  @include text-style(
    $font-weight: 400,
    $letter-spacing: 1.71px
  );
}

@mixin text--list-heading-link {
  @include text-style(
    $font-weight: 700,
    $letter-spacing: 0.73px
  );
  text-transform: uppercase;
}

@mixin text--list-item-link {
  @include text-style(
    $letter-spacing: 0.73px
  );
  text-transform: none;
}

@mixin text--related-article-title {
  @include text-heading-style(
    $font-size: 24px
  );
}

@mixin text--tag {
  @include text-style(
    $font-size: 14px
  );
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text--product-card-price-label {
  @include text-style(
    $font-size: 14px,
    $line-height: 1.1
  );
  text-transform: uppercase;
}

@mixin text--product-card-price-label--from-label {
  @include text--product-card-price-label;
  font-weight: 300;
}

@mixin text--product-card-price {
  @include text-subheading-style(
    $font-size: 20px,
    $font-weight: 400,
    $letter-spacing: 0
  );
}

@mixin text--product-card-old-price {
  @include text-style(
    $font-size: 18px,
    $line-height: 18px,
    $font-weight: 300,
    $letter-spacing: -0.16px
  );
  text-transform: uppercase;
}

@mixin text--product-card-old-price-label {
  @include text--product-card-old-price;
  font-size: 14px;
  line-height: 18px;
}

@mixin text--pdp-section-title {
  @include text--large;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin text--pdp-price {
  @include text-subheading-style(
    $font-size: 24px,
    $font-weight: 600
  );
}

@mixin text--nutritional-info-column-label {
  @include text--micro;
  text-transform: uppercase;
  text-align: left;
}

@mixin text--pdp-accordion-title {
  @include text--large;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin text--store-locator-details {
  @include text-style(
    $line-height: 20px
  );
  font-style: normal;
}

@mixin text--store-locator-action {
  @include text-style(
    $font-size: 13px,
    $font-weight: 700,
    $line-height: 1.8
  );
  text-decoration: underline;
  text-align: left;
}

@mixin text--store-location-city {
  @include text-subheading-style(
    $font-size: 17px,
    $letter-spacing: 3px,
    $color: $color--ui--text-light-on-dark
  );
}

@mixin text--store-location-description {
  @include text-style(
    $font-size: 16px,
    $line-height: 1.4,
    $color: $color--ui--text-light-on-dark
  )
}

@mixin text--ghost-button {
  @include text-style(
    $font-size: 14px,
    $font-weight: 700
  );
  text-transform: uppercase;
  text-align: center;
}

@mixin text--error {
  @include text-style(
    $font-size: 11px,
    $line-height: 1.3,
    $font-weight: 700
  );
  text-transform: uppercase;
}

@mixin text--giftcard-note {
  @include text-style(
    $font-size: 17px,
    $font-weight: 300
  );
}

@mixin text--accept-cookies {
  @include text-style(
    $font-size: 14px,
    $font-weight: 700,
    $letter-spacing: 1.5px,
    $line-height: 1.6
  );
  text-transform: uppercase;
}

@mixin text--home-hero-promo-links {
  @include text-style(
    $font-size: 17px,
    $font-weight: 300,
    $line-height: 1.4
  );
}

@mixin text--home-hero-promo-links--heading {
  @include text--home-hero-promo-links;
  font-weight: 700;
}

@mixin text--home-paragraph-text {
  @include text-style(
    $font-size: 16px,
    $font-weight: 300
  );
}

@mixin text--icon-text {
  @include text-style(
    $font-weight: 300,
    $letter-spacing: 0
  );
  text-transform: none;
}

@mixin text--curated-collections {
  @include text-style(
    $font-weight: 300,
    $letter-spacing: 1.71px,
    $line-height: 20px
  );
  text-transform: uppercase;
}

@mixin text--pagination {
  @include text--large;
  font-weight: 300;
}

@mixin text--pagination--current-page {
  @include text--pagination;
  font-weight: 700;
}

@mixin text--radio-button {
  @include text-style(
    $font-weight: 300,
    $letter-spacing: 1.71px,
    $line-height: 16px
  );
}

@mixin text--checkout-title-text {
  @include text-style(
    $font-size: 18px,
    $letter-spacing: 1.01px,
    $line-height: 1.6,
    $color: $color--brand--tertiary,
  );
}

@mixin text--checkout-base-text {
  @include text-style(
    $font-weight: 300,
    $letter-spacing: 0.7px,
    $line-height: 20px
  );
}

@mixin text--checkout-message-text {
  @include text-style(
    $font-size: 11px,
    $font-weight: 300,
    $letter-spacing: 0.7px,
    $line-height: 1.3
  );
  text-transform: uppercase;
}

@mixin text--checkout-payment-text {
  @include text-style(
    $font-weight: 300,
    $letter-spacing: 0.7px,
    $line-height: 20px
  );
}

@mixin text--checkout-tab-ui-text {
  @include text-style(
    $font-size: 14px,
    $font-weight: 500,
    $letter-spacing: 0.61px,
    $line-height: 1.4
  );
  text-transform: uppercase;
}

@mixin text--checkout-headline-text {
  @include text-style(
    $font-size: 18px,
    $font-weight: 300,
    $letter-spacing: 0.7px,
    $line-height: 1.3
  );
}

@mixin text--checkout-sub-text {
  @include text-style(
    $font-size: 14px,
    $font-weight: 300,
    $line-height: 1.2,
    $letter-spacing: 0.61px
  );
}

@mixin text--checkout-total-modifier {
  @include text-style(
    $color: $color--brand--primary,
    $font-weight: 600,
    $font-size: 13px,
    $line-height: 16px,
    $letter-spacing: 0.56px,
  );
}

@mixin text--checkout-configuration-text {
  @include text-style(
    $font-size: 12px,
    $font-weight: 300,
    $letter-spacing: 1px,
    $line-height: 1.2,
    $color: $color--neutral--grey,
  );
}

@mixin text--checkout-button {
  @include text-style(
    $font-size: 14px,
    $line-height: 1,
    $font-weight: 700
  );
  text-transform: uppercase;
}

@mixin text--checkout-welcome-back {
  @include text-style(
    $font-size: 17px,
    $font-weight: 500,
    $line-height: 1.2,
    $letter-spacing: 0.7px
  );
}

@mixin text--checkout-item-price {
  @include text-style(
    $font-family: $font-family-heading,
    $letter-spacing: -0.11px,
    $line-height: 32px
  );
  text-transform: uppercase;
}

@mixin text--checkout-grandtotal-price {
  @include text-style(
    $font-size: 17px,
    $line-height: 1.4,
    $letter-spacing: 0.7px,
    $font-weight: 600
  );
  text-transform: uppercase;
}

@mixin text--checkout-totals-price {
  @include text-style(
    $font-size: 16px,
    $line-height: 1.5,
    $letter-spacing: 0.7px,
    $font-weight: 300,
  );
}

@mixin text--checkout-totals-label {
  @include text-style(
    $font-size: 16px,
    $line-height: 1.5,
    $letter-spacing: 0.7px,
    $font-weight: 600
  );
}

@mixin text--checkout-totals-label-info {
  @include text-style(
    $font-size: 15px,
    $line-height: 1.6,
    $font-weight: 300,
    $letter-spacing: 0.75px,
  );
}

@mixin text--checkout-autocomplete-suggestion {
  @include text-style(
    $font-size: 14px,
    $line-height: 1.2,
    $font-weight: 300
  );
}

@mixin text--checkout-autocomplete-subtext {
  @include text-style(
    $line-height: 16px,
    $font-weight: 300,
    $letter-spacing: 0.7px,
    $color: $color--neutral--grey
  );
}

@mixin text--checkout-shipping-price {
  @include text-style(
    $font-size: 14px,
    $font-weight: 500,
    $letter-spacing: 0.7px,
    $line-height: 1.7,
    $color: $color--neutral--mine-shaft
  );
}

@mixin text--checkout-free-gift-quantity {
  @include text-style(
    $font-size: 14px
  );
  text-transform: uppercase;
}

@mixin text--input-label($color) {
  @include text-style(
    $color: $color,
    $font-weight: 300,
    $letter-spacing: 0.7px
  );
}

@mixin text--input-checkbox-label {
  @include text-style(
    $font-weight: 300,
    $line-height: 24px,
    $letter-spacing: 0.7px
  );
}

@mixin text--footnote {
  @include text--small;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 0;
}

@mixin text--modal-heading {
  @include text--heading-4;
  letter-spacing: 0;
}

@mixin text--delivery-estimator-result-label {
  @include text-style(
    $font-size: 17px,
    $line-height: 1.1,
    $font-weight: 700
  );
}

@mixin text--delivery-estimator-results {
  @include text-style (
    $font-weight: 300,
    $line-height: 19px
  );
}

@mixin text--dinkus {
  @include text-style(
    $font-size: 11px,
    $font-weight: 500,
    $line-height: 2,
    $letter-spacing: 0.7px
  );
  text-transform: uppercase;
}

@mixin text--account-warning {
  @include text-style(
    $line-height: 20px,
    $font-weight: 300,
    $letter-spacing: 0.7px
  );
}

@mixin text--account-table {
  @include text-style(
    $font-size: 16px,
    $line-height: 1.1
  );
  text-align: left;
}

/**
 * Text Gradient
 * Apply text gradient with fallback
 */
@mixin text-gradient($gradient, $fallback-color) {
  color: $fallback-color;

  @supports (-webkit-background-clip: text) and
            (-webkit-text-fill-color: transparent) {
    background: $gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/**
 * Revert Text Gradient
 * Revert styles applied by text-gradient to allow set color
 */
@mixin revert-text-gradient() {
  color: inherit;
  background: transparent;

  @supports (-webkit-background-clip: text) and
            (-webkit-text-fill-color: transparent) {
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }
}

/**
 * Gold Text Gradient
 * Standard gold text gradient with fallback
 */
@mixin gold-text-gradient() {
  @include text-gradient(
    $color--brand--primary-blend,
    $color--brand--primary
  );
}

/**
 * Links
 * $bg: light | dark
 * $tone: default | light | medium
 */
@mixin link($bg: 'light', $tone: '') {
  @include transition('background, border, color');
  text-decoration: none;

  &::before,
  &::after {
    @include revert-text-gradient;
  }

  &:hover {
    cursor: pointer;
  }

  @if $bg == 'light' {
    color: $color--ui--link-text;

    &:hover {
      color: $color--ui--link-text-hover;
    }

    &:focus,
    &:active {
      color: $color--ui--link-text-active;
    }
  }

  @if $bg == 'dark' {
    @if $tone == 'light' {
      color: $color--ui--text-light-on-dark;
    }

    @if $tone == 'medium' {
      color: $color--ui--text-medium-on-dark;
    }

    @if $tone == 'light' or
        $tone == 'medium' {
      &:hover,
      &:focus,
      &:active {
        @include gold-text-gradient;
      }
    }
  }
}

.gift-message-container {
  position: relative;
  margin-bottom: 50px;

  .react-checkout-loading__block {
    position: absolute;
    width: auto;
    padding: 0;
    background: none;
    top: 0.5rem;
    left: auto;
    right: 0;
  }

  .gift-message__toggle-container {
    margin-top: 20px;
  }

  .gift-message-message-container {
    margin-bottom: 0;
  }

  .gift-message__message-info {
    @include text--checkout-sub-text;
    display: flex;
    justify-content: space-between;
    color: $color--neutral--grey;
  }

}

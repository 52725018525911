@import '../settings/colours';

.react-checkout {
  $_before-offset: -1px;
  $_before-offset-active: -2px;
  $_label-border-radius: $border-radius;
  $_label-border-radius-active: 8px;

  .tabbed-ui__tabs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 0;
    list-style: none;
  }

  .tabbed-ui__tab {
    display: inline-block;
    position: relative;
    width: calc(50% - 20px); // due to margin spacing and before absolute positioning
    height: 40px;
    flex-grow: 1;
    margin: 0 10px 20px;
    line-height: 1;
    text-align: center;
    color: $color--ui--tab-color;

    &::before {
      content: '';
      position: absolute;
      top: $_before-offset;
      left: $_before-offset;
      right: $_before-offset;
      bottom: $_before-offset;
      background: $color--neutral--dusty-grey;
      z-index: 0;
      border-radius: 10px;
    }

    &:active,
    &.active {
      outline: none;
      color: $color--ui--tab-color-active;

      &::before {

        top: $_before-offset-active;
        left: $_before-offset-active;
        right: $_before-offset-active;
        bottom: $_before-offset-active;
        background: $color--ui--tab-indicator-active;
      }

      .tabbed-ui__tab-label {
        border-radius: $_label-border-radius-active;
      }
    }

    &.disabled {
      outline: none;

      .tabbed-ui__tab-label {
        color: $color--ui--tab-indicator-disabled;
        cursor: default;
      }

      &::before {
        background: $color--ui--tab-indicator-disabled;
      }

      img {
        opacity: 0.3;
      }
    }
  }

  .tabbed-ui__tab--free {
    display: none;
  }

  .tabbed-ui__tab-label {
    @include text--checkout-tab-ui-text;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 5px;
    border-radius: $_label-border-radius;
    background: $color--ui--bg-default;
    white-space: pre;
    cursor: pointer;

    img {
      width: auto;
      max-width: 100%;
      height: 20px;
    }
  }

  .tabbed-ui__tab--simplifycommerce,.tabbed-ui__tab--pinpay {
    .tabbed-ui__tab-label {
      img {
        height: 30px;
      }
    }
  }

  .tabbed-ui__tab--paypal_express {
    .tabbed-ui__tab-label {
      img {
        margin-top: 5px;
      }
    }
  }

  .tabbed-ui__after-tab-content {
    display: flex;
    flex-direction: column;
  }
}

.checkout-app__payment-section .tabbed-ui {
  margin: 15px 0 30px;
}

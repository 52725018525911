@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Recently Viewed
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--recently-viewed {
  @include pb-reset-elements;

  .block-title [role='heading'] {
    @include text--heading-2;
    display: block;
    text-align: center;
    margin-bottom: 1em;
  }

  .block.widget {
    margin-bottom: 70px;
  }

  .product-items {
    $spacing: 30px;
    margin: 0;
    padding: 0;

    @include media('<small-tablet') {
      @include flex-grid(1, $spacing);
    }
    @include media('>=small-tablet', '<tablet') {
      @include flex-grid(2, $spacing);
    }
    @include media('>=tablet') {
      @include flex-grid(3, $spacing);
    }

    .price {
      @include text--product-card-price;
    }

    .price-box {
      margin-top: 15px;
    }

    .minimal-price-link .price {
      &::before {
        @include text--product-card-price-label--from-label;
        content: 'From';
        display: block;
        margin-bottom: 5px;
      }
    }

    .old-price {
      display: none;
    }

    .price-label { // Required for giftcards
      @include text--product-card-price-label--from-label;
      display: block;
      margin: 15px 0 5px;
    }
  }

  .product-item-actions,
  .product-item-description {
    display: none;
  }
}

@import '../settings/animation';
@import '../settings/colours';
@import '../settings/layout';
@import '../settings/typography';
@import '../tools/mixins-animation';
@import '../tools/mixins-layout';
@import '../tools/z-index';
/**
 * Mixins
 */

/**
 * Site content wrapper
 */
@mixin site-wrapper($important: '') {
  width: 100% #{$important};
  height: 100% #{$important};
  max-width: $max-site-width + $gutter-medium * 2;
  margin: auto #{$important};
  padding: 0 $gutter-tiny #{$important};

  @include media('>=md') {
    padding: 0 $gutter-small #{$important};
  }

  @include media('>=tablet') {
    padding: 0 $gutter-medium #{$important};
  }
}
.site-wrapper {
  @include site-wrapper;
}

/**
 * Hides elements visually, but allows screen readers to see them
 */
@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.visually-hidden {
  @include visually-hidden;
}

/**
 * Hides the up and down arrows from number input fields
 */
@mixin hide-number-input-spinners {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/**
 * Viewport curtains that cover the screen while component is focused
 */
@mixin curtain {
  @include transition('opacity', $transition-duration-slow);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $color--ui--curtain;
  pointer-events: none;
  opacity: 0;
}

@mixin curtain--active {
  pointer-events: auto;
  opacity: 1;
}

/**
 * Top/bottom borders with gradient
 */
@mixin gold-strip($height: 3px) {
  content: '';
  display: block;
  background: $color--brand--primary-blend;
  width: 100%;
  height: $height;
}

/**
 * Creates a before element, applies border width as padding and colour as background.
 */
@mixin border-as-background($border-width: 1px, $background: $color--brand--primary-blend) {
  background: $color--ui--bg-default;
  border: solid $border-width transparent;
  background-clip: padding-box;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border-width;
    background: $background;
  }
}

/**
 * Applies a gradient background to a container, and applies the border width as padding
 */
@mixin border-as-background-container($border-width: 1px, $background: $color--brand--primary-blend) {
  display: block;
  background: $background;
  padding: $border-width;
}


/**
 * Displays children in a grid format
 */
@mixin flex-grid($column-count, $column-gap, $row-gap: $column-gap, $important: '') {
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-right: $column-gap #{$important};
    margin-bottom: $row-gap #{$important};
    flex-basis: calc((100% - #{$column-gap * (#{$column-count - 1})}) / #{$column-count});
    flex-grow: 0;

    &:nth-child(#{$column-count}n) {
      margin-right: 0 #{$important};
    }
  }
}

/**
 * Styles the container element for groups of product cards
 */
@mixin product-grid {
  margin: 0;
  padding: 0;

  @include media('<tablet') {
    @include flex-grid(2, 15px, 70px);
  }

  @include media('>=tablet', '<desktop') {
    @include flex-grid(3, 20px, 70px);
  }

  @include media('>=desktop') {
    @include flex-grid(3, 32px, 70px);
  }
}

/**
 * Removes first top margin and last bottom margin for edge to edge content
 */
@mixin first-last-no-end-margins($important: '') {
  &:first-child {
    margin-top: 0 #{$important};
  }

  &:last-child {
    margin-bottom: 0 #{$important};
  }
}

/**
 * Text aligns left and last right. Sets width of last.
 * Commonly used for tables.
 * @param $width-last    width of last child (optional)
 */
@mixin text-align-left-last-right($width-last: '') {
  text-align: left;

  &:last-child {
    text-align: right;
    width: $width-last;
  }
}

/**
 * Clears both sides of an element with floated content to stop it from collapsing
 */
@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

/**
 * Prevents margins of children affecting parent relationships
*/
@mixin prevent-margin-collapse {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
}

/**
 * Modals
 *
 * toggled: hc-modals.js || magento modals
 */
@mixin modal (
  $curtain-selector: '.modal__curtain',
  $container-selector: '.modal__container',
  $content-selector: '.modal__content',
  $close-selector: '.modal__close',
  $heading-selector: '.modal__heading',
  $active-class: '.toggle-modal--active',
  $active-selector: '&',
  $active-curtain-selector: $curtain-selector,
  $modal-width: 530px,
  $important-z-index: ''
) {
  @include zIndex('modals', #{$important-z-index});
  @include fixed-fill;
  pointer-events: none;

  #{$curtain-selector} {
    @include curtain;
  }

  #{$container-selector} {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding: 10px;
  }

  #{$content-selector} {
    @include transition('transform, opacity');
    position: relative;
    background: $color--neutral--white;
    width: 100%;
    max-width: $modal-width;
    padding: 30px;
    box-shadow: $color--ui--shadow-modal;
    border: $color--ui--border;
    transform: scale(0.9);
    transform-origin: top;
    opacity: 0;

    @include media('>=small-tablet') {
      padding: 40px;
    }
  }

  #{$heading-selector} {
    @include text--modal-heading;
    margin-top: -7px; // pull up to keep cap-height at padding distance
    margin-bottom: -6px // pull up direct sibling to keep baseline at margin distance
  }

  #{$close-selector} {
    @include button-style--icon;
    position: absolute;
    top: 6px;
    right: 10px;

    &::before {
      @include icon('close', 'light');
      font-size: 22px;
    }

    @include media('>=small-tablet') {
      top: 16px;
      right: 20px;
    }
  }

  #{$active-selector} {
    // hides magento's stacked modals
    // side affect is it only animates in
    @if ($container-selector == '.modal-popup') {
      &:not(#{$active-class}) {
        @include visually-hidden;
      }
    }

    &#{$active-class} {
      pointer-events: auto;
      overflow-y: auto;

      #{$active-curtain-selector} {
        @include curtain--active;
      }

      #{$content-selector} {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

@mixin loading-spinner(
  $size: 30px,
  $color: $color--brand--primary,
  $duration: $transition-duration-slow,
  $timing: ease-in-out
) {
  position: relative;

  &::after {
    @include pos-abs-center;
    content: '';
    width: $size;
    height: $size;
    border-top: 3px solid $color;
    border-right: 3px solid transparent;
    border-radius: 50%;
    animation: animation-spinner $duration $timing infinite;
  }
}
.loading-spinner {
  @include loading-spinner;
}

/**
 * Pagination
 */
@mixin pagination() {
  margin: auto;

  .pages-items {
    @include reset-ul;
    display: flex;
    justify-content: center;
  }

  .action-icon {
    @include button-style--icon;
    font-size: 28px;
    width: 15px;
    height: 100%;

    &::before {
      @include gold-text-gradient;
    }
  }

  .action-icon--previous::before {
    @include icon('left-arrow', 'light');
  }

  .action-icon--next::before {
    @include icon('right-arrow', 'light');
  }

  .page-numbers {
    @include text--pagination;
    margin: 0 30px;
  }

  .page-numbers__current-page {
    @include text--pagination--current-page;
  }
}

@mixin warning-message($inner-selector: '.warning-message-inner-wrapper') {
  position: relative;
  margin: 20px 0;

  &::before {
    // Before element used to create gradient border
    $border-width: 1px;
    border-radius: 10px;
    content: '';
    background: $color--brand--primary-blend;
    position: absolute;
    top: -$border-width;
    right: -$border-width;
    bottom: -$border-width;
    left: -$border-width;
    z-index: 0;
  }

  .warning-icon {
    font-size: 27px;
    margin-right: 8px;
  }

  .warning-icon::before {
    @include icon('exclamation-circle');
    flex-shrink: 0;
    color: $color--brand--primary;
  }

  #{$inner-selector} {
    @include text--account-warning;
    position: relative;
    background: $color--ui--bg-default;
    border-radius: 9px;
    padding: 25px 28px;
    display: flex;
    z-index: 1;
  }
}

@mixin emblem-background {
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 740px;
    height: 890px;
    background: url('../images/emblem-740-890.png') no-repeat;
    transform: translate(-50%, -33%);
    opacity: 0.02;
    z-index: -1;
  }
}

/**
 * Colour variables
 */
// primary
$_color-harvest-gold: #DFBC78;
$_color-gold-blend-from: #F4D869;
$_color-gold-blend-to: #CCAD50;
$_color-gold-faded: #D2B4541A;
$_color-gold-blend: linear-gradient(144.32deg, $_color-gold-blend-from 0%, $_color-gold-blend-to 100%);
$_color-gold-blend-rev: linear-gradient(144.32deg, $_color-gold-blend-to 0%, $_color-gold-blend-from 100%);
$_color-gold-blend-light-from: #E1C080;
$_color-gold-blend-light-to: #FEDE93;
$_color-gold-blend-light: linear-gradient(144.32deg, $_color-gold-blend-light-from 0%, $_color-gold-blend-light-to 100%);
$_color-gold-dark: #AB9157;
// secondary
$_color-brown-derby: #4A2C16;
// tertiary
$_color-mine-shaft: #333333;
// accents
$_color-gamboge: #EB9508;
// neutral
$_color-white: #FFFFFF;
$_color-white-alpha: rgba(#FFFFFF, 0.5);
$_color-alabaster-light: #FCFCFC;
$_color-alabaster: #FAFAFA;
$_color-concrete: #F2F2F2;
$_color-red-cream: #F7F5F4;
$_color-red-cream-alpha: rgba($_color-red-cream, 0.3);
$_color-red-cream-alpha-2: rgba($_color-red-cream, 0.6);
$_color-brown-derby-alpha: rgba($_color-brown-derby, 0.1);
$_color-cream: #F6F4F3;
$_color-gallery: #EDEBEB;
$_color-cararra: #ECEBE5;
$_color-mercury: #E2E2E2;
$_color-french-grey: #CACACB;
$_color-silver-chalice: #ACACAC;
$_color-dusty-grey: #979797;
$_color-grey: #818181;
$_color-masala: #484443;
$_color-bronze: #42210B;
$_color-black: #000000;
// ui
$_color-ui-error: #D0021B;
$_color-ui-error-light: #FAE5E5;
$_color-ui-success: $_color-gold-blend;
$_color-ui-success-light: #E5EFE5;
$_color-ui-warning: $_color-red-cream;
$_color-ui-notice: $_color-red-cream;
$_color-ui-shadow: rgba($_color-black, 0.2);
$_color-ui-info: #C07602;
$_color-ui-info-light: #FDF0D5;
$_color-grey-text: #b9b9b9;

/**
 * Colour sets
 */
// brand
$color--brand--primary: $_color-harvest-gold;
$color--brand--primary-blend: $_color-gold-blend;
$color--brand--secondary: $_color-brown-derby;
$color--brand--tertiary: $_color-mine-shaft;
$color--brand--announcement: $_color-gold-dark;

// accent
// $color--accent--X: $X;

// neutral
$color--neutral--white: $_color-white;
$color--neutral--white-alpha: $_color-white-alpha;
$color--neutral--alabaster: $_color-alabaster;
$color--neutral--concrete: $_color-concrete;
$color--neutral--red-cream: $_color-red-cream;
$color--neutral--red-cream-alpha: $_color-red-cream-alpha;
$color--neutral--red-cream-alpha-2: $_color-red-cream-alpha-2;
$color--neutral--brown-derby: $_color-brown-derby;
$color--neutral--brown-derby-alpha: $_color-brown-derby-alpha;
$color--neutral--cream: $_color-cream;
$color--neutral--gallery: $_color-gallery;
$color--neutral--cararra: $_color-cararra;
$color--neutral--mercury: $_color-mercury;
$color--neutral--french-grey: $_color-french-grey;
$color--neutral--silver-chalice: $_color-silver-chalice;
$color--neutral--dusty-grey: $_color-dusty-grey;
$color--neutral--grey: $_color-grey;
$color--neutral--grey-alpha: rgba($_color-grey, 0.66);
$color--neutral--masala: $_color-masala;
$color--neutral--bronze: $_color-bronze;
$color--neutral--mine-shaft: $_color-mine-shaft;
$color--neutral--black: $_color-black;

// ui
$color--ui--bg-default: $_color-white;
$color--ui--bg-checkout: $_color-alabaster-light;
$color--ui--bg-light: $_color-red-cream-alpha;
$color--ui--bg-light-2: $_color-red-cream-alpha-2;
$color--ui--bg-light-3: $_color-cream;
$color--ui--bg-light-4: $_color-red-cream;
$color--ui--bg-dark: $_color-mine-shaft;

$color--ui--text-body: $_color-mine-shaft;
$color--ui--text-heading: $_color-mine-shaft;
$color--ui--text-subheading: $_color-mine-shaft;
$color--ui--text-light-on-dark: $_color-mercury;
$color--ui--text-medium-on-dark: $_color-french-grey;

$color--ui--button-default-bg: $_color-white;
$color--ui--button-default-bg-hover: $_color-white;
$color--ui--button-default-bg-active: $_color-alabaster;
$color--ui--button-default-text: $_color-black;
$color--ui--button-default-text-hover: $_color-mine-shaft;
$color--ui--button-default-text-active: $_color-black;

$color--ui--button-outline-bg: $_color-white;
$color--ui--button-outline-bg-hover: $_color-cream;
$color--ui--button-outline-bg-active: $_color-white;
$color--ui--button-outline-border-color: $_color-black;
$color--ui--button-outline-border-color-hover: $_color-mine-shaft;
$color--ui--button-outline-border-color-active: $_color-black;
$color--ui--button-outline-text: $_color-black;
$color--ui--button-outline-text-hover: $_color-mine-shaft;
$color--ui--button-outline-text-active: $_color-black;

$color--ui--button-primary-bg: $_color-gold-blend;
$color--ui--button-primary-bg-hover: $_color-gold-blend-light;
$color--ui--button-primary-bg-active: $_color-gold-blend;
$color--ui--button-primary-text: $_color-black;
$color--ui--button-primary-text-hover: $_color-black;
$color--ui--button-primary-text-active: $_color-white;

$color--ui--button-secondary-bg: $_color-black;
$color--ui--button-secondary-bg-hover: $_color-masala;
$color--ui--button-secondary-bg-active: $_color-black;
$color--ui--button-secondary-text: $_color-white;
$color--ui--button-secondary-text-hover: $_color-white;
$color--ui--button-secondary-text-active: $_color-harvest-gold;

$color--ui--button-radio-bg: $_color-white;
$color--ui--button-radio-bg-selected: $_color-mine-shaft;
$color--ui--button-radio-text: $_color-mine-shaft;
$color--ui--button-radio-text-selected: $_color-harvest-gold;

$color--ui--email-blend: linear-gradient(90deg, $color--neutral--cararra 40%, $color--neutral--white 100%);
$color--ui--email-blend-reversed: linear-gradient(90deg, $color--neutral--white 60%, $color--neutral--cararra 100%);

$color--ui--link-text: $_color-mine-shaft;
$color--ui--link-text-hover: $_color-black;
$color--ui--link-text-active: $_color-black;
$color--ui--link-text-highlight: $_color-gold-blend-to;
$color--ui--link-text--megamenu-seasonal: $_color-gold-blend-to;
$color--ui--link-text--megamenu-hover: $_color-gold-blend-to;

$color--ui--tag-bg: $_color-red-cream;

$color--ui--out-of-stock-text: $_color-gold-blend-to;

$color--ui--pdp-accordion-title-bg: $_color-red-cream;
$color--ui--pdp-accordion-content-bg: $_color-alabaster;
$color--ui--pdp-details-highlight: $_color-gold-blend-to;
$color--ui--pdp-details-bg: $_color-gold-faded;

$color--ui--filter-highlight: $_color-gold-blend-to;

$color--ui--input-bg: $_color-concrete;
$color--ui--input-bg-focus: $_color-white;
$color--ui--input-text: $color--ui--text-body;
$color--ui--input-text-placeholder: $_color-grey;
$color--ui--input-border: 0 0 0 1px inset $_color-grey; // apply to box-shadow to extrude
$color--ui--input-border-focus: 0 0 0 1px inset $_color-harvest-gold; // apply to box-shadow to extrude
$color--ui--input-border-error: 0 0 0 2px inset $_color-ui-error; // apply to box-shadow to extrude

$color--ui--accordion-bg: $_color-white;
$color--ui--accordion-bg-active: linear-gradient(90deg, $_color-concrete 0%, $_color-white 100%);
$color--ui--border-color: $_color-mine-shaft;
$color--ui--border-color-light: $_color-french-grey;
$color--ui--border-color-alpha: rgba($_color-mine-shaft, 0.33);
$color--ui--border: 1px solid $_color-mine-shaft;
$color--ui--border-alpha: 1px solid $color--ui--border-color-alpha;
$color--ui--border-alpha-2: 1px solid rgba($_color-gallery, 0.66);
$color--ui--border-light: 1px solid $_color-mercury;
$color--ui--border-medium: 1px solid $_color-dusty-grey;
$color--ui--curtain: rgba($_color_mine-shaft, 0.3);
$color--ui--divider: $_color-black;
$color--ui--overlay-image: rgba($_color-black, 0.66);
$color--ui--overlay-label: rgba($_color-black, 0.9);
$color--ui--shadow: $_color-ui-shadow;
$color--ui--shadow-light: rgba(229, 229, 229, 0.5);
$color--ui--shadow-soft-box: 0 0 4px 1px $color--ui--shadow-light;
$color--ui--shadow-sticky-top: 0 3px 6px $_color-ui-shadow;
$color--ui--shadow-sticky-left: 3px 0 6px $_color-ui-shadow;
$color--ui--shadow-sticky-right: -3px 3px 6px $_color-ui-shadow;
$color--ui--shadow-header: 0 0 3px $_color-ui-shadow;
$color--ui--shadow-megamenu: 0 0 6px $_color-ui-shadow;
$color--ui--shadow-menu-dropdown: 1px 1px 2px $_color-ui-shadow;
$color--ui--shadow-modal: 2px 4px 6px 2px $_color-ui-shadow;
$color--ui--shadow-filter-options: 0 0 12px 12px $color--ui--shadow-light;
$color--ui--shadow-date-picker: 0 2px 4px 0 $_color-mercury;
$color--ui--slider-indicator-bg: rgba($_color_mine-shaft, 0.5);
$color--ui--slider-indicator-bg-active: $_color_mine-shaft;
$color--ui--sticky-bg: $_color-concrete;

$color--ui--info: $_color-ui-info;
$color--ui--info-bg: $_color-ui-info-light;
$color--ui--invalid: $_color-ui-error;
$color--ui--error: $_color-white;
$color--ui--error-bg: $color--ui--invalid;
$color--ui--success: $_color-mine-shaft;
$color--ui--success-bg: $_color-ui-success;
$color--ui--warning: $_color-gamboge;
$color--ui--warning-bg: $_color-ui-warning;
$color--ui--notice: $_color-mine-shaft;
$color--ui--notice-bg: $_color-ui-notice;

$color--ui--tab-color: $_color-grey;
$color--ui--tab-color-active: $_color-black;
$color--ui--tab-icon: $_color-red-cream;
$color--ui--tab-icon-active: $_color-gold-blend-rev;
$color--ui--tab-indicator: $_color-red-cream;
$color--ui--tab-indicator-active: $_color-gold-blend;
$color--ui--tab-indicator-disabled: $_color-mercury;
$color--ui--tab-indicator-final: $_color-gold-blend-rev;

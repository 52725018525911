@import '../tools/mixins-typography';
@import '../tools/mixins-form-elements';

/**
 * Styling for form elements
 */

form {
  .field:not(.choice):not(.note):not(.qty) {
    @include input-style;
    margin-bottom: 1rem;

    &.is-textarea {
      $text-area-height: 100px;

      @include input-style($input-height: $text-area-height, $error-class: '.has-error');
      @include h-textarea;

      .control {
        @include h-input-wrap($input-height: $text-area-height);
        top: 18px;
      }

      .label {
        @include h-input-label($input-height: $text-area-height);
        top: 8px;
      }
    }

    .label {
      @include h-input-label;
    }

    &.is-focused,
    &.has-value {
      .label {
        @include h-input-label-active;
      }
    }

    .control {
      @include h-input-wrap;
    }

    &.is-dropdown {
      .label {
        @include h-input-label-active; // select always active state
      }

      .control {
        @include select-style;

        &::after {
          top: 55%;
          right: 24px;
        }
      }
    }

    input,
    select {
      @include h-input-reset;
    }

    textarea {
      @include h-input-reset(
        $input-offset-top: 0
      );
      height: 100%;
      font-weight: 300;
      line-height: 22px;
      resize: none;
    }
  }

  .field.choice {
    @include styled-checkbox(
      $input-class: '.checkbox',
      $label-class: '.label'
    );
  }

  .field.captcha {
    overflow: initial !important; // Overrides .field:not(.choice):not(.note):not(.qty)
  }

  .fieldset {
    border: 0;
  }

  .section-label {
    display: block;
    margin-bottom: 0.75em;
  }

  .date-picker-wrapper {
      display: flex;

      .ui-datepicker-trigger {
          padding: 0 1em;
          font-size: 12px;
          white-space: nowrap;
          background: transparent;
      }
  }

  div.mage-error {
    @include h-mage-error;
  }
}

@import '../settings/colours';
@import '../tools/mixins-pagebuilder';
/**
 * Blog Header Banner
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--blog-header-banner {
  position: relative;
  left: 50%;
  transform: translateX(-50vw);
  width: 100vw;
  margin: 0 !important; // overrides pagebuilder styling
  padding: 0 !important; // overrides pagebuilder styling

  [data-content-type='column-group'] {
    background: $color--ui--overlay-image;
  }

  [data-content-type='column'] {
    @include pb-hero-title-description;
    margin: auto !important; // overrides pagebuilder styling
  }

  [data-content-type='heading'] {
    color: inherit !important; // overrides typography mixin styling
  }
}

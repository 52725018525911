@import '../settings/layout';
@import '../tools/mixins-typography';
/**
 * Styling for the Contact page
 */

.contact-index-index {
  .column.main {
    margin-top: 0;
  }

  .fieldset {
    padding: 0;

    .legend {
      display: none;
    }

    .note {
      @include text--heading-5;
      margin-bottom: 30px;
    }
  }

  .actions-toolbar {
    .primary {
      width: 100%;

      @include media('>=lg') {
        max-width: 250px;
      }
    }
  }
}

/**
 * Fonts faces
 */

// Google fonts
@import url('https://fonts.googleapis.com/css?family=PT+Serif&display=swap');

// Font Awesome PRO icons
@font-face {
  font-family: 'Font Awesome Regular';
  src:
    url('../fonts/fontawesome-pro-5.7.2-web/webfonts/fa-regular-400.woff2') format('woff2'),
    url('../fonts/fontawesome-pro-5.7.2-web/webfonts/fa-regular-400.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome Light';
  src:
    url('../fonts/fontawesome-pro-5.7.2-web/webfonts/fa-light-300.woff2') format('woff2'),
    url('../fonts/fontawesome-pro-5.7.2-web/webfonts/fa-light-300.woff') format('woff');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Font Awesome Solid';
  src:
    url('../fonts/fontawesome-pro-5.7.2-web/webfonts/fa-solid-900.woff2') format('woff2'),
    url('../fonts/fontawesome-pro-5.7.2-web/webfonts/fa-solid-900.woff') format('woff');
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: 'Font Awesome Brands';
  src:
    url('../fonts/fontawesome-pro-5.7.2-web/webfonts/fa-brands-400.woff2') format('woff2'),
    url('../fonts/fontawesome-pro-5.7.2-web/webfonts/fa-brands-400.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}

@import '../tools/mixins-typography';
@import '../tools/mixins-button';

.giftcardbalance-index-index {
  .gift-card-balance-heading {
    @include text--heading-3;
  }

  .sub-title {
    padding-top: 30px;
  }

  .form-giftcard-redeem {
    margin-top: 20px;
    max-width: 400px;
  }

  .fieldset {
    padding: 0;
    margin: 0;
  }

  .actions-toolbar {
    margin-top: 0;
  }

  .giftcard-balance {
    @include text--product-card-price;
    margin-bottom: 20px;

    &.error {
      @include text--medium;
      color: $color--ui--invalid;
    }
  }

  .primary,
  .action.check {
    width: 100%;
  }

  .buy-button {
    @include button-style--primary;
  }
}

@import '../tools/mixins-typography';
/**
 * Base styling for heading elements
 */

h1 {
  @include text--heading-1;
}

h2 {
  @include text--heading-2;
}

h3 {
  @include text--heading-3;
}

h4 {
  @include text--heading-4;
}

h5 {
  @include text--heading-5;
}

h6 {
  @include text--heading-6;
}

.lead-text {
  @include text--lead-text;
}

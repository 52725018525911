@import '../elements/icons';
@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins';
@import '../tools/mixins-button';

.stockists-index-index {
  $border: $color--ui--border-light;
  $mobile-map-height: 500px;
  $tablet-view-height: 780px;

  .page-title-wrapper {
    display: none;
  }

  .store-locator {
    display: block;
    padding-top: 50px;

    @include media('>=tablet') {
      display: flex;
      padding-top: 100px;
      height: $tablet-view-height;
    }
  }

  .locations {
    padding: 0 10px;
    overflow-y: scroll;
  }

  .locations__spinner {
    @include loading-spinner;
    height: 30px;
    margin-top: 44px;
  }

  .location {
    display: flex;
    border-bottom: $border;
    padding: 27px 0 30px;

    .location__details {
      flex: 1;
    }

    .location__title {
      @include text--heading-5;
      margin-top: 0;
    }

    .location__distance {
      @include text--more-cta-link;
      float: right;
      margin: 6px 0 0 10px;
    }

    .location__actions {
      margin-top: 20px;
    }
  }

  .action {
    @include button--store-locator-action;
    display: block;
  }

  .location__pin {
    padding-right: 10px;

    &::before {
      @include icon('map-pin', 'solid');
    }
  }

  .store-locator__left-section {
    width: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;

    @include media('>=tablet') {
      max-width: 365px;
    }

    .title {
      @include text--heading-3;
      text-align: center;
      margin-bottom: 20px;
    }

    .store-locator__search {
      padding: 20px 10px;
      border-bottom: $border;
    }

    .location-form {
      display: flex;
    }

    .input-component {
      flex: 1;
      margin: 0;
    }

    .submit {
      @include button-style--primary;
      height: 44px;
      margin-left: 5px;
      padding: 0 30px;
    }

    .radius {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }

    .radius-slider {
      margin: 10px;
      flex: 1;
    }

    .geolocation-container {
      display: flex;
      justify-content: center;
    }

    .geolocate-btn {
      @include button--icon-text;

      &::before {
        @include icon('crosshairs');
      }
    }

    .error-message {
      color: $color--ui--invalid;
    }
  }

  .store-locator__map {
    flex: 1;
    height: 100%;
    padding: 80px 30px 0;

    .container,
    .map {
      height: 100%;
    }
  }

  .tabbed-ui__tabs {
    @include reset-ul;
    display: flex;
    padding: 10px 0;
  }

  .tabbed-ui__tab {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border: $border;
    flex: 1;
  }

  .tabbed-ui__tab--active::after {
    @include gold-strip;
    position: absolute;
    bottom: 0;
  }

  .tabbed-ui__content {
    .locations {
      overflow-y: auto;
    }

    .map {
      height: $mobile-map-height;
    }
  }

  .contact-details {
    margin: 20px 0 10px;
  }

  .address-title {
    @include text--heading-6;
  }

  .contact-details__address {
    @include text--store-locator-details;
    margin: 5px 0 12px;

    .address-info {
      margin: 0;
    }
  }

  .contact-details__phone {
    @include text--store-locator-details;
  }



  .infowindow-store-info {
    min-width: 220px;

    @include media('>=tablet') {
      min-width: 250px;
    }

    .title {
      @include text--heading-5;
    }

    .contact-details__address,
    .contact-details__phone {
      font-size: 14px;
    }

    .trading-hours,
    .extended-hours {
      margin: 15px 0;
    }

    .trading-hours__title,
    .extended-hours__title {
      @include text--heading-6;
      margin-bottom: 5px;
    }

    .trading-hours__table,
    .extended-hours__table {
      width: 100%;
    }

    .trading-hours__cell,
    .extended-hours__cell {
      width: 50%;
    }
  }
}

@import '../settings/colours';
@import '../tools/mixins-animation';
@import '../tools/mixins-button';
@import '../tools/mixins-layout';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * MegaMenu - Promo Column
 * Page Builder Block - !important used frequently to override inline styles
 */

.header__nav .megamenu .pb-block--megamenu-promo {
  background: $color--brand--primary-blend;
  padding: 1px !important;

  [data-content-type='column-group'] {
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 !important;
    min-height: 480px;
    overflow: hidden;

    [data-content-type='column'] {
      margin: 0 !important;
      width: 100% !important;
      color: $color--ui--text-light-on-dark;

      // bg cover image
      &:nth-child(1) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
      }

      // overlay label
      &:nth-child(2) {
        @include transition('padding');
        background: $color--ui--overlay-label;
        padding: 20px 15px !important;
        z-index: 1;
      }

      [data-content-type='heading'] {
        @include text--heading-3;
        text-align: left !important;
        padding: 0;
        margin: 0;
        color: $color--ui--text-light-on-dark;
      }

      [data-content-type='text'] {
        margin-bottom: 20px !important;

        p {
          margin: 0;
        }
      }

      [data-content-type='buttons'] {
        margin: 0 !important;
        padding: 0 !important;
      }

      [data-content-type='button-item'] {
        @include button--more-cta;
        margin: 0 !important;
        position: initial;
      }

      [data-element='link'] {
        @include link('dark', 'light');

        &::after {
          @include pos-abs-fill;
          content: '';
        }
      }
    }

    &:hover {
      cursor: pointer;

      [data-content-type='column']:nth-child(2) {
        padding: 25px 15px 30px !important;
      }
    }
  }
}

@import '@aligent/frontend-toolkit/scss/mixins/reset-ul';
@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-button';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
@import '../elements/icons';
/**
 * Styling for the header nav and mega menu
 */

// increase specificity to override page-full-width setting site-wrap on column-group
body {
  @include pb-reset-elements('.header__nav');
}

.header__nav {
  z-index: 1;

  .header__nav__curtain {
    @include curtain;
  }

  &.toggle-nav--active {
    pointer-events: auto;

    .header__nav__curtain {
      @include curtain--active;
    }
  }

  .megamenu {
    @include reset-ul;
    display: flex;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-family-serif;
    }

    [data-content-type='button-item'] {
      @include button--menu-top-level-link;

      &:not(:first-child) {
        @include button--menu-list-item;

        @include media('<desktop') {
          padding-left: 20px !important;
        }
      }
    }

    .megamenu__top-level {
      &.megamenu__top-level--seasonal { // page builder block: megamenu
        [data-element='link'] {
          color: $color--ui--link-text--megamenu-seasonal;
        }
      }

      [data-content-type='button-item'] {
        margin: 0 !important;

        &::before {
          @include icon('diamond', 'solid');
          @include gold-text-gradient;
          font-size: 8px;
          position: absolute;
          left: -14px;
          top: 50%;
          transform: translateY(-50%);
          visibility: hidden;
        }
      }
    }

    .megamenu__content {
      [data-content-type='buttons']:not(:first-child) {
        margin: 30px 0 !important;
      }
    }

    .megamenu__item--no-content {
      position: relative;

      .megamenu__top-level {
        [data-content-type='button-item'] {
          position: initial; // allow anchor within to fill menu item

          &::before {
            left: 6px;
          }
        }

        [data-element='link'] {
          &::after {
            @include pos-abs-fill;
            content: '';
          }
        }
      }
    }

    // hc-megamenu.js
    // when current url matches menu item
    .megamenu__item.active .megamenu__top-level [data-content-type='button-item'] {
      font-weight: 700;
      color: $color--ui--link-text-active;

      &::before {
        visibility: visible;
      }
    }
  }

  /**
   * Mobile Header Nav
   */
  @include media('<desktop') {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    .header__nav__content {
      @include transition('transform');
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: $color--ui--sticky-bg;
      box-shadow: $color--ui--shadow-sticky-left;
      width: calc(100% - 40px);
      max-width: 400px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      transform: translateX(-110%); // 110% of the mobile menu width so shadow isn't visible at edge
    }

    .header__nav__main::before {
      @include gold-strip;
    }

    .header__nav__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 0 $gutter-medium;
    }

    .header__nav__logo {
      .logo {
        display: flex;

        .logo__img {
          height: 25px;
        }
      }
    }

    .header__nav__close {
      @include button--back-arrow;
      transform: translateX(50%);
    }

    .header__nav__footer {
      margin: 40px 0;
      padding: 0 20px;
    }

    // Page Builder
    // footer-base-links
    .header__nav__footer-links {
      margin: -5px;

      [data-content-type='button-item'] {
        @include button--menu-link;

        [data-element='link'] {
          display: block;
          padding: 5px;
          text-align: left !important;
        }
      }
    }

    /**
     * Mega Menu
     * Page builder content, !important used frequently
     */
    .megamenu {
      $border-top-bottom: $color--ui--border-light;

      flex-direction: column;
      background: $color--neutral--white;
      border-top: $border-top-bottom;
      border-bottom: $border-top-bottom;

      .megamenu__item {
        overflow: hidden;

        [data-content-type='column-group'] {
          display: block !important;
        }

        &.ui-state-active {
          .megamenu__top-level {
            @include button--accordion-level-0--active;
          }
        }
      }

      .megamenu__item--no-content {
        .megamenu__top-level {
          &::after {
            display: none;
          }
        }
      }

      .megamenu__top-level {
        @include button--accordion-level-0;

        &::after {
          transform: translate(0, -50%);
        }
      }

      .megamenu__content {
        width: 100% !important;
        padding: 0 20px 20px !important;

        [data-content-type='column'] {
          width: 100% !important;
          margin: 15px 0 0 !important;
        }
      }
    }

    &.toggle-nav--active {
      .header__nav__content {
        transform: translateX(0);
      }
    }
  }

  /**
   * Desktop Mega Menu
   */
  @include media('>=desktop') {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;

    .header__nav__header,
    .header__nav__footer {
      display: none;
    }

    .header__nav__content,
    .header__nav__main,
    .header__nav__mega-menu,
    .megamenu,
    .megamenu .level0,
    .megamenu .level-top__link {
      height: 100%;
    }

    .header__nav__content {
      display: flex;
      justify-content: flex-end;
    }

    .megamenu {
      margin-right: -$gutter-medium;

      &:not(.is-touch-supported) {
        @include delay-child-pointer-events(800ms);
      }

      .megamenu__item {
        &,
        [data-content-type='column-group'] {
          height: 100%;
        }
      }

      .megamenu__top-level {
        @include transition('background');
        width: 100% !important;
        padding: 0 20px !important;
        background: transparent;
        justify-content: center !important;

        &.megamenu__top-level--seasonal { // page builder block: megamenu
          [data-element='link'] {
            font-weight: 700;
          }
        }

        [data-content-type='button-item'] {
          @include text--megamenu-top-level;
          margin: 0 !important;
        }
      }

      .megamenu__content {
        @include transition('opacity, margin');
        position: absolute;
        left: 0;
        top: 100%;
        width: 100% !important;
        z-index: 1;

        // hidden state
        opacity: 0;
        margin-top: -30px !important;
        pointer-events: none;

        // content block
        > [data-content-type='block'] {
          padding: 15px !important;
          margin-left: -$gutter-medium !important;
          margin-right: -$gutter-medium !important;
          background: $color--neutral--white;
          box-shadow: $color--ui--shadow-megamenu;

          [data-content-type='column'] {
            margin-left: 20px !important;
          }

          [data-content-type='buttons'] {
            margin: 30px 0 !important;
            padding: 0 0 0 30px !important;
          }
        }

        .pagebuilder-column:not(:first-child) {
          [data-content-type='buttons'] {
              margin: 30px 0 !important;
          }
        }

        .pb--megamenu-list-heading {
          @include button--menu-list-heading;
        }

        [data-content-type='button-item']:not(.pb--megamenu-list-heading) {
          &::before {
            @include icon('right-arrow', 'light');
            font-size: 16px;
            margin-right: 12px;
            color: $color--ui--link-text--megamenu-hover;
          }

          [data-element='link'] {
            &:hover {
              color: $color--ui--link-text--megamenu-hover;
              font-weight: 700;
            }
          }
        }
      }
    }

    // hc-megamenu.js
    // when menu item is hovered
    &.toggle-nav--active {
      .megamenu__item.ui-state-active {
        .megamenu__top-level {
          background: $color--neutral--white;
          z-index: 2;

          .pagebuilder-button-primary {
            color: $color--ui--link-text--megamenu-hover;
            font-weight: 700;
          }
        }

        .megamenu__content {
          opacity: 1;
          margin-top: 0 !important;
          pointer-events: auto;
        }
      }
    }
  }
}

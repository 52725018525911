@import '../settings/colours';

.catalog-category-view, // PLP
.catalogsearch-result-index, // Search results page
.catalog-product-view, // PDP
.cms-page-view { // CMS pages
  .amxnotif-block,
  .alert.stock {
    .notification-container {
      display: grid;
      gap: 10px;
      margin-top: 1.2rem;

      @include media('>=md') {
        grid-template-columns: 1fr min-content;
      }
    }

    .input-fields {
    }

    .input-text {
      padding: 0.4rem 1rem;
      border: $color--ui--border-alpha;
    }

    div.mage-error {
      margin: 0;
      padding: 5px 24px;
      max-height: initial;
    }

    .actions-toolbar {
      display: block;
      margin: 0;
    }

    .action.primary {
      background: $color--brand--tertiary;
      color: $color--ui--text-light-on-dark;
      border-radius: 0;
      padding: 0.73rem 2rem;
      white-space: nowrap;
      width: 100%;
    }

    .alert {
      text-decoration: underline;
    }
  }
}

.catalog-category-view, // PLP
.catalogsearch-result-index, // Search results page
.cms-page-view { // CMS page
  .amxnotif-block,
  .alert.stock {
    &.category.subscribe-popup {
      display: none; // It will be shown using javascript
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $color--ui--curtain;
      overflow: auto;
      z-index: 99999;
    }

    &.label {
      text-decoration: underline;
    }

    .form-validate-stock__label {
      display: block;
      text-align: center;
      font-size: 23px;
    }

    .subscribe-popup {
      // PLP popup
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      max-width: 700px;
      background-color: $color--ui--bg-default;
      height: auto;
      padding: 2.7rem 1rem;

      @include media('>=tablet') {
        padding: 3.5rem 6rem;
      }
    }

    .close-subscribe-popup {
      color: $color--neutral--silver-chalice;
      cursor: pointer;
      display: block;
      font-size: 28px;
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;

      @include media('>=tablet') {
        top: 1.5rem;
        right: 1.5rem;
      }
    }

    .amxnotif-block__title,
    .amxnotif-block__description {
      display: none;
    }
  }
}

.catalog-product-view { // PDP
  .amxnotif-block,
  .alert.stock {
    margin-top: 3rem;

    .amxnotif-block__title {
      @include text-subheading-style(
        $font-size: 18px,
        $letter-spacing: 0,
        $font-weight: 700
      );
      text-transform: uppercase;
      color: $color--ui--pdp-details-highlight;
      margin-bottom: 0.3rem;
    }

    .amxnotif-block__description {
      font-size: 15px;
      margin-top: 0;
    }

    .amxnotif-block__label {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .notification-container {
      margin-top: 0.1rem;
    }
  }
}

@import '../settings/colours';
@import '../tools/mixins-form-elements';

/**
 * Styling for input elements
 */


input {
  &.input--read-only {
    @include input-style(
      $color: $color--ui--input-text-placeholder,
      $background: $color--neutral--gallery
    );
  }

  &[type='text'],
  &[type='email'],
  &[type='tel'],
  &[type='password'] {
    @include h-input-reset;
  }

  &[type='checkbox'] {
    & + label {

    }

    &:checked {

    }
  }

  &[type='radio'] {
    & + label {

    }

    &:checked {

    }
  }

  &[type='file'] {

  }

  &[type='submit'] {

  }
}

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

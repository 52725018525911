/**
 * Contextual styling for the cart page
 */

.checkout-cart-index {
  @import 'aligent-scss-placeholder/contexts/cart';

  .cart-container { // The wrapper around the cart summary and cart items table

  }

  .cart-summary { // Summary of uesrs cart, showing shipping, totals etc
  }

  .form-cart { // The table showing all items in the users cart

    .items { // <table>

    }

    .table-caption {

    }
  }

  #gift-options-cart { // No class name exists on element

  }

  .cart-discount {

  }

}

/**
 * Typography settings
 */
$base-font-size: 16px;
$font-family-serif: 'PT Serif', serif;
$font-family-sans-serif: 'acumin-pro', sans-serif;
$font-family-body: $font-family-sans-serif;
$font-family-accent: '';
$font-family-heading: $font-family-serif;
$font-family-subheading: $font-family-sans-serif;
$font-family-icon: '';
$base-line-height: 28px;

@import '../tools/mixins-button';

.checkout-onepage-success {

  .main {
    display: flex;
    flex-wrap: wrap;
  }

  .page-title {
    @include text--heading-1;
    line-height: 60px;
    margin-bottom: 50px;
  }

  .checkout-success {
    font-size: 17px;
  }

  .checkout-success-content {
    width: 100%;

    @include media('>=tablet') {
      width: 50%;
      padding-left: 20px;
    }
  }

  .checkout-success-info {
    width: 100%;

    @include media('>=tablet') {
      padding-right: 20px;
      width: 50%;
    }
  }

  .checkout-success__guest-info-message {
    margin-bottom: 50px;
  }

  .checkout-success__guest-info-details {
    font-weight: 600;
  }

  .checkout-success__hr {
    margin-top: 50px;
    margin-bottom: 50px;
    border: 0;
    border-top: $color--ui--border-light;
  }

  .actions-toolbar {
    flex-direction: column;

    @mixin action-toolbar-flex-row () {
      flex-direction: row;

      .action {
        flex-grow: 1;
      }

      .action.secondary {
        margin: 0 16px 0 0;
      }
    }

    @include media('>=small-tablet', '<tablet') {
      @include action-toolbar-flex-row;
    }

    @include media('>=desktop') {
      @include action-toolbar-flex-row;
    }
  }

  .action.primary {
    @include button-style--primary;
    padding: 16px 20px;
  }

  .action.secondary {
    @include button-style--outline;
    padding: 16px 20px;
    margin-bottom: 16px;
  }
}

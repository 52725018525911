@import '../settings/colours';
@import '../tools/mixins-form-elements';
@import '../tools/mixins-button';

$_input-offset: $input-offset;
$_input-height: $input-height;
$_border-radius: $border-radius-large;

.checkout-app-promos-container {
  position: relative;
}

.payment-modifier__container {
  &.promo-code__container {
    margin-bottom: 15px;
  }

  .react-checkout-loading__block {
    position: absolute;
    width: auto;
    padding: 0;
    background: none;
    top: 30px;
    left: auto;
    right: 0;
  }

  .payment-modifier__form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  .payment-modifier__form--hidden {
    display: none;
  }

  .payment-modifier__button {
    @include button-style--outline;
    height: $_input-height;
    margin-bottom: 20px;
    padding: 10px 25px;
    font-size: 14px;
    width: 100%;
    min-width: 130px;

    @include media('>=md') {
      margin-left: 16px;
      width: auto;
    }

    // special case to catch small column width
    @include media('>=desktop', '<1250px') {
      width: 100%;
      margin-left: 0;
    }
  }

  .input-component {
    position: static; // move wrapping of error message to parent.
    flex-grow: 1;
    margin: 0;
  }

  .gift-card-input-component {
    width: 100%;
  }

  .payment-modifier__toggle {
    font-weight: bold;

    &:focus,
    &:hover {
      background: none;
      text-decoration: underline;
    }
  }

  .payment-modifier__message {
    @include text--checkout-message-text;
    position: relative;
    background: $color--brand--primary-blend;
    color: $color--ui--success;
    border-radius: $border-radius-large;
    text-align: center;
    padding: 0;
    opacity: 0;
    transition: all 0.2s;
    margin-top: -8px; // Need negative margin since can't edit the input field's margin
    width: 100%;
  }

  .payment-modifier__message--gift-card-balance {
    margin-top: 0;
  }

  .payment-modifier__message--error {
    background: $color--ui--error-bg;
    color: $color--ui--error;
    font-weight: 500;
  }

  .payment-modifier__message--visible {
    padding: 8px 20px;
    opacity: 1;

    &.payment-modifier__message--gift-card {
      margin-bottom: 15px;
    }
  }

  .gift-card__balance-info {
    width: 100%;
    margin-top: -10px; // allow for input margin
    text-align: center;
  }

  .button-check-balance {
    @include button--padded-link;
  }
}

@import '@aligent/frontend-toolkit/scss/mixins/reset-ul';
@import '../settings/colours';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-typography';
@import '../tools/mixins-button';
@import '../elements/icons';
/**
 * Styling for the search block
 */

.block-search {
  padding: 32px 0;

  @include media('>=lg') {
    padding: 40px 0;
  }

  @include media('>=tablet') {
    padding: 40px 20px;
  }

  @include media('>=desktop') {
    padding: 46px 20px; // slightly taller to cover header
  }

  .search-title {
    @include text--instant-search-heading;
    color: $color--neutral--grey;
    text-align: center;
    padding: 0 10px;

    @include media('>=tablet') {
      padding: 0 40px;
    }
  }

  .block-content {
    margin-top: 25px;

    @include media('>=lg') {
      margin-top: 35px;
    }
  }

  .search-submit {
    @include button-style--primary;

    // easier to set <lg as only changes then
    // rather than double up resetting hidden
    @include media('<lg') {
      width: 50px;
      padding-left: 0;
      padding-right: 0;

      &::before {
        @include icon('search', 'solid');
      }

      .search-submit__label {
        display: none;
      }
    }
  }

  .form.minisearch {
    $input-height: 50px;
    display: flex;

    input {
      @include text--medium;
      @include transition('box-shadow');
      background: $color--neutral--white;
      width: 100%;
      height: $input-height;
      font-weight: 300; // resetting as overridden by text medium
      padding-top: 0 !important; // overrides mixins-form-elements.scss
    }

    .field.search {
      width: 100%;
      height: $input-height;
      margin: 0;
      margin-right: 10px;
      background: $color--neutral--white;

      .control {
        top: auto;
        line-height: $input-height;
        height: $input-height;
      }

      @include media('>=tablet') {
        margin-right: 15px;
      }
    }
  }

  .search-autocomplete {
    position: fixed !important; // override inline style
    padding: 10px;
    max-width: 100%;
    margin-left: -5px; // pull back to align input value and suggestions

    ul[role='listbox'] { // No class added by autocomplete
      @include reset-ul;
      @include text--medium;
      display: inline-block;
      background: $color--neutral--white;
      box-shadow: $color--ui--shadow-menu-dropdown;
      padding: 20px;
      min-width: 250px;

      [aria-hidden='true'] {
        display: none;
      }
    }

    li[role='option'] { // No class added by autocomplete
      cursor: pointer;
    }
  }
}

@import '../settings/colours';
@import '../settings/layout';

.checkout-app-progress-tabs,
.checkout-app__sections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.checkout-app__sections--expanded {
    justify-content: space-between;
  }

  &.checkout-app__sections--show-cart {
    .checkout-app-tab-section__content {
      @include media('>=desktop') {
        max-width: 1/3 * 100%;
      }
    }
  }
}

.checkout-app__sections {
  margin: 0 -$gutter-small; // reduce padding on checkout end columns.

  @include media('>=tablet') {
    margin: 0 -$gutter-medium;
  }
}

.checkout-app-tab-section {
  width: 100%;

  @include media('>=desktop') {
    justify-content: center;
  }
}

.checkout-app-progress-tabs {
  justify-content: space-between;
}

.checkout-app-tab-section__content {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 25px 30px;

  @include media('>=desktop') {
    display: flex;
    flex: 1;
    max-width: 45%;
  }
}

.checkout-app-tab-section__content--is-active {
  display: flex;
}

.checkout-app__shoppingbag-section {
  @include media('>=tablet') {
    display: none;

    &.show-cart {
      display: block;
    }
  }
}

/**
 * cart-form styling
 */

.form-cart {
  @import 'aligent-scss-placeholder/components/cart-form';

  .table-wrapper {

  }

  .items { // <table>

  }

  .table-caption {

  }

  thead {
    .item {

    }

    .subtotal {

    }

    th {

    }
  }

  tbody {

    // Used to vertically center the prices with the qty input box
    span.price { // Need to scope to <span> as there is a <td> with the same class name

    }
  }

  td {

    &:first-child {

    }

    &:last-child {

    }
  }

  .col.item {

  }

  .product-item-details {

  }

  .col.price,
  .col.qty,
  .col.subtotal,
  .col.msrp {

  }

  .col.subtotal {

  }

  .col.qty {
    .label {

    }
  }

  .item-info {
    &:first-child {

    }
  }

  .item-actions {

  }

  // Container with "Continue Shopping", "Clear Shopping Cart" and "Update Shopping Cart" buttons
  .actions {

    .action {

    }

    .continue {

    }

    .clear {

    }
  }
}

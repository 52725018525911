@import '@aligent/frontend-toolkit/scss/mixins/reset-ul';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
@import '../elements/icons';
/**
 * Styling for the account dropdown menu
 */

.account-dropdown-menu {
  position: relative;

  .account-dropdown-menu__icon::before {
    @include icon('account', 'solid');
  }

  .account-dropdown-menu__dropdown {
    display: none;
    position: fixed;
    margin: -5px 0 0 8px;
    padding-top: 15px;
    z-index: 1;

    @include media('>=desktop') {
      margin-left: 7px;
    }

    .dropdown-content {
      $lip-size-fill: 10px;
      $lip-size-stroke: 11px;
      $bg: $color--neutral--white;
      position: relative;
      background-color: $bg;
      border: $color--ui--border;
      box-shadow: $color--ui--shadow-menu-dropdown;
      padding: 10px 18px;
      margin-left: -50%;
      margin-right: 50%;

      &::before,
      &::after {
        position: absolute;
        content: '';
        left: 50%;
      }

      &::before {
        border-left: $lip-size-stroke solid transparent;
        border-right: $lip-size-stroke solid transparent;
        border-bottom: $lip-size-stroke solid $color--ui--border-color;
        top: -$lip-size-stroke;
        margin-left: -$lip-size-stroke;
      }

      &::after {
        border-left: $lip-size-fill solid transparent;
        border-right: $lip-size-fill solid transparent;
        border-bottom: $lip-size-stroke solid $bg;
        top: -$lip-size-fill;
        margin-left: -$lip-size-fill;
      }
    }
  }

  .account-dropdown-menu__list {
    // glyphs were slightly cut off - increased size
    $icon-pull-left: 0.2em;
    $icon-size: 1.2em;

    @include reset-ul;
    @include text--base;
    white-space: nowrap;

    .menu-list-item__link {
      @include button--icon-text;
    }

    .menu-list-item--label {
      @include text--icon-text;
      cursor: inherit;
    }

    .menu-list-item--account {
      .menu-list-item__link::before {
        @include icon('account-circle', 'solid');
        margin-left: -$icon-pull-left;
        width: $icon-size;
        line-height: $icon-size;
      }
    }

    .menu-list-item--signout {
      .menu-list-item__link::before {
        @include icon('signout', 'solid');
        margin-left: -$icon-pull-left;
        width: $icon-size;
        line-height: $icon-size;
      }
    }
  }

  &:hover {
    .account-dropdown-menu__dropdown {
      display: block;
    }
  }
}

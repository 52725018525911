@import '../settings/colours';
@import '../settings/typography';

/**
 * Styling for related, upsell and crosssell item block
 */
.block.related,
.block.upsell,
.block.crosssell {
  margin-bottom: 2rem;

  .block-title {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 2.6rem;

    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      background: $color--ui--pdp-details-highlight;
    }
  }

  .block-related-heading,
  .block-upsell-heading,
  .block-crosssell-heading {
    text-transform: uppercase;
    color: $color--ui--pdp-details-highlight;
    font-weight: 700;
    font-size: 23px;
    font-family: $font-family-subheading;
    letter-spacing: 1.15px;
    text-align: center;
    background: $color--ui--bg-default;
    z-index: 1; // Raises the text above .block-title's after element
    padding: 0 1rem;
  }

  .items.product-items {
    margin: 0;
    padding: 0;

    @include media('<tablet') {
      @include flex-grid(2, 15px, 70px);
    }

    @include media('>=tablet', '<desktop') {
      @include flex-grid(3, 20px, 70px);
    }

    @include media('>=desktop') {
      @include flex-grid(4, 20px, 70px);
    }
  }
}

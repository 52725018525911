@import '../settings/animation';
/**
 * Animation Keyframes and Mixins
 */

/**
 * Keyframes
 */
@keyframes delay-pointer-events {
  0% {
    pointer-events: none;
  }

  100% {
    pointer-events: auto;
  }
}

@keyframes animation-translate-y-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes animation-spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

/**
 * Mixins
 */
@mixin transition (
  $properties: 'all',
  $duration: $transition-duration,
  $timing: $transition-timing-function
) {
  transition-property: #{$properties};
  transition-duration: $duration;
  transition-timing-function: $timing;
}

@mixin delay-child-pointer-events($delay: 500ms) {
  > * {
    pointer-events: none;
    z-index: 1;
  }

  &:hover > * {
    animation: delay-pointer-events $delay linear forwards;
    animation-iteration-count: 1;

    /**
     * Safari Desktop only selector
     */
    _:default:not(:root:root), & {
      pointer-events: auto;
    }
  }
}

@mixin animation-hover-zoom($X: 1.1, $Y: $X) {
  @include transition('transform');

  &:hover {
    transform: scale($X, $Y) translateZ(0);
  }
}

@mixin animation-translate-y-up($duration: $transition-duration) {
  animation: animation-translate-y-up $duration linear forwards;
  animation-iteration-count: 1;
}

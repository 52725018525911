@import '../tools/mixins-typography';

.page-product-giftcard {
  .quantity-control {
    display: none;
  }

  .price-box {
    padding: 0;
    margin: 0 0 21px;
  }

  .box-tocart {
    .quantity-add-to-cart-wrapper {
      display: block;
    }
  }

  .product-options-wrapper,
  .product-info-price,
  .product-info-stock-sku {
    margin: 0;
  }

  .flatpickr-calendar {
    width: calc(90vw);
    margin-left: 5vw;

    @include media('>=small-tablet') {
      width: calc(45% - 10px);
      margin-left: 0;
    }

    @include media('>=tablet') {
      width: calc(55% - 60px);
    }

    @include media('>=desktop') {
      width: calc(25% - 0px);
      max-width: 267px;
    }
  }

  .product-details-container {
    padding: 4rem 0;

    .short-description {
      // styles for terms and conditions inside pagebuilder element
      $_text-indent: 40px;
      display: block;

      p:first-child {
        margin-top: 0;
      }

      h4 {
        @include text--pdp-section-title;
      }

      ol {
        font-size: 16px;
        margin-top: 5px;
        padding-left: 30px;
      }

      li {
        margin-bottom: 10px;
      }
    }
  }
}

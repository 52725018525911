@import '../tools/mixins-typography';
@import '../elements/icons';
@import '../settings/layout';

/**
 * Recipes
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block-recipe-container {
  padding: 0 !important; // Overrides inline style

  h1 {
    margin: 50px 0 30px;
  }

  .recipe-download-link {
    @include link;

    a::before {
      @include icon('download', 'solid');
      margin-right: 10px;
    }
  }

  h3 {
    margin: 50px 0 20px;
  }

  .recipes-related-product-title,
  [data-content-type='products'] {
    @include media('<tablet') {
      order: 2;
    }
  }

  [data-content-type='column-group'] {
    flex-direction: column;

    @include media('>=tablet') {
      flex-direction: row;
    }
  }

  [data-content-type='column'] {
    @include media('<tablet') {
      width: 100% !important;
    }
  }

  [data-content-type='text'] {
    ol,
    ul {
      list-style-position: outside !important;
    }

    li {
      border-bottom: $color--ui--border-alpha-2;
      padding: 10px 0;
    }
  }
}

@import '../settings/colours';
@import '../tools/mixins-layout';
@import '../tools/mixins-animation';
/**
 * Full Width Image Link
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--full-width-image-link {
  position: relative;

  // margin used as animation to reveal gold gradient bg as border
  &::before {
    @include pos-abs-fill;
    @include transition('margin');
    content: '';
    background: $color--brand--primary-blend;
    margin: 2px;
    z-index: -1;
  }

  a {
    display: block;
    overflow: hidden;
  }

  img {
    @include transition('opacity, transform');
    width: 100% !important;
  }

  &:hover,
  &:focus-within {
    &::before {
      margin: -3px;
    }

    a {
      outline: none;
    }

    img {
      transform: scale(1.1);
      opacity: 0.8;
    }
  }
}

@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-button';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Styling for Page Builder elements
 *
 * Breakpoints are desktop first to maintain initial styling
 * Page Builder layouts set for desktop
 *
 * !important used frequently throughout to override inline styles
 */

/**
 * Initial wrappers for page
 * Allow inner padding and margins to persist
 */
.column.main > [data-content-type='row'] {
  position: relative;
  z-index: 0;

  &[data-appearance='contained'] {
    @include site-wrapper();
  }

  > [data-element='inner'] {
    padding: 0;
  }

  // Lifts column-group over row
  // So negative z-indexes on child elements are visible
  [data-content-type='column-group'] {
    z-index: 1;
  }
}

/**
 * Button styles
 */
[data-content-type='button-item'] {
  @include pb-set-button-styles;
}

/**
 * Site wrapper for contained container when Page -- full width
 */
.page-layout-cms-full-width {
  [data-content-type='column-group'] {
    @include site-wrapper();
  }
}

/**
 * Helper classes
 * Add to app/code/Haighs/Cms/view/adminhtml/ui_component/pagebuilder_base_form.xml
 */
/**
 * Layout styles
 * pb-layout__{element}--{id}--{modifier}
 * element: page builder element that the class should be added to, i.e. row, image
 * {id}--{modifier}
 */
.pb-layout__image--full-width img {
  width: 100% !important;
}

.pb-layout__row--col-1--tablet {
  [data-content-type='column-group'] {
    @include media('<desktop') {
      @include pb-columns(1);
    }
  }
}

// Need to increase specificity to override col-x
body .col-1--tablet {
  @include media('<desktop') {
    @include pb-columns(1);
  }
}

/**
 * Appearance styles
 * pb-style--{id}--{modifier}
 */
.pb-style--white-text {
  &,
  [data-content-type='heading'],
  [data-content-type='text'] {
    color: $color--neutral--white;
  }
}

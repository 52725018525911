/**
 * Styling for text based elements
 */

b,
strong {
  font-weight: 700;
}

em {

}

blockquote {

}

p {

}

ul {
  li {

  }
}

ol {
  li {

  }
}

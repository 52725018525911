@use 'settings/colours' as *;
@use 'tools/z-index' as *;

$_loading-height: 48px;
$_loading-border-width: 6px;

@mixin spinner(
  $height: $_loading-height,
  $border-width: $_loading-border-width,
  $border-color: $color--neutral--white,
  $border-color-active: $color--brand--primary,
) {
  width: $height;
  height: $height;
  display: inline-block;
  box-sizing: border-box;
  position: relative;

  &::after,
  &::before {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: $height;
    height: $height;
    border-radius: 50%;
    content: '';
  }

  &::before {
    border: solid $border-width $border-color;
  }

  &::after {
    border: solid $border-width transparent;
    border-top-color: $border-color-active;
    animation: cp-round-animate 0.7s ease-in-out infinite;
  }
}

.react-checkout-loading__block {
  @include zIndex('loading');
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s 0.3s ease;
  background-color: $color--neutral--white-alpha;
}

.react-checkout-loading__block--is-active {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s ease;
}

.react-checkout-loading__spinner {
  @include spinner;
}

@keyframes cp-round-animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.checkout-loader--small {
  height: auto;
  background: none;
  top: 40px;

  .react-checkout-loading__spinner {
    @include spinner($height: 24px, $border-width: 2px);
  }
}

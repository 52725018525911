@import '../tools/mixins-button';
@import '../tools/icons';
@import '../tools/mixins-typography';
@import '../tools/mixins-form-elements';
@import '../tools/mixins';

.giftcard.form {
  $_input-height: 50px;
  margin-bottom: 48px;

  .legend {
    @include text--label-heading;
  }

  .fieldset,
  .price-box.price-final_price {
    padding: 0;
    margin: 0;
  }

  .radio-input {
    @include visually-hidden;
  }

  .radio-input:checked ~ .swatch-option {
    @include button--radio--selected;
  }

  .sender-receiver-info {
    margin-top: 18px;

    @include media('>=small-tablet', '<tablet') {
      @include flex-grid(2, 15px);
    }

    @include media('>=desktop') {
      @include flex-grid(2, 15px);
    }
  }

  .fieldset.giftcard .field { // Need specificity to override form styling
    @include input-style(
      $input-height: $_input-height,
      $border-radius: $_input-height / 2
    );
    margin-bottom: 20px;

    &.is-textarea {
      @include input-style(
        $input-height: 100px,
        $error-class: '.has-error',
        $border-radius: $_input-height / 2
      );

      .label {
        top: 11px;
      }
    }

    .label {
      @include h-input-label(
        $input-height: $_input-height
      );
    }

    .control {
      height: $_input-height;
    }
  }

  div.mage-error {
    @include h-mage-error(
      $input-height: 50px
    );
  }

  .input-wrapper {
    @include media('>=small-tablet', '<tablet') {
      display: flex;
    }

    @include media('>=desktop') {
      display: flex;
    }
  }

  .send-at-fieldset {
    margin-top: 30px;
  }

  .send-at-fieldset__inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include media('>=small-tablet', '<tablet') {
      flex-direction: row;
    }

    @include media('>=desktop') {
      flex-direction: row;
    }

    .field-and-toggle-wrapper {
      position: relative;
      flex: 1;
      display: flex;
      min-width: 280px;
      margin: 10px 46px 0 0;

      @include media('>=small-tablet', '<tablet') {
        width: 45%;
      }

      @include media('>=desktop') {
        width: 45%;
      }

      .scheduled_at { // Need specificity to override form styling
        height: $_input-height;
        // margin-bottom: 0;
      }
    }
  }

  .field-wrapper {
    width: 100%;
  }

  .scheduled_at {
    .label {
      width: calc(100% - 40px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .scheduled_at__send-time {
    @include text--giftcard-note;
    flex: 1;
    color: $color--neutral--grey;
    margin-top: -15px; // Using negative margin to push into the input space

    @include media('>=small-tablet', '<tablet') {
      margin-top: 18px;
    }

    @include media('>=desktop') {
      margin-top: 18px;
    }
  }

  .flatpickr-toggle {
    @include button-style--icon;
    position: absolute;
    right: -24px;
    top: 22px;

    &::after {
      @include icon('calendar');
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color--neutral--grey-alpha;
      font-size: 26px;
    }
  }

  .price-wrapper {
    @include text--heading-2;
    padding-left: 16px;
    min-height: 48px;
    margin-bottom: 25px;
  }

  .giftcard-amount .input-text {
    font-size: 16px;
  }

  .giftcard-note {
    @include text--giftcard-note;
    color: $color--neutral--grey;
    margin-top: -15px; // Using negative margin to push into the input space
  }
}

@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins-button';
@import '../elements/icons';

.pdp-accordions {
  .accordion {
    &::after {
      @include gold-strip(1px);
    }
  }

  .accordion__title-container {
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $color--ui--pdp-accordion-title-bg;
    cursor: pointer;

    &::after {
      @include icon('down-arrow');
      @include gold-text-gradient;
      font-size: 20px;
    }
  }

  .accordion__title-container.active::after {
    @include icon('up-arrow');
  }

  .accordion__title {
    @include text--pdp-accordion-title;
  }

  .accordion__content {
    display: flex;
    flex-direction: column;
    background: $_color-alabaster;
    padding: 20px;
  }
}

@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-button';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Mosaic Promo
 * Page Builder Block - !important used frequently to override inline styles
 */

/**
 * Content styles
 */
@mixin text-overlay {
  [data-content-type='heading'] {
    @include text--heading-3--mosaic-text-overlay;
  }

  &,
  [data-content-type='heading'] {
    color: $color--neutral--white;
  }

  a {
    color: $color--neutral--mercury;
  }

  [data-content-type='column'] {
    position: absolute;
    height: 100%;
  }

  [data-content-type='column']:nth-child(2) {
    background: $color--ui--overlay-image;
  }
}

@mixin text-label {
  [data-content-type='column']:nth-child(2) {
    background: $color--neutral--white;
    border: $color--ui--border-alpha !important;

    [data-element='link'] {
      color: $color--ui--link-text;
    }
  }

  // label positioning
  @include media('<small-tablet') {
    [data-content-type='column']:nth-child(2) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  @include media('>=small-tablet') {
    justify-content: flex-end;
  }
}

/*
 * Content style and positions
 */
@mixin overlay-bottom-left {
  @include text-overlay;

  [data-content-type='column']:nth-child(2) {
    justify-content: flex-end !important;
  }
}

@mixin overlay-bottom-right {
  @include text-overlay;

  [data-content-type='column']:nth-child(2) {
    justify-content: flex-end !important;
    text-align: right;
  }
}

@mixin overlay-top-right {
  @include text-overlay;

  [data-content-type='column']:nth-child(2) {
    text-align: right;
  }
}

@mixin label-bottom {
  @include text-label;

  [data-content-type='column']:nth-child(2) {
    align-self: flex-end !important;
  }
}

@mixin label-right {
  @include text-label;

  [data-content-type='column-group'] {
    justify-content: flex-end;
  }

  [data-content-type='column']:nth-child(2) {
    width: 55% !important;
    height: 100% !important;
    justify-content: flex-end !important;
  }
}

/**
 * Mosaic Pos Abs Position and Size in Percentage
 */
$_mosaic-w--tablet: 728;
$_mosaic-h--tablet: 1690;
$_mosaic-w--desktop: 1080;
$_mosaic-h--desktop: 1160;

@mixin pos-abs-position-size-percent($x, $y, $w, $h, $media: 'tablet') {
  $mosaic-w: $_mosaic-w--tablet;
  $mosaic-h: $_mosaic-h--tablet;

  @if $media == 'desktop' {
    $mosaic-w: $_mosaic-w--desktop;
    $mosaic-h: $_mosaic-h--desktop;
  }

  position: absolute;
  left: ($x / $mosaic-w) * 100%;
  top: ($y / $mosaic-h) * 100%;
  width: ($w / $mosaic-w) * 100% !important;
  height: ($h / $mosaic-h) * 100% !important;
}

/**
 * Component
 */
// Increased specificity due to padding zero pagebuilder.scss
.column.main > [data-content-type='row'] > [data-element='inner'].pb-block--mosaic-promo {
  @include pb-reset-elements;

  position: relative;
  display: flex;
  flex-direction: column;

  @include media ('>=small-tablet') {
    padding-bottom: ($_mosaic-h--tablet / $_mosaic-w--tablet) * 100% !important;
  }

  @include media ('>=desktop') {
    padding-bottom: ($_mosaic-h--desktop / $_mosaic-w--desktop) * 100% !important;
  }

  [data-content-type='column-group'] {
    position: relative;
    background: $color--neutral--concrete;
    width: 100%;

    // margin used as animation to reveal gold gradient bg as border
    &::before {
      @include pos-abs-fill;
      @include transition('opacity');
      content: '';
      background: $color--brand--primary-blend;
      margin: -1px;
      opacity: 0;
    }

    &:hover,
    &:focus-within {
      &::before {
        opacity: 1;
      }

      a {
        outline: none;
      }
    }

    @include media('<small-tablet') {
      margin-bottom: 30px !important;
      padding-bottom: 133% !important;
    }

    &:nth-child(1) {
      @include overlay-bottom-left;

      @include media('>=small-tablet') {
        @include pos-abs-position-size-percent(0, 0, 258, 300);
      }

      @include media('>=desktop') {
        @include pos-abs-position-size-percent(0, 0, 280, 300, 'desktop');
      }
    }

    &:nth-child(2) {
      @include overlay-top-right;

      @include media('>=small-tablet') {
        @include pos-abs-position-size-percent(288, 0, 440, 580);
      }

      @include media('>=desktop') {
        @include pos-abs-position-size-percent(320, 0, 440, 580, 'desktop');
      }
    }

    &:nth-child(3) {
      @include label-bottom;

      @include media('>=small-tablet') {
        @include pos-abs-position-size-percent(0, 1190, 258, 500);
      }

      @include media('>=desktop') {
        @include pos-abs-position-size-percent(800, 0, 280, 450, 'desktop');
      }
    }

    &:nth-child(4) {
      @include label-bottom;

      @include media('>=small-tablet') {
        @include pos-abs-position-size-percent(0, 330, 258, 500);
      }

      @include media('>=desktop') {
        @include pos-abs-position-size-percent(0, 330, 280, 500, 'desktop');
      }
    }

    &:nth-child(5) {
      @include label-bottom;

      @include media('>=small-tablet') {
        @include label-right;
        @include pos-abs-position-size-percent(288, 610, 440, 220);
      }

      @include media('>=desktop') {
        @include pos-abs-position-size-percent(320, 610, 440, 220, 'desktop');
      }
    }

    &:nth-child(6) {
      @include text-overlay;

      @include media('>=small-tablet') {
        @include pos-abs-position-size-percent(288, 1190, 440, 250);
      }

      @include media('>=desktop') {
        @include pos-abs-position-size-percent(800, 480, 280, 350, 'desktop');
      }
    }

    &:nth-child(7) {
      @include overlay-bottom-left;

      @include media('>=small-tablet') {
        @include pos-abs-position-size-percent(0, 860, 728, 300);
      }

      @include media('>=desktop') {
        @include pos-abs-position-size-percent(0, 860, 760, 300, 'desktop');
      }
    }

    &:nth-child(8) {
      @include overlay-bottom-right;

      @include media('>=small-tablet') {
        @include pos-abs-position-size-percent(288, 1470, 440, 220);
      }

      @include media('>=desktop') {
        @include pos-abs-position-size-percent(800, 860, 280, 300, 'desktop');
      }
    }
  }

  [data-content-type='column'] {
    width: 100% !important;

    // background
    &:nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      [data-content-type='image'] {
        @include transition('transform, opacity');
      }

      &,
      [data-content-type='image'],
      img {
        width: 100%;
        height: 100% !important;
      }

      img {
        object-fit: cover;
      }
    }

    // text
    &:nth-child(2) {
      padding: 15px !important;
      z-index: 1;
    }
  }

  [data-content-type='heading'] {
    @include text--heading-3;

    @include media('>=small-tablet', '<tablet') {
      @include text-heading-style(
        $font-size: 18px,
        $font-weight: 600,
        $line-height: $base-line-height
      );
    }
  }

  [data-content-type='text'] {
    margin-bottom: 20px !important;

    > * {
      @include first-last-no-end-margins;
    }
  }

  [data-element='link'] {
    @include text--more-cta-link;
    @include link('dark', 'light');
    text-transform: uppercase;
    position: initial;

    &::after {
      @include pos-abs-fill;
      content: '';
    }
  }
}

@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins';
@import '../tools/mixins-pagebuilder';

.product-details-container {
  @include pb-reset-elements;
  position: relative;
  padding: 2rem 0;
  margin-bottom: 5rem;

  @include media('>=tablet') {
    margin-bottom: 4rem;
  }

  &::before {
    // Background that extends beyond the container width
    content: '';
    position: absolute;
    z-index: -1;
    background: $color--ui--pdp-details-bg;
    left: -50vw;
    right: -50vw;
    top: 0;
    bottom: 0;
  }

  .product.data {
    display: flex;
    flex-direction: column;

    .items__tabs {
      display: contents;

      .title {
        &:nth-child(1) {
          order: 0;
        }

        &:nth-child(2) {
          order: 2;
        }

        &:nth-child(3) {
          order: 4;
        }

        &:nth-child(4) {
          order: 6;
        }

        .switch {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 0;
          font-size: 19px;
          letter-spacing: 0;
          text-transform: uppercase;
          font-weight: 700;
          border-bottom: 1px solid $color--ui--pdp-details-highlight;

          @include media('>=desktop') {
            border-bottom: none;
          }

          &::after {
            @include icon('down-arrow');
            transition: 0.2s;
            color: $color--ui--pdp-details-highlight;
          }
        }

        &.active .switch {
          color: $color--ui--pdp-details-highlight;

          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    .item.content {
      background: white;
      padding: 2rem;

      &:nth-child(2) {
        order: 1;
      }

      &:nth-child(3) {
        order: 3;
      }

      &:nth-child(4) {
        order: 5;
      }

      &:nth-child(5) {
        order: 7;
      }
    }

    @include media('>=desktop') {
      display: block;

      .items__tabs {
        display: flex;
        justify-content: center;

        .title .switch {
          display: block;
          padding: 1rem 3rem;

          &::after {
            content: none;
          }
        }
      }

      .item.content {
        padding: 4.5rem;
      }
    }
  }

  .whats-inside-product {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .whats-inside-product__button {
    @include button-style--link;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $color--ui--bg-default;

    &:hover {
      .whats-inside-product__title {
        @include gold-text-gradient;
      }
    }
  }

  .whats-inside-product__image-container {
    $imageSize: 100px;
    @include text--base;
    position: relative;
    padding-bottom: $imageSize;
    height: 0;

    .whats-inside-product__image {
      object-fit: contain;
      width: $imageSize;
      height: $imageSize;
      margin-bottom: 5px;
    }
  }

  .whats-inside-product__title {
    text-align: left;
    margin-top: 5px;
    padding: 0 20px 0 10px;
  }

  .description-wrapper {
    @include media('>=tablet') {
      display: flex;
      justify-content: space-between;
    }
  }

  .attribute.description {
    [data-content-type='text'] {
      p:first-child { // Pagebuilder element with no class
        margin-top: 0;
      }
    }

    [data-element='inner'] {
      padding: 0 !important; // overrides pagebuilder inline style
    }
  }

  .product-description {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .rainforest-alliance-image-wrapper {
    $imageSize: 150px;
    width: $imageSize;
    height: $imageSize;
    margin: 2rem 0 0;
    flex-shrink: 0;

    @include media('>=tablet') {
      margin: 0 2rem;
    }
  }

  .rainforest-alliance-image {
    width: 100%;
    height: 100%;
  }

  .short-description {
    display: none; // Only shown for giftcards
  }

  .advisory,
  .ingredients,
  .precautionary {
    margin-bottom: 20px;
  }

  .nutritional-information {
    --nutritional-info-max-width: 500px;
    width: 100%;
    max-width: var(--nutritional-info-max-width);
    margin: auto;
  }

  .nutritional-information__title {
    @include text--pdp-section-title;
  }

  .nutritional-information__table {
    margin-top: -10px;

    @include media('>=tablet') {
      max-width: var(--nutritional-info-max-width);
    }

    .col.per-serve,
    .col.per-100g {
      @include text--nutritional-info-column-label;
    }

    .col.per-serve {
      padding-right: 12px;
    }

    .col.item {
      width: 100%;
    }

    .nutrition-label {
      position: relative;
      overflow: hidden;
      padding: 0;
    }

    .nutrition-label__text {
      padding-right: 10px;
    }

    .nutrition-label__dots::after {
      position: absolute;
      content: '....................................................................................................';
      font-size: 16px;
      line-height: 32px;
      color: $color--neutral--grey;
      letter-spacing: 4px;
    }

    .per-serve-value,
    .col.per-serve {
      padding-left: 10px;
    }

    .indented-nutrition {
      padding-left: 20px;
    }
  }

  .dietary-needs {
    display: block;
    margin: 3rem 0.5rem 1rem;

    @include media('>=tablet') {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 2rem 0 1rem;
    }
  }

  .dietary-needs__tag {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: $font-family-serif;

    @include media('>=tablet') {
      margin: 0 5rem 0 0;
    }

    &::before {
      @include icon('check-circle');
      margin-right: 1rem;
      color: $color--ui--pdp-details-highlight;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .dietary-needs__text {
    margin: 0;
  }
}

/**
 * Styling for the shipping information in the one page checkout order summary section
 * This is typically displayed on the payment step of the checkout
 */

.opc-block-shipping-information {
  @import 'aligent-scss-placeholder/components/opc-block-shipping-information';

  .ship-to {

  }

  .ship-via {

  }

  .shipping-information-title {

  }

  .action {

    &:hover {

    }
  }

  .shipping-information-content {

  }
}

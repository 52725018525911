@import '../elements/icons';
@import '../settings/typography';
@import '../settings/layout';
@import '../settings/colours';

$_tabs-border-height: 6px;
$_icon-height: 35px;
$_tabs-bar-from-top: 16px;

/**
 * Checkout tabs styling
 */
.checkout-app-progress-tabs {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: $_tabs-bar-from-top;
    width: 100%;
    height: $_tabs-border-height;
    border-top: $_tabs-border-height solid $color--ui--tab-indicator;
    border-radius: $_tabs-border-height;
  }

  &.checkout-app-progress-tabs--show-cart {
    .tab--active,
    .tab--completed {
      .checkout-app-progress-tab__completed-indicator--final {
        @include media('>=tablet') {
          $sectionWidthPercentageToCover: 50;
          $sectionWidthPercentageActual: 100/3;
          $widthRatio: $sectionWidthPercentageToCover / $sectionWidthPercentageActual;

          width: $widthRatio * 100%;
          left: -($widthRatio * 100% - 100%);
        }
      }
    }
  }
}

.checkout-app-progress-tab {
  flex-basis: 33%;
  position: relative;

  text-align: center;
  background: transparent;

  &.tab--extended {
    @include media('>=tablet') {
      flex-basis: 45%; // This closes up the white gap caused by decimal rounding differences
    }
  }
}

.checkout-app-progress-tab__completed-indicator {
  display: inline-block;
  position: absolute;
  top: $_tabs-bar-from-top;
  left: 0;
  width: 0;
  height: $_tabs-border-height;
  background: $color--ui--tab-indicator;

  .tab--active &,
  .tab--completed & {
    background: $color--ui--tab-indicator-active;
    width: 100%;
    left: -50%;
  }

  .tab--extended & {
    @include media('>=tablet') {
      width: 150%;
      left: 0;

      &:last-child {
          left: -75%;
      }
    }
  }

  &--final {
    .tab--active &,
    .tab--completed & {
      left: 50%;
      width: 50%;

      @include media('>=tablet') {
        $sectionWidthPercentageToCover: 50;
        $sectionWidthPercentageActual: 45;
        $widthRatio: $sectionWidthPercentageToCover / $sectionWidthPercentageActual;

        width: $widthRatio * 100%;
        left: -($widthRatio * 100% - 100%);
        background: $color--ui--tab-indicator-final;
      }
    }
  }

  .tab--completed:first-child & {
    width: 50%;
    left: 0;
  }

}

.checkout-app-progress-tab__label {
  @include text--heading-3;
  display: inline-block;
  margin-top: 12px;
  text-transform: initial;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  color: $color--ui--tab-color;

  .tab--active &,
  .tab--completed & {
    color: $color--ui--tab-color-active;
  }

  .not-small {
    @include media('<=sm') {
      display: none;
    }
  }
}

/**
 * Checkout tab button (progress bar item) styling
 */
.checkout-app-progress-tab__button {
  @include reset-button;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1; // above tabs line
  text-decoration: none;
  color: $color--ui--tab-color;
  border-radius: 0;

  &:hover,
  &:focus {
    @include reset-button;
  }
}

.checkout-app-progress-tab__icon {
  display: flex;
  justify-content: center;
  margin: auto;
  width: $_icon-height;
  height: $_icon-height;
  line-height: $_icon-height;
  border-radius: 50%;
  background: $color--ui--tab-icon;

  .tab.tab--completed &,
  .tab.tab--active & {
    background: $color--ui--tab-icon-active;
    color: $color--ui--tab-color-active;
  }
}

.tab--active,
.tab--completed {
  .tab-icon::before {
    color: $color--ui--tab-color-active;
  }
}

.tab--hidden {
    @include media('>=tablet') {
        display: none;
    }
}

.tab-icon {
  font-size: 16px;

  &::before {
    flex-shrink: 0;
    color: $color--ui--tab-color;
  }

  &.cart {
    margin-right: 1px;

    &::before {
      @include icon('cart', 'solid');
    }
  }

  &.truck::before {
    @include icon('truck', 'solid');
  }

  &.credit-card::before {
    @include icon('card', 'solid');
  }
}

/**
 * Checkout cart toggle button styling
 */
.checkout-app-shopping-bag__toggle-cart {
  display: none;

  @include media('>=tablet') {
    display: block;
    border: 1px solid #333333;
    border-radius: 6px;
    padding: 10px 15px 12px;
    text-transform: none;
    font-size: 14px;

    &::before {
      @include icon('cart', 'solid');
      font-size: 16px;
      margin-right: 8px;
    }

    &.showing-cart-content {
      &::before {
        content: '';
        margin-right: 0;
      }

      &::after {
        @include icon('times');
        margin-left: 10px;
      }
    }
  }
}

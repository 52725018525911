@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins';

$color-white: #ffffff;
$color-black: #000000;
$color-orange: #ff8f14;
$color-red: #FF0000;
$color-text-black: #707070;
$color-background-pearl: #F7F5F4;
$color-border-dark-grey: #b9b9b9;
$color-button-golden: #ccad50;
$color-action-black: #333333;
$color-border-light-grey: #cccccc;

.opc-wrapper,
.opc-summary-wrapper {
    width: 50%;

    @include media('<tablet') {
        width: 100%;
    }
}

.opc-block-summary {
    background: $_color-red-cream;
    padding: 40px 54px;
    margin-bottom: 0;

    @include media('>=tablet') {
        max-width: 510px;
    }

    >.title {
        font-family: $font-family-serif;
        font-size: 24px;
        color: $_color-mine-shaft;
    }

    .items-in-cart {
        display: flex;
        flex-direction: column;

        >.title {
            padding: 0 !important;
            color: $color-text-black;
            font-family: $font-family-serif;
            font-size: 18px;
            line-height: 23px;
            border: none;
            letter-spacing: 0;

            &:after {
                right: unset !important;
                top: unset !important;
                margin-left: 5px;
            }
        }

        .minicart-items {
            display: none !important;
        }
    }

    .items-in-cart.active {
        .minicart-items {
            display: block !important;
        }
    }
}

.checkout-index-index {

    @include media('>=tablet') {
        .columns:after {
            content: '';
            background: $color-background-pearl;
            height: calc(100% - 214px);
            width: 50%;
            display: block;
            position: absolute;
            top: 214px;
            right: 0;
            z-index: -1;
        }
    }

    .opc-estimated-wrapper {
        ~.messages {
            width: 50%;

            .message-error {
                padding: 5px 10px;
                margin: 0 20px 0 0;
            }
        }

        @include media('<tablet') {
            ~.messages {
                width: 100%;
                margin: 0 0 15px;
                
                .message-error {
                    margin: 0;
                }
            }
        }
    }

    .opc-summary-wrapper {
        .action-close {
            background: none;
            top: 0;
            right: 0;
        }

        .product-item {
            margin-bottom: 11px;

            > .product {
                display: flex;

            }

            .product-item-details {
                padding-left: 16px !important;
            }
            
            .cart.message.notice {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
            }

            .product-image-wrapper {
                padding: 0 !important;
            }

            .product-item-inner {
                display: flex;
                justify-content: space-between;
            }

            .product-item-name-block {

                .product-item-name,
                .details-qty span {
                    font-size: 14px;
                    font-family: $font-family-sans-serif;
                    color: $_color-mine-shaft;
                    line-height: 14px;
                }

                .product-item-name {
                    font-weight: bold;
                    margin-bottom: 6px;
                }
            }

            .subtotal {
                font-family: $font-family-sans-serif;
                color: $_color-mine-shaft;
                line-height: 14px;
                font-weight: bold;
            }

            .price {
                font-size: 14px;
                line-height: 20px;
                font-weight: bold;
            }
        }

        .table-totals {
            tbody {
                .mark {
                    padding: 0;
                    line-height: 28px;
                    color: $_color-mine-shaft;
                    letter-spacing: 0;

                    .value {
                        font-size: 12px;
                        color: $color-text-black;
                        line-height: 16px;
                        letter-spacing: 0;
                    }
                }

                .totals-tax {

                    .mark,
                    .amount {
                        padding-bottom: 14px;
                    }
                }
            }

            .amount {
                padding: 0;
                font-size: 14px;
                line-height: 28px;
                color: $_color-mine-shaft;
                letter-spacing: 0;
            }

            .grand.totals {
                border-color: $color-border-dark-grey;

                .mark,
                .amount {
                    font-size: 18px;
                    line-height: 28px;
                    padding: 14px 0 28px;
                }
            }

            .excl {
                display: none;
            }
        }
    }
}

.checkout-shipping-step {
    .opc-block-summary {
        display: flex;
        flex-direction: column;

        .loading-mask {
            background: $color-background-pearl;
        }

        >.title {
            order: 1;
        }

        .table-totals {
            order: 3;
        }

        .items-in-cart {
            order: 2;
            margin-bottom: 47px;
        }

        >p {
            order: 4;
            font-size: 14px;
            line-height: 20px;
            margin-top: 47px;
            letter-spacing: 0;
        }
    }

    .table-totals {

        .totals.sub,
        .totals-tax,
        .grand.excl {
            display: none;
        }

        tbody {
            display: flex;
            flex-direction: column;

            .mark {
                width: 100%;
            }
        }

        .shipping {
            order: 1;
            display: flex;

            .mark,
            .amount {
                width: 50%;
            }

            .value {
                display: none;
            }
        }

        .grand.incl {
            order: 2;
            border: 0;

            .mark,
            .amount {
                padding: 0 !important;
            }

            .mark {
                white-space: unset;

                >strong:after {
                    content: '(incl. GST, incl. shipping)';
                    font-size: 14px;
                    font-weight: 400;
                    padding-left: 5px;
                }
            }
        }
    }
}

.checkout-payment-step {

    .opc-estimated-wrapper {
        ~.messages {
            display: none;
        }
    }

    .opc-summary-wrapper {
        .table-totals {
            .grand.incl {
                .mark {
                    display: flex;
                }
                .price {
                    display: flex;
                    flex-direction: column;

                    &:after {
                        content: 'incl GST';
                        font-size: 14px;
                        font-weight: 400;
                        padding-left: 5px;
                    }
                }
            }

            .shipping {
                .value {
                    display: none;
                }
            }
        }
    }

    .opc-payment {
        margin-top: 38px;
    }

    .opc-block-summary {
        >p {
            display: none;
        }
    }
}

.discount-code {
    .payment-option-content {
        display: none !important;
    }

    &._active {
        .payment-option-content {
            display: block !important;
        }
    }

    .messages {
        .message-error {
            border-radius: 6px;
            font-size: 11px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
    }
}

.checkout-payment-step .opc-block-shipping-information {
    background: $_color-red-cream;
    padding: 0 54px 40px;

    @include media('<tablet') {
        padding: 100px 0 40px !important;
    }

    @include media('>=tablet') {
        max-width: 510px;
    }

    .ship-to,
    .ship-via {
        padding: 0;
    }

    .ship-to {
        margin-bottom: 40px;
    }
}

#shipping,
#payment {
    @include media('>=tablet') {
        margin-top: -240px;
        padding-top: 240px;
    }

    @include media('<tablet') {
        margin-top: -270px;
        padding-top: 270px;
    }
}

.gift-message-sidebar-block {
    padding: 0 54px;

    @include media('>=tablet') {
        max-width: 510px;
    }

    @include media('<tablet') {
        padding: 0 !important;
    }

    .opc-block-git-message {
        padding: 28px 15px 0;
        font-size: 14px;
        letter-spacing: 1px;
    }

    .gift-message-title {
        font-family: $font-family-serif;
        font-size: 18px;
        border-bottom: 1px solid $color-border-dark-grey;
        letter-spacing: 0;
    }
}


.opc-block-git-message {
    letter-spacing: 0;
    display: flex;
    flex-wrap: wrap;

    @include media('<tablet') {
        padding: 0;
    }

    span:nth-child(2n+1) {
        flex-basis: 25%;
    }
    
    span:nth-child(2n) {
        flex-basis: 75%;
    }
}

#co-shipping-method-form {
    .opc-block-git-message {
        padding: 10px 0;
        font-size: 14px;
        margin-bottom: 20px
    }

    #edit-gift-message {
        @include button-style--secondary;
    }

    .message.notice {
        background: $color-orange !important;
        padding: 10px;
        margin-top: 10px;
    }
}

.modal-content {
    .form-shipping-address {

        .fieldset.address .field .control {
            input,
            .select {
                border: 1px solid $_color-grey-text;
                background: $_color-white;
                border-radius: 6px;
                padding: 0 15px;
            }
        }
        
        .field._error{
            .control {
                border: 1px solid $color-orange;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-bottom-left-radius: 22px;
                border-bottom-right-radius: 22px;
            }

            .field-error {
                background: $color-orange;
                color: $color-white;
                text-align: center;
                border-bottom-left-radius: 22px;
                border-bottom-right-radius: 22px;
                text-transform: uppercase;
                line-height: 20px;
                font-size: 11px;
                font-weight: 700;
                padding-top: 8px;
            }
        }

        .field._warn {
            .message.warning {
                display: none;
            }
        }
    }

    ~.modal-footer {
        .secondary.action-hide-popup {
            background: $color-black;
            color: $color-white;
        }
    } 
}

.ui-helper-hidden-accessible {
    display: none;
}

.checkout-index-index {
    > .loading-mask {
        p {
            position: absolute;
            top: 54vh;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
        }
    }
}

.product-item-details {
    .product.options.active {
        .content {
            letter-spacing: 0;
        }

        .item-options {
            display: flex;
            flex-wrap: wrap;

            .label {
                flex-basis: 50%;
                max-width: 50%;
                font-weight: 500;
            }

            .value {
                flex-basis: 50%;
                max-width: 50%;
                margin: 0;
            }
        }
    }
}

@include media('>=tablet') {
    .opc-block-custom-text {
        max-width: 510px;
        padding: 0 54px;
    } 
}

.checkout-payment-step,
.checkout-shipping-step {
    .payment-method {
        .payment-method-title {
            border: 0;
            padding: 0 0 15px;
            letter-spacing: 0;

            .radio:checked {
                accent-color: $color-button-golden;
            }

            .label {
                font-weight: 400;
            }
        }

        .payment-method-content {
            .billing-address-same-as-shipping-block {
                margin-bottom: 10px;
                font-size: 14px;
                letter-spacing: 0;
                font-weight: 900;

                input:checked {
                    accent-color: $color-button-golden;
                }
            }

            .billing-address-details {
                font-size: 14px;
                letter-spacing: 0;
                margin-bottom: 20px;

                a {
                    color: $color-button-golden;
                }

                .action-edit-address {
                    margin-top: 10px !important;
                    @include button-style--secondary;
                }
            }
        }

        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

            #pinpayments_cc-form .fieldset {
                #pinpayments_cc_cc_owner-error,
                #pinpayments_cc_cc_number-error,
                #pinpayments_cc_expiration-error,
                #pinpayments_cc_expiration_yr-error,
                #pinpayments_cc_cc_cid-error {
                    display: none !important;
                }

            .name:has(.input-text.mage-error)  {
                ~#pinpayments_cc_cc_owner-error {
                    display: block !important;
                    background: $color-orange;
    
                    ~#pinpayments_cc_cc_owner-error {
                        display: none !important;
                    }
                }
            }

            .number:has(.input-text.mage-error) {
                ~#pinpayments_cc_cc_number-error {
                    display: block !important;
                    background: $color-orange;
    
                    ~#pinpayments_cc_cc_number-error {
                        display: none !important;
                    }
                }
            }

            .month:has(.select-month.mage-error) {
                ~#pinpayments_cc_expiration-error {
                    display: block !important;
                    background: $color-orange;
    
                    ~#pinpayments_cc_expiration-error {
                        display: none !important;
                    }
                }
            }

            .year:has(.select-year.mage-error) {
                ~#pinpayments_cc_expiration_yr-error {
                    display: block !important;
                    background: $color-orange;
    
                    ~#pinpayments_cc_expiration_yr-error {
                        display: none !important;
                    }
                }
            }

            .cvv:has(.input-text.mage-error) {
                ~#pinpayments_cc_cc_cid-error  {
                    display: block !important;
                    background: $color-orange;
    
                    ~#pinpayments_cc_cc_cid-error {
                        display: none !important;
                    }
                }
            }

            .field { 
                box-shadow: none !important; 

                .input-text.mage-error {
                    border-top: 1px solid $color-orange;
                    border-left: 1px solid $color-orange;
                    border-right: 1px solid $color-orange;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    .purchase-order-method {
        display: none;
    }

    .payment-method-content {
        .form {
            .type {
                margin: 0 !important;
            }

            .credit-card-types {
                list-style-type: none;
                display: flex;
                gap: 10px;
                padding: 0;
                flex-wrap: wrap;
            }
        }

        .actions-toolbar {
            justify-content: flex-end;

            .action {
                letter-spacing: 0;
                text-transform: unset;
            }
        }
    }

    .payment-option {
        cursor: pointer;

        .payment-option-title {
            padding: 36px 0 14px;
            font-family: $font-family-serif;

            .action {
                font-size: 16px;
                letter-spacing: 0;
            }
        }

        .form-discount {
            display: flex;
            margin-bottom: 20px;

            .field,
            .actions-toolbar {
                margin: 0 !important;
            }

            .payment-option-inner {
                width: 80%;
            }

            .actions-toolbar {
                width: 20%;
                display: block;

                .action {
                    background: $color-action-black;
                    color: $color-white;
                    text-transform: unset;
                    font-size: 14px;
                    letter-spacing: 0;
                    padding: 14px 0;
                }
            }

            .field {
                ~ #discount-code-error{
                    display: none !important;
                }
    
                &:has(.input-text.mage-error)  ~#discount-code-error {
                    display: block !important;
                    margin-top: 0;
                    background: $color-orange;
                    padding-top: 8px !important;
    
                    ~#discount-code-error{
                        display: none !important;
                    }
                }
            }
        }
    }

    .giftcardaccount {
        .payment-option-title {
            border: 0;
        }

        .form-giftcard-account {
            .label {
                display: none;
            }

            .field {
                margin-bottom: 0 !important;
                box-shadow: none !important;

                ~ #giftcard-code-error{
                    display: none !important;
                }
    
                &:has(.input-text.mage-error)  ~#giftcard-code-error{
                    display: block !important;
                    margin-top: -16px;
                    background: $color-orange;
                    padding-top: 8px !important;
    
                    ~#giftcard-code-error {
                        display: none !important;
                    }
                }

                ~ #gift-card-pin-error{
                    display: none !important;
                }
    
                &:has(.input-text.mage-error)  ~#gift-card-pin-error{
                    display: block !important;
                    margin-top: -16px;
                    background: $color-orange;
                    padding-top: 8px !important;
    
                    ~#gift-card-pin-error {
                        display: none !important;
                    }
                }
            }
        }

        .messages {
            .message-error {
                border-radius: 6px;
                font-size: 11px;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 5px;
                background: #03A88F !important;
            }
        }

        .actions-toolbar {
            flex-direction: row-reverse;

            .action {
                text-transform: unset;
                font-size: 14px;
                letter-spacing: 0;
            }

            .secondary .action {
                background: $color-white;
                color: $color-action-black;
                border: 1px solid $color-border-dark-grey;
            }

            .primary .action {
                color: $color-white;
                background: $color-action-black;
            }
        }
    }

    .opc-payment .payment-method-content >.actions-toolbar .primary {
        .action {
            font-size: 24px;
            line-height: 34px;
            padding: 15px 26px;
            border-radius: 10px;
            text-transform: uppercase;
        } 

        @include media('<tablet') {
            width: 100%;
            margin: 0 0 0 -15px;

            .action {
                width: 100%;
            }
        }
    }

    .payment-method-billing-address {
        .action-cancel {
            background: $color-black;
            color: $color-white;
        }

        .fieldset.address {
            .field._error {
                .control {
                    border: 1px solid $color-orange;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    border-bottom-left-radius: 22px;
                    border-bottom-right-radius: 22px;
                }
    
                .field-error {
                    background: $color-orange;
                    color: $color-white;
                    text-align: center;
                    border-bottom-left-radius: 22px;
                    border-bottom-right-radius: 22px;
                    font-size: 11px;
                    line-height: 21px;
                    text-transform: uppercase;
                    font-weight: 700;
                }
            }

            .field.street {
                .field {
                    .label {
                        display: none;
                    }
                }
            }
        }
    }

    .opc-block-shipping-information {
        .shipping-information-title {
            font-family: $font-family-serif;
            font-size: 18px;
            border-color: $color-border-dark-grey;
            letter-spacing: 0;
        }
    
        .shipping-information-content {
            font-size: 14px;
            line-height: 25px;
            padding: 28px 15px 0;
    
            a {
                color: #085EB4;
            }
    
            table {
                width: 100%;
    
                .sq-td-right {
                    font-weight: 700;
    
                    span {
                        float: left;
                    }
    
                    .actions-gift-card {
                        .action-delete:before {
                            content: "\f00d";
                            @include icon('close', 'solid');
                            color: $color-action-black;
                        }
                    }
                }
            }
        }
    }
}

@include media('<tablet') {
    .checkout-index-index {
        .opc-summary-wrapper.custom-slide {
            left: 0;
            -webkit-transform: translateX(0);
            transform: translateX(0);

            &._show {
                background: $color-background-pearl;
                padding: 20px 22px 100px;
            }
        }

        .custom-slide {
            left: auto;
        }

        .action-close {
            right: 0;
        }

        .opc-block-shipping-information {
            padding: 0 22px;
        }

        .opc-summary-wrapper {
            .opc-block-summary {
                padding: 0;

                .title {
                    padding-bottom: 26px;
                }

                >p {
                    margin-top: 37px;
                }
            }

            .minicart-items {
                padding-top: 18px;
            }
        }

        .opc-estimated-wrapper {
            .estimated-block {
                letter-spacing: 0;

                .estimated-price:after {
                    content: '(incl. GST)';
                    font-size: 14px;
                    font-weight: 400;
                    padding-left: 5px;
                }
            }
        }
    }

    .checkout-payment-step {
        .payment-group {
            .step-title {
                border-bottom: 1px solid $color-border-dark-grey !important;
                padding: 0 14px !important;
                margin-bottom: 26px !important;
            }
        }

        .payment-method {
            padding: 0 14px;
        }

        .payment-option {
            .payment-option-title {
                padding: 15px 14px 12px;
                margin: 0 -15px;
            }
        }

        .giftcardaccount {
            .payment-option-title {
                border-top: 1px solid $color-border-dark-grey;
            }
        }
    }
}

// ----------- Checkout Design ---------------
.checkout-index-index {
    .opc-wrapper {
        @include media('>=tablet') {
            margin-bottom: 190px;
            padding-right: 50px;
        }

        .step-title {
            font-family: $font-family-serif;
            font-size: 21px;
            line-height: 60px;
            letter-spacing: 0;
            border: 0;
            padding: 0;
            margin: 0;
            @include media('<tablet') { 
                line-height: 20px;
                padding-bottom: 10px !important;
            }
        }

        .step-content .form {
            .field {
                .label {
                    font-size: 14px;
                    line-height: 20px;
                    color: $_color-mine-shaft;
                }

                .control {

                    input,
                    .select {
                        border: 1px solid $_color-grey-text;
                        background: $_color-white;
                        border-radius: 6px;
                        padding: 0 15px;
                    }
                }

                &._required,
                &.required {
                    .label:after {
                        content: '*';
                        color: $color-red;
                        padding-left: 3px;
                    }
                }
            }

            .street {
                margin-bottom: 0 !important;
            }
        }

        .form-login {
            border-bottom: 0;
            margin: 0 0 34px;
            padding: 0;
            max-width: unset;

            @include media('<tablet') {
                margin: 0 0 20px;
            }

            .field,
            .fieldset {
                margin: 0 !important;
            }

            .note {
                display: none !important;
            }

            #customer-email-fieldset.fieldset {
                #customer-email-error {
                    display: none !important;
                }

                &:has(.input-text.mage-error)  #customer-email-error{
                    display: block !important;
                    margin-top: 0;
                    background: $color-orange;

                    ~#customer-email-error {
                        display: none !important;
                    }
                }

                .field { 
                    box-shadow: none !important; 
    
                    .input-text.mage-error {
                        border-top: 1px solid $color-orange;
                        border-left: 1px solid $color-orange;
                        border-right: 1px solid $color-orange;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
    
                        ~ .note {
                            display: none !important;
                        }
                    }
                }
            }
        }

        .newsletter-subscription-form{
            font-size: 14px;
            letter-spacing: 0;
            color: $color-action-black;
            margin-bottom: 40px;

            .payment-option-inner {
                display: flex;
            }

            input {
                margin-right: 20px;
                width: 22px;
                height: 22px;
            }
        }

        .form-shipping-address {
            margin: 0;
            max-width: unset;

            .field._error{
                .control {
                    border: 1px solid $color-orange;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    border-bottom-left-radius: 22px;
                    border-bottom-right-radius: 22px;
                }
    
                .field-error {
                    background: $color-orange;
                    color: $color-white;
                    text-align: center;
                    border-bottom-left-radius: 22px;
                    border-bottom-right-radius: 22px;
                    text-transform: uppercase;
                    line-height: 20px;
                    font-size: 11px;
                    font-weight: 700;
                    padding-top: 8px;
                }
            }

            .field._warn {
                .message.warning {
                    display: none;
                }
            }
        }
    }

    .checkout-shipping-method {
        #checkout-shipping-method-load {
            .table-checkout-shipping-method {
                letter-spacing: 0;
                text-align: left;
            }

            .note {
                font-size: 14px;
                letter-spacing: 0;
                color: $color-text-black;
            }
        }
    }
}

// Square Payment

.sq-payment-method {
    .sq-accordion,
    .sq-accordion-gift-card {
        .payment-option .payment-option-title {
            font-family: $font-family-sans-serif;
            padding: 20px 0;
            color: $color-button-golden;
            letter-spacing: 0;
            font-weight: 600;
            display: flex;
            column-gap: 10px;
            align-items: baseline;
            border: none !important;

            @include media('<tablet') {
                padding: 15px 0;
                border-top: 1px solid $color-border-light-grey;
                margin: 0;
            }  
        }

        #square_form_fields {
            margin: 0 0 10px;
        }
    }

    .sq-accordion {
        .payment-option .payment-option-title {
            width: 80%;

            @include media('<tablet') {
                width: 100%;
            }

            &::after {
                content: "\f078";
                display: block !important;
                width: 20px;
                height: 20px;
                @include icon('down-arrow', 'solid');
                color: $color-border-light-grey;
            }
        }

        .payment-option._active {
            .payment-option-title {
                &::after {
                    content: "\f077";
                    display: block !important;
                    width: 20px;
                    height: 20px;
                    @include icon('up-arrow', 'solid');
                    color: $color-border-light-grey;
                }
            }
        }

        #square_form_fields {
            [data-bind="visible: (getCanSaveCards() && displaySaveCcCheckbox())"] {
                display: none;
            }
        }

        .payment-option[method="googlePay"],
        .payment-option[method="applePay"] {
            + div[data-role="content"] {
                p {
                    margin: 0 0 10px;
                    letter-spacing: 0;
                    font-size: 14px;
                }

                .gpay-card-info-container {
                    outline: none;

                    &.focus {
                        outline: 1px auto -webkit-focus-ring-color;
                    }
                }
            }
        }
    }

    #payment_form_squareup_payment {
        margin-bottom: 45px;
    }

    #squareup_omni_google_pay {
        div + div {
            display: none;
        }
    }

    .sq-card-wrapper {
        .sq-card-iframe-container {
            border-color: $color-border-dark-grey;
        }

        &.sq-error {
            border-top: 1px solid $color-orange;
            border-left: 1px solid $color-orange;
            border-right: 1px solid $color-orange;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;

            .sq-card-message-error {
                background: $color-orange;
                color: $color-white;
                text-align: center;
                border-bottom-left-radius: 22px;
                border-bottom-right-radius: 22px;
                text-transform: uppercase;
                line-height: 20px;
                font-size: 11px;
                font-weight: 700;
                padding-top: 8px;
                margin: 0;

                &:before {
                    display: none;
                }
            }

            .sq-card-iframe-container {
                &:before {
                    display: none;
                }
            }
        }
    }

    .sq-accordion-gift-card {
        width: 80%;

        @include media('<tablet') {
            width: 100%;
        }

        &._active {
            .payment-option-title {
                &::after {
                    content: "\f077";
                    display: block !important;
                    width: 20px;
                    height: 20px;
                    @include icon('up-arrow', 'solid');
                    color: $color-border-light-grey;
                }
            }
        }

        .payment-option-title {
            &::after {
                content: "\f078";
                display: block !important;
                width: 20px;
                height: 20px;
                @include icon('down-arrow', 'solid');
                color: $color-border-light-grey;
            }
        }

        .form-giftcard-account {
            .actions-toolbar {
                justify-content: space-between;
            }
        }
    }
    #payment_form_squareup_payment {
        >.payment-option {
            display: none;
        }
    }
}

.checkout-index-index {
    .opc-block-summary {
        .actions-toolbar {
            display: none;
        }
    }

    @include media('<tablet') {
        .opc-block-summary .minicart-items-wrapper {
            margin: 0;
            max-height: 370px;
            padding: 15px 15px 0 0;
            border: 0;
            overflow-y: auto;
        }

        .checkout-shipping-method #checkout-shipping-method-load .note {
            padding-left: 30px;
        }

        .checkout-shipping-method .table-checkout-shipping-method th,
        .checkout-shipping-method .table-checkout-shipping-method td {
            text-align: left;
            font-size: 14px;

            &.col.col-method:has(.radio) {
                width: 15px;
            }

            &.col.col-price {
                width: 100px;
            }

        }
    }
}

.checkout-footer-row .pagebuilder-column.secure-row p {
    text-align: left;
    background: url('../images/lock-icon.png') no-repeat center left;
    background-size: 14px auto;
    padding-left: 30px;
}

.payment-card-box {
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
}


.payment-card-box .pagebuilder-column-group {
    width: 100%;
    max-width: 305px;
    margin: 0 auto;
}


.payment-card-box figure {
    margin: 0;
}

.payment-card-box h4 {
    font-size: 12px;
    margin-top: 25px;
    font-family: acumin-pro, sans-serif;
}

.payment-card-box .p-title {
    margin-bottom: 20px;
    text-align: center;
}

.payment-card-box .p-title img {
    margin: 0 auto;
}



.checkout-index-index {
    .form.form-login {
        @include media('<tablet') {
            .actions-toolbar {
                flex-wrap: wrap;

                .primary {
                    width: 100%;

                    .action.login.primary {
                        margin-bottom: 15px;
                        width: 100%;
                    }
                }

                .secondary {
                    width: 100%; 
                    .action.remind {
                        width: 100%;
                    }
                }
            }
        }

    }


    #shipping-method-buttons-container {
        .button.action.continue.primary {
            > span {
                &::after {
                    content: ">";
                    padding-left: 7px;
                }
            }
        }
    }

    .field._required .label:after, 
    .field.required .label:after {
        content: '*';
        color: $color-red;
        padding-left: 3px;
    }

    form[data-role="login"] {
        .action.action-login.secondary {
            span {
                text-transform: none;
            }
        }
    }

    .shipping-address-items {
        display: flex;
        flex-wrap: wrap;
    
        .shipping-address-item {
            width: 48%;       
            margin-right: 2%;
            padding: 20px;
            border-radius: 10px;
            margin-bottom: 15px;
            
            &.not-selected-item {
                border: 1px solid $color-black;

                .action-select-shipping-item {
                    font-size: 14 !important;
                    letter-spacing: 0 !important;
                    margin: 20px 0 0 0 !important;
                    @include button-style--outline();
                }
            }
            &.selected-item {
                background: $_color-white;
                border: 3px solid $color-button-golden;
                position: relative;
                
                &:before {
                    background: $color-button-golden;
                    border-bottom-left-radius: 7px;
                    color: $color-white;
                    padding: 7px;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 0;   
                    @include icon('tick', 'solid');
                }
            }
        }
    }

    .shipping-information-title .action.action-edit{
        background: none;

        > span {
            display: none;
        }
        &::after {
            content: "\f303";
            display: block !important;
            width: 20px;
            height: 20px;
            @include icon('edit', 'solid');
        }
    }

    #opc-new-shipping-address {
        overflow: auto;
        max-height: 500px;       
    }

    .modal-popup.modal-slide{
        .primary.action-save-address {
            margin-right: 20px;
        }
    }

    button {
        background: linear-gradient(144.32deg, #F4D869 0%, $color-button-golden 100%);
        color: $color-black;
    }

    .new-address-popup {
        margin: 20px 0;

        .action-show-popup {
            @include button-style--secondary;
        }
    }

    .opc-block-custom-text {
        &.mobile-elements {
            display: none;
        }
    }

    @include media('<tablet') {
        .opc-block-custom-text {
            display: none !important;
            &.mobile-elements {
                margin-top: 100px;
                margin-bottom: 70px;
                display: block !important;
            }
        }
    }  
}


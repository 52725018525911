@import '../settings/colours';
@import '../tools/mixins-animation';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
@import '../tools/z-index';
/**
 * Newsletter Sticky
 */

.newsletter-sticky {
  @include zIndex('newsletter-sticky');
  @include transition('transform');
  position: fixed;
  left: 0;
  bottom: 15px;
  transform: translate3d(-10px, 100%, 0);

  .newsletter-sticky__link {
    @include button-style--secondary--alt;
    padding: 20px 20px 10px;
    transform: rotate(-90deg);
    transform-origin: top left;
  }

  &:hover,
  &:active,
  &:focus-within {
    transform: translate3d(0, 100%, 0);
  }

  @include media('>=tablet') {
    bottom: 30px;
  }

  @include media('>=site') {
    bottom: 50px;

    .newsletter-sticky__link {
      font-size: 22px;
      padding: 35px 40px 21px;
    }
  }
}

@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins-form-elements';
@import '../tools/mixins-button';
@import '@aligent/frontend-toolkit/scss/mixins/reset-button';

$_input-offset: $input-offset;
$_input-height: $input-height;

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: $color--neutral--white;
  color: $color--ui--input-text;
  box-shadow: 0 2px 4px 0 $color--neutral--mercury;
  border-radius: $input-offset;
  overflow: hidden;
}

.react-autosuggest__container {
  position: relative;
  margin-bottom: 1rem;

  .input-component {
    margin-bottom: 0;
  }

  .react-autosuggest__input {
    padding-right: 50px; // allow for remove btn
  }
}

.address-autosuggest-remove__button {
  @include button-style--clear;
  position: absolute;
  top: 0;
  right: 0;
  padding: $_input-offset / 2 $_input-offset;
  height: $_input-height;
  border-radius: 0 $_input-offset $_input-offset 0;
  background-color: transparent;
  line-height: 1;
  z-index: 1;

  &::after {
    @include icon('close', 'light');
  }
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  z-index: 1;
}

.react-autosuggest__suggestion {
  @include text--checkout-autocomplete-suggestion;
  border-top: 1px solid $color--neutral--mercury;
  border-bottom: 1px solid $color--neutral--mercury;
  padding: 14px 24px;
  margin-top: -1px;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  &:first-child {
    border-top: 0;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: $color--neutral--red-cream;
  z-index: 1;
  position: relative;
}

.autocomplete-address__toggle-full-form-container {
  @include text--checkout-autocomplete-subtext;
  margin: -5px 10px 20px; // pull up to account for input margin error effect
  text-align: center;
}

.autocomplete-address__toggle-full-form {
  @include button--padded-link;
}

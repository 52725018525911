/**
 * Styling for the minicart-wrapper, which allows for contextual, positional styling
 */

.minicart-wrapper {
  .block-minicart {
    display: none; // Hidden by default to prevent temporary flash as jQuery widget loads and takes control of drop down
  }

  .ui-dialog {
    .block-minicart {
      display: inherit; // Revert display:none; fix above
    }
  }
}

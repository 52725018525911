/**
 * Styling for the toolbar that is display on the PLP
 * This toolbar usually contains; count of items, sorting options, pagination options and view mode (grid/list)
 */

.toolbar-products,
.toolbar-sorter-wrapper,
.order-products-toolbar {
  @import 'aligent-scss-placeholder/components/toolbar-products';
}

/**
 * Utility / general purpose classes
 */

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.hidden {
  display: none !important; // important is necessary to override anything with higher specificity
}

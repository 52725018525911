@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins';

#checkout-step-shipping_method {
    .cart-gift-item {
        .title span{
            font-family: $font-family-serif;
            font-size: 21px;
            color: $_color-mine-shaft;
            cursor: pointer;
            display: block;
            margin: 80px 0 30px;

            @include media('<tablet') {
                margin-top: 50px;
            }
        }

        .input-label {
            font-size: 14px;
            letter-spacing: 0;
        }

        #gift-message-toggle:checked {
            accent-color: #ccad50 !important;
        }
    }

    .gift-options {
        .gift-options-title {
            display: none;
        }

        .actions-toolbar {
            text-align: left;
        }
    }

    .gift-options-content {
        margin-top: 14px;
        
        .field {
            margin: 0 0 12px;
        }
    
        input,
        textarea {
            border: 1px solid $_color-grey-text;
            background: $_color-white;
            border-radius: 6px;
            padding: 0 15px;

            &::placeholder {
                font-size: 14px;
                color: $_color-grey-text;
            }
        }
    
        input {
            height: 47px;
        }

        .note {
            font-size: 10px;
            line-height: 30px;
            margin: 0;
        }
    }
}


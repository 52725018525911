@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
/**
 * Styling for the delivery estimator
 */

.delivery-estimator {
  .delivery-estimator__toggle {
    // styled in _header-sticky.scss
  }
}

.delivery-estimator__modal {
  @include modal(
    $curtain-selector: '.delivery-estimator__modal__curtain',
    $container-selector: '.delivery-estimator__modal__container',
    $content-selector: '.delivery-estimator__modal__content',
    $close-selector: '.delivery-estimator__close',
    $heading-selector: '.delivery-estimator__heading'
  );

  // overrides mixins-form-elements.scss
  form div.mage-error {
    margin-bottom: 0;
    display: none;

    &.animating,
    &.open {
      display: block;
      margin-top: -20px;
    }
  }

  .delivery-estimator__form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    @include media('>=small-tablet') {
      flex-wrap: nowrap;
    }
  }

  // wrapper
  .delivery-estimator__form__postcode {
    width: 100%;
    margin-bottom: 15px;

    @include media('>=small-tablet') {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }

  // !importants - mixins-form-elements.scss
  .delivery-estimator__postcode-field {
    z-index: 1; // bump for error sibling presented underneath
    margin-bottom: 0 !important;
    height: 50px !important;

    .label {
      top: 11px !important;
    }

    .control {
      @include text--medium;
      top: 16px !important;
    }

    .input-text {
      height: 50px !important;
      padding-top: 13px !important;
    }
  }

  .delivery-estimator__form__actions {
    @include media('<small-tablet') {
      &,
      .delivery-estimator__submit {
        width: 100%;
      }
    }
  }

  .delivery-estimator__submit {
    @include button-style--primary;
  }

  // init hidden and revealed on success in hc-delivery-estimator.js
  .delivery-estimator__result {
    border-bottom: $color--ui--border-light;

    label {
      @include text--delivery-estimator-result-label;
    }

    &.error {
      label {
        display: none;
      }

      .delivery-estimator__result__text {
        color: $_color-ui-error;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .delivery-estimator__result__content {
    padding: 21px 0 27px;
  }

  .delivery-estimator__result__text {
    @include text--delivery-estimator-results;
    color: $color--neutral--grey;
  }

  .delivery-estimator__footnote {
    @include text--footnote;
    color: $color--neutral--grey;
    margin-top: 25px;

    a {
      color: $color--neutral--grey;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: $color--ui--link-text-hover;
      }
    }
  }

  &.loading {
    .delivery-estimator__postcode {
      @include loading-spinner;

      &::after {
        left: auto;
        right: 0;
      }
    }
  }
}

/**
 * CMS Page Common Styling
 */

.cms-page-view {
  @import 'aligent-scss-placeholder/contexts/cms';

  .product-items.widget-product-grid {
    @include product-grid;
  }
}

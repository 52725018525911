/**
 * Layout mixins
 */

/**
 * Position an element center center
 */
@mixin pos-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/**
 * Position an element absolute and fill closest relative container
 */
@mixin pos-abs-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/**
 * Position an element fixed and fill viewport
 */
@mixin fixed-fill {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin full-bleed($important: '') {
  width: 100vw;
  margin-left: 50% #{$important};
  transform: translateX(-50%);
}

@mixin full-bleed-fill {
  position: absolute;
  width: 100vw !important; // overrides pagebuilder -- TODO add param for it
  height: 100%;
  top: 0;
  left: calc((100vw - 100%) / -2);
}
.full-bleed-fill {
  @include full-bleed-fill;
}

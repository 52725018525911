@import 'include-media/dist/include-media';
/**
 * Layout variables - breakpoints, spacing, sizing
 */

$max-site-width: 1080px;

$gutter-tiny: 10px;
$gutter-small: 15px;
$gutter-medium: 20px;
$gutter-large: 30px;

// common values: 320,375,425,768,1024,1440
$breakpoint-mobile-small: 320px;
$breakpoint-mobile-medium: 375px;
$breakpoint-mobile-large: 425px;
$breakpoint-small-tablet: 540px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop-large: 1440px;
$breakpoints: (
  'sm': $breakpoint-mobile-small,
  'md': $breakpoint-mobile-medium,
  'lg': $breakpoint-mobile-large,
  'small-tablet': $breakpoint-small-tablet,
  'tablet': $breakpoint-tablet,
  'desktop': $breakpoint-desktop,
  'desktop-large': $breakpoint-desktop-large,
  'site': $max-site-width + $gutter-medium * 2
);

$min-button-size: 44px;

$ratio-golden: 1.618;
$ratio-16-9: 1.777;
$ratio-4-3: 1.333;
$ratio-9-16: 0.5625;

$border-radius-small: 3px;
$border-radius: 10px;
$border-radius-large: 22px;

$checkout-max-width: 1320px;

/**
 * Styling for the breadcrumbs component
 */

.breadcrumbs {
  @import 'aligent-scss-placeholder/components/breadcrumbs';

  .items { // <ul>

  }

  .item { // <li>

  }

  a {

  }

  // The currently active page, so bread crumb isn't a link
  strong {

  }


}

@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-button';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * New To Haighs
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--new-to-haighs {
  @include pb-reset-elements;

  [data-content-type='heading'] {
    text-align: center !important;
  }

  [data-content-type='text'] {
    max-width: 700px;
    padding: 0 20px !important;
    margin: 25px auto 14px !important;

    &,
    p {
      text-align: center !important;
    }

    @include media('>=md') {
      @include text--medium;
    }

    @include media('>=desktop') {
      @include text--large;
    }

    > * {
      @include first-last-no-end-margins(!important);
    }
  }

  [data-content-type='buttons'] {
    text-align: center !important;
  }

  [data-content-type='button-item'] {
    @include button--more-cta-link-with-arrow--light-uppercase(
      $anchor-selector: '> a'
    );
  }

  .block.widget {
    margin-top: 40px;

    .block-title {
      display: none;
    }

    .product-items {
      $spacing: 30px;
      margin: 0;
      padding: 0;

      @include media('<small-tablet') {
        @include flex-grid(1, $spacing);
      }
      @include media('>=small-tablet', '<tablet') {
        @include flex-grid(2, $spacing);
      }
      @include media('>=tablet') {
        @include flex-grid(3, $spacing);
      }

      .price-box {
        margin: 15px 0 0;
      }

      .normal-price .price-label {
        @include text--product-card-price-label--from-label;
        display: block;
        margin: 15px 0 5px;
      }

      .special-price {
        margin: 15px 0 5px;
      }

      .product-item-details__bottom-container {
        display: none; // hides swatches and add to cart button
      }

      .sly-old-price {
        display: none;
      }
    }
  }
}

@import '../tools/mixins-button';
/**
 * Base styling for button elements
 */

button,
.button {
  @include button-style--default;
}
.button--primary {
  @include button-style--primary;
}
.button--secondary {
  @include button-style--secondary;
}
.button--link {
  @include button-style--link;
}

.primary .action {
  @include button-style--primary;
}

.secondary .action {
  @include button-style--secondary;
}

.actions-toolbar {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

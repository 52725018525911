/**
 * cart-summary styling
 */

.cart-summary {
  @import 'aligent-scss-placeholder/components/cart-summary';

  .summary.title {

  }

  .table-wrapper {

  }

  .checkout-methods-items {

    .item {

    }

    .action {

    }
  }
}

@import '@aligent/frontend-toolkit/scss/mixins/reset-ul';
@import '../settings/colours';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-typography';
@import '../tools/z-index';
@import '../elements/icons';
/**
 * Styling for the filters - typically displayed on the PLP or search results page
 * Amasty Improved Layered Navigation
 */

/**
 * This rendering on About CMS page and I can't figure out why
 * Hide Filters on About page
 */
.cms-about .filter {
  display: none;
}

.filter {
  $_border-top-bottom: $color--ui--border-light;

  @include media('>=desktop') {
    margin-bottom: 3.5rem;
  }

  .filter__actions {
    position: relative;
    display: flex;
  }

  .filter__toggle {
    @include button--icon-text--actions;
    padding: 8px 14px;
    border: 1px solid $color--ui--border-color;
    text-transform: uppercase;

    &::before {
      @include icon('filters', 'solid');
    }

    @include media('>=desktop') {
      display: none;
    }
  }

  .filter__applied-filters {
    margin-bottom: 1rem;
  }

  .filter__detail {
    margin: 0 0;

    @include media('>=desktop') {
      display: none;
    }

    .filter-current {
      margin-top: 1rem;
    }

    .filter-current-subtitle {
      display: block;
      position: relative;
      padding: 0.5rem 1rem;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;

      &::after {
        content: ' (' attr(data-count) ')'
      }

      &::before {
        @include icon('down-arrow', 'solid');
        position: absolute;
        right: 1rem;
        top: 14px;
        transition: transform 0.2s ease-in-out;
      }
    }

    .items {
      @include reset-ul;
      display: none;
      margin: 1rem 0;
      padding: 0;

      .item,
      .am-shopby-item {
        margin: 0 1rem 0.5rem;
        padding: 0;
        display: flex;
        align-items: center;

        &.amshopby-item-removed {
          color: $color--neutral--dusty-grey;

          .amshopby-remove {
            color: $color--neutral--dusty-grey;
          }
        }

        .amshopby-filter-name {
          display: none;
        }

        .amshopby-filter-value {
          margin-left: 12px;
          line-height: 1.2;
        }

        .amshopby-remove {
          display: flex;
          height: 16px;
          width: 16px;
          flex-shrink: 0;

          &::after {
            @include icon('close', 'light');
          }
        }
      }
    }

    .filter__menu__clear {
      display: none;
      color: $color--brand--primary;
      font-size: 14px;
      letter-spacing: 0;
      padding: 0 1rem 1rem;
    }

    .filter-current.active {
      .items,
      & + .filter__menu__clear {
        display: inline-block;
      }

      .filter-current-subtitle::before {
        transform: rotate(180deg);
      }
    }
  }

  // STICKY MENU
  .filter__menu-container {
    @include zIndex('filter-menu-sticky');
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    pointer-events: none;
  }

  .filter__curtain {
    @include curtain;
  }

  .filter__menu {
    @include transition('transform');
    background: $color--ui--sticky-bg;
    box-shadow: $color--ui--shadow-sticky-right;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(-110%);

    &::before {
      @include gold-strip;
    }

    &.apply-button-visible {
      padding-bottom: 80px; // Stops the "Apply" button overlaying menu content
    }
  }

  .filter__menu__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 20px;
  }

  .filter__menu__title {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
  }

  .filter__menu__close {
    @include button--cross;
    transform: translateX(-50%);
  }

  // MENU LIST
  .filter__menu-list {
    @include reset-ul;
    background: $color--ui--accordion-bg-active;
    border-top: $_border-top-bottom;
    border-bottom: $_border-top-bottom;
  }

  .filter__menu-list-item {
    overflow: hidden;

    &:not(:first-child) {
      border-top: $_border-top-bottom;
    }

    .filter__menu-list-item__toggle {
      @include button--accordion-level-0;
      font-size: 16px;

      &::after {
          right: 30px;
      }
    }

    &.ui-state-active {
      .filter__menu-list-item__toggle {
        @include button--accordion-level-0--active;
      }
    }

    // AMASTY FILTER LIST
    .items {
      @include reset-ul;
      display: flex;
      flex-direction: column;
      min-width: 0;
      max-width: none;
      margin-bottom: 10px;

      .item {
        @include styled-checkbox(
          $label-class: '[class^="am-filter-item-"]',
          $input-class: 'input[type="checkbox"]',
          $size: 12px
        );
        position: relative;
        font-size: 14px;
        margin: 5px 20px;

        input {
          display: none;
        }

        [class^="am-filter-item-"] {
          @include button--amasty-filter-item;
          margin: 4px 0;

          &::before {
            margin-top: 2px;
            border-radius: 2px;
            box-shadow: 0 0 0 1px $color--neutral--grey;
          }
        }

        .amshopby-link-selected::before {
          background: $color--brand--primary;
          box-shadow: 0 0 0 1px $color--brand--primary;
        }

        .amshopby-link-selected::after {
          @include styled-checkbox-after($size: 12px, $rounded: 0, $padding: 1px);
          top: 5px;
          left: 1px;
          font-size: 10px;
          color: $color--neutral--white;
          box-shadow: none;
        }

        .count {
          display: none;
        }
      }

      // radio buttons within the filters
      &.-am-singleselect [class^="am-filter-item-"] {
        &::before,
        &::after {
          border-radius: 50%;
        }
      }
    }

    // AMASTY FILTER ITEMS (variance from above)
    // Renders differently for sizes filter
    [data-am-js='swatch-options'] {
      display: flex;
      flex-direction: column;
      min-width: 0;
      max-width: none;
      margin-bottom: 10px;

      .am-swatch-wrapper {
        position: relative;
        margin: 5px 20px;

        input {
          display: none;
        }

        .am-swatch-link {
          @include button--amasty-filter-item;
        }
      }
    }
  }

  // FILTER MENU ACTIVE
  &.toggle-filters--active {
    .filter__menu-container {
      pointer-events: auto;
    }

    .filter__curtain {
      @include curtain--active;
    }

    .filter__menu {
      transform: translateX(0);
    }
  }

  // DESKTOP FILTERS
  @include media('>=desktop') {
    &::before {
      @include gold-strip;
    }
  }

  .filter-subtitle {
    text-transform: uppercase;
    display: block;
    margin-right: 50px;
  }

  .filter-options {
    display: none;
    background: $color--ui--sticky-bg;
    padding: 0.8rem 1rem;

    @include media('>=desktop') {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .filter-options-item {
    position: relative;
  }

  .filter-options-title {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.7px;
    font-weight: 700;
    margin-right: 2rem;
    cursor: pointer;

    &::after {
      @include icon('down-arrow', 'solid');
      margin-left: 2px;
    }
  }

  .filter-options-content {
    position: absolute;
    background: $color--ui--bg-default;
    left: 0;
    top: calc(100% + 0.8rem);
    box-shadow: $color--ui--shadow-filter-options;
    z-index: 2;

    .items {
      padding: 0.8rem;
      margin: 0;
      min-width: 260px;

      .item {
        @include styled-checkbox(
          $label-class: '[class^="am-filter-item-"]',
          $input-class: '.am-input',
          $size: 12px
        );
        padding: 0;
        margin: 0;

        [class^="am-filter-item-"] {
          align-items: flex-start;
          font-size: 14px;
          line-height: 1.3;
          padding: 0;
          margin: 0.3rem 0;

          &::before {
            margin-top: 4px;
            border-radius: 2px;
            box-shadow: 0 0 0 1px $color--neutral--grey;
          }
        }

        .amshopby-link-selected::before {
          background: $color--brand--primary;
          box-shadow: 0 0 0 1px $color--brand--primary;
        }

        .amshopby-link-selected::after {
          @include styled-checkbox-after($size: 12px, $rounded: 0, $padding: 1px);
          left: 1px;
          font-size: 10px;
          color: $color--neutral--white;
          box-shadow: none;
        }

        input {
          @include visually-hidden;
        }
      }

      .count {
        display: none;
      }

      .amshopby-choice {
        display: none;
      }

      &.-am-singleselect [class^="am-filter-item-"] {
        &::before,
        &::after {
          border-radius: 50%;
        }
      }
    }
  }
}

.page-layout-2columns-left .filter {
  @include media('>=desktop') {
    margin-right: 3rem;

    &::before {
      content: none;
    }

    .filters-inner-wrapper {
      $scrolledHeaderHeight: 95px;
      position: sticky;
      top: $scrolledHeaderHeight + 9px; // 9px added to add space between header and sticky filters
      display: flex;
      flex-direction: column;
    }

    .filter__detail {
      display: block;
      width: 240px;
      margin-top: 0;

      .items {
        display: block;
        margin: 0.5rem 0;

        .item {
          margin: 0 0 0.5rem;
        }
      }

      .filter__menu__clear {
        display: block;
        padding-left: 0;
      }
    }

    .filter-current {
      margin-top: 0;
    }

    .filter-current-subtitle {
      cursor: initial;
      padding: 0.4rem 0;

      &:before {
        content: none;
      }
    }

    .filter__applied-filters {
      background: transparent;
      margin-bottom: 0;
    }

    .filter-options {
      flex-direction: column;
      order: 1;
      align-items: stretch;
      background: transparent;
      padding: 0.2rem 0;

      .filter-subtitle {
        margin-right: 0;
        display: block;
        padding: 0.3rem 0;
        border-bottom: $color--ui--border-light;
      }

      .filter-options-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 0;
        padding: 0.5rem 0;
      }

      .filter-options-item {
        border-bottom: $color--ui--border-light;

        &.active {
          .filter-options-title::after {
            transform: rotate(180deg);
          }
        }

        .filter-options-content {
          position: static;
          box-shadow: none;
          background: transparent;         

          .items {
            min-width: unset;
            padding: 0.8rem 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.catalogsearch-result-index.page-layout-2columns-left .filter {
  margin-top: 4rem;
}

/* Filter menu "Apply" button  */
.am_shopby_apply_filters {
  $_sectionHeight: 56px;
  display: none;
  position: fixed;
  bottom: $_sectionHeight * -1;
  z-index: 999;
  width: 100%;
  transition: bottom .2s ease-in-out;
  background: $color--ui--sticky-bg;

  &.visible {
    display: block;
    bottom: 0;
  }

  .am-show-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-top: 1px solid $color--ui--border-color-light;

    .am-items {
      min-width: 70px;
      margin-right: 1rem;
    }

    .amshopby-button {
      @include button-style--primary;
      padding: 10px 24px;
    }
  }
}

@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins';

.checkout-footer-wrapper {
    background: $color--ui--bg-dark;
    width: 100vw;
    left: calc(50% - 50vw);
    position: relative;
    float: left;
    font-size: 12px;
    color: $color--ui--text-medium-on-dark;
    text-transform: uppercase;
    font-weight: 700;
}

.checkout-footer-row {
    @include site-wrapper;
    max-width: 1400px;

    .checkout-footer-image-section {
        flex-direction: row !important;

        img {
            height: 50px !important;
            max-width: none !important;
        }
    }

    .pagebuilder-column {
        justify-content: center !important;

        ul {
            display: flex;
            justify-content: space-around;
            list-style-type: none;
            padding-left: 0;
        }

        p {
            text-align: center;
        }

        a {
            color: $color--ui--text-medium-on-dark;
            font-weight: 700;
        }
    }
}

@include media('<tablet') {
    .checkout-footer-row {
        padding: 0 !important;
        
        .pagebuilder-column-group {
            flex-direction: column;
        }
        
        .pagebuilder-column {
            width: 100% !important;

            ul {
                flex-direction: row;
                margin: 27px 0 15px;
            }

            li {
                text-align: center;
            }
        }

        .links-row {
            order: 1;
        }

        .checkout-footer-image-section {
            order: 2;
        }

        .copyright-row {
            order: 3;
        }

        .secure-row {
            display: none !important;
            
        }
    }
}
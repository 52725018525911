/**
 * Contextual styling for the cart page
 */


 .ampromo-overlay {
  display: none;
}

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 899;
}

.opc-progress-bar {
  display: none;
}

.opc-wrapper .opc {
  margin: 0;
  padding: 0;
  list-style: none;
}

.checkout-index-index {
  .fieldset {
    border: 0;
    margin: 0 0 40px;
    padding: 0;
  }

  .loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);

    .loader>img {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
    }
  }

  .opc-wrapper .form-login {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
  }

  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  #checkout-step-payment {
    .legend {
      display: none;
    }
  }

  .fieldset>.legend+br {
    clear: both;
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .fieldset hr {
    display: none !important;
  }

  .opc-block-summary .mark .value {
    color: #666;
    display: block;
    font-size: 14px;
  }

  .cart-totals tbody .mark,
  .cart-totals tfoot .mark,
  .opc-block-summary .table-totals tbody .mark,
  .opc-block-summary .table-totals tfoot .mark {
    border: 0;
    font-weight: 400;
    padding: 6px 0;
    font-size: 14px;
  }

  .checkout-payment-method .payment-method-title {
    input[type="radio"] {
      margin-right: 10px;
    }

    .label::before {
      display: none;
    }
  }

  input[type="checkbox"] {
    margin: 2px 5px 0 0;
    position: relative;
    top: 2px;
  }

  form .field:not(.choice):not(.note):not(.qty):focus-within {
    outline: none;
    box-shadow: none;
    background: #FFFFFF;
  }

  .payment-method {
    .payment-method-title {
      padding: 20px 0;
      font-size: 18px;
      border-top: 1px solid #ccc;
      margin-bottom: 0;

      .label {
        font-size: 18px;
      }
    }
  }

  .opc-summary-wrapper {
    background: none;
    padding: 0;
    margin-top: 0;

    .opc-block-summary {
      >.title {
        font-size: 26px;
        font-weight: 300;
        padding-bottom: 20px;
        display: block;
        letter-spacing: 0;
      }

      .items-in-cart>.title {
        &:after {
          @include icon('angle-down', 'solid');
          position: absolute;
          right: 0;
          top: 10px;
        }

        strong {
          font-size: 18px;
          font-weight: 300;
          margin: 0;
        }
      }
    }

    .minicart-items {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .product-item {
      border: 0;
      display: block;
      margin-bottom: 0;

      &:first-child {
        border: 0;
      }

      .product {
        display: block;

        >.product-item-photo,
        >.product-image-container {
          float: left;
          width: 75px !important;
          height: 75px !important;
          padding-bottom: 0;
        }

        .product-item-details {
          padding-left: 88px;
          display: block;
          margin-top: 0;

          .product-item-name {
            font-size: 14px;
          }

          .product-item .price {
            font-size: 16px;
          }
        }
      }
    }
  }

}


.checkout-payment-method .payment-option._collapsible .payment-option-content {
  display: none;
}

.opc-wrapper .opc,
.opc-progress-bar,
.checkout-agreements-items,
.order-details-items .items-qty {
  margin: 0;
  padding: 0;
}


.authentication-wrapper {
  float: right;
  margin-top: -60px;
  max-width: 50%;
  position: relative;
  z-index: 1;
}

.opc-block-summary {
  background: #f5f5f5;
  margin: 0 0 20px;
  padding: 22px 30px;
}

.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.opc-block-summary>.title,
.checkout-agreements-items .checkout-agreements-item-title {
  padding-bottom: 10px;
  color: #333;
  font-weight: 300;
  font-size: 26px;
}

.opc-block-summary>.title {
  display: block;
}

.opc-block-summary .items-in-cart>.title {
  border-bottom: 1px solid #ccc;
  padding: 10px 40px 10px 0;
  cursor: pointer;
  display: block;
  text-decoration: none;
  margin-bottom: 0;
  position: relative;
}

.table-checkout-shipping-method thead th {
  display: none;
}





.checkout-payment-method .payment-method-content {
  display: none;
  padding: 0 0 20px 22px;
}

.checkout-payment-method .payment-method._active .payment-method-content {
  display: block;
}

.opc-block-summary .table-totals .table-caption {
  display: none;
}

.opc-block-summary .product-item .product-item-inner {
  display: table;
  margin: 0 0 10px;
  width: 100%;
}



.checkout-index-index {
  .ampromo-items-add {
    display: none;
  }

  form .field:not(.choice):not(.note):not(.qty) .label {
    position: static;
  }

  form .field:not(.choice):not(.note):not(.qty) {
    position: relative;
    background-color: transparent;
    color: #333333;
    border: 0;
    height: auto;
    line-height: 44px;
    border-radius: 22px;
    padding: 0;
    -webkit-appearance: none;
    font-weight: 300;
    transition: box-shadow 600ms;
    overflow: visible;
    margin-bottom: 1rem;
  }

  form .field:not(.choice):not(.note):not(.qty) input,
  form .field:not(.choice):not(.note):not(.qty) select {
    background: #f2f2f2;
    border-radius: 50px;
    padding-top: 0;
  }

  .field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 1px;
  }

  .field-tooltip .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none !important;
  }

  .field-tooltip .field-tooltip-action:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 21px;
    line-height: inherit;
    color: #bbb;
    @include icon('circle-question', 'solid');
  }

  .field-tooltip {
    .field-tooltip-content {
      background: #f4f4f4;
      border: 1px solid #999;
      border-radius: 1px;
      font-size: 14px;
      padding: 12px;
      width: 270px;
      display: none;
      left: 38px;
      position: absolute;
      text-transform: none;
      top: -9px;
      word-wrap: break-word;
      z-index: 2;
      line-height: 20px;
    }

    &._active {
      .field-tooltip-content {
        display: block;
      }
    }
  }

  .field-tooltip .field-tooltip-content:before,
  .field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    border-right-color: #f4f4f4;
    left: -21px;
    top: 12px;
    content: '';
    display: block;
    position: absolute;
    z-index: 3;
    line-height: 20px;
  }

  .field-tooltip .field-tooltip-content:before {
    border-right-color: #666;
  }

  .field-tooltip .field-tooltip-content:after {
    border-right-color: #f4f4f4;
    width: 1px;
    z-index: 4;
  }

  form .field:not(.choice):not(.note):not(.qty) .control {
    height: auto;
    line-height: 44px;
  }

  form .field[name="shippingAddress.street.0"],
  form .field[name="shippingAddress.street.1"] {
    .label {
      display: none !important;
    }
  }

  form .field:not(.choice):not(.note):not(.qty):focus {
    outline: none;
    box-shadow: none;
    background: #FFFFFF;
  }
}

////////////////
//Sign In Popup
///////////////
.authentication-wrapper {
  width: 33.33333333%;
  text-align: right;

  .action.action-auth-toggle {
    text-transform: none;
    letter-spacing: 0;
    font-weight: 400;
    padding: 0;
    text-decoration: underline;
    background: none;
  }
}



.block-authentication .block-title {
  font-size: 26px;
  border-bottom: 0;
  margin-bottom: 25px;
  font-weight: 300;
}

._has-auth-shown .authentication-wrapper {
  z-index: 900;
}

.block-authentication .actions-toolbar {
  display: block;

  >.primary {
    display: inline;
    float: right;
    margin-right: 0;
  }

  >.secondary {
    float: left;
    margin-right: 2rem;
    padding-top: 1rem;

    .action {
      padding: 0;
      color: $_color-black;
      background: none;
      text-transform: none;
      font-weight: 400;
    }
  }

}

.authentication-dropdown {
  background-color: #fff;
  border: 1px solid #aeaeae;
  -webkit-transform: scale(1, 0);
  -webkit-transform-origin: 0 0;
  -webkit-transition: -webkit-transform linear .1s, visibility 0s linear .1s;
  position: absolute;
  text-align: left;
  top: 100%;
  transform: scale(1, 0);
  transform-origin: 0 0;
  transition: transform linear .1s, visibility 0s linear .1s;
  visibility: hidden;
  width: 100%;
  box-sizing: border-box;

  .modal-inner-wrap {
    padding: 25px;
    overflow: hidden;
  }

  .action-close {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
    margin: 15px;
    @include media('<tablet') { 
      right: 40px;
    }

    &::before {
      @include icon('close', 'solid');
    }

    >span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}

.authentication-dropdown._show {
  z-index: 100;
  -webkit-transform: scale(1, 1);
  -webkit-transition: -webkit-transform linear .1s, visibility 0s linear 0s;
  transform: scale(1, 1);
  transition: transform linear .1s, visibility 0s linear 0s;
  visibility: visible;
}

////////////////
//Sign In Popup
///////////////


@include media('>=tablet') {

  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    max-width: 500px;
  }

  .opc-estimated-wrapper {
    display: none;
  }


  .opc-progress-bar {
    margin: 0 0 20px;
    counter-reset: i;
    display: block;
    font-size: 0;
  }

  .opc-progress-bar-item {
    margin: 0 0 10px;
    width: 185px;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
  }

  .opc-progress-bar-item:before {
    background: #e4e4e4;
    border: 1px solid #ccc;
    top: 19px;
    transition: background .3s;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px;
  }

  .opc-progress-bar-item._active:before {
    background: #ccad50;
    border-color: #ccad50;
  }

  .opc-progress-bar-item>span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: #666;
    font-weight: 300;
    font-size: 18px;
  }

  .opc-progress-bar-item._active>span {
    color: #333;
    font-weight: 600;
  }

  .opc-progress-bar-item>span:before,
  .opc-progress-bar-item>span:after {
    background: #e4e4e4;
    border: 1px solid #ccc;
    height: 38px;
    margin-left: -19px;
    transition: background .3s;
    width: 38px;
    border-radius: 50%;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
  }

  .opc-progress-bar-item._active>span:before {
    background: #ccad50;
    border-color: #ccad50;
  }

  .opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0;
  }

  .opc-wrapper {
    width: 66.66666667%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding-right: 30px;
    box-sizing: border-box;
    margin: 0 0 20px;
  }

  .opc-sidebar {
    margin: 46px 0 20px;
    width: 33.33333333%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  // .checkout-index-index {
  //   // .checkout-container {
  //   //   overflow: hidden;
  //   // }
  // }

  .opc-progress-bar-item._active>span:after {
    border-color: #ccad50;
    @include icon('truck', 'solid');
    line-height: 37px;
    font-size: 13px;
  }

  .opc-progress-bar-item>span:after {
    background: #fff;
    @include icon('card', 'solid');
    line-height: 37px;
    font-size: 13px;
  }

  .opc-wrapper .step-title {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    padding-bottom: 10px;
    color: #333;
    font-weight: 300;
    font-size: 26px;
  }

  .checkout-shipping-method .actions-toolbar>.primary {
    float: right;
  }

  .checkout-shipping-method .actions-toolbar {
    display: block;
  }

  .opc-block-summary .minicart-items-wrapper {
    margin: 0 -15px 0 0;
    max-height: 370px;
    padding: 15px 15px 0 0;
    border: 0;
    overflow-y: auto;
  }

  .opc-wrapper {
    .payment-group {
      .step-title {
        border-bottom: 0;
      }
    }
  }

  .checkout-payment-method .payment-option-title {
    border-top: 1px solid #ccc;
    padding: 20px 0;
  }
}

body {

  .discount-code,
  .giftcardaccount {
    padding: 0;

    .payment-option-title {
      border-top: 1px solid #ccc;
      padding: 20px 0;
      padding-left: 20px;
      margin-bottom: 0;

      .action {
        text-align: left;
        padding: 0;
        font-size: 14px;
        font-weight: 400;

        &::after {
          font-size: 16px;
          line-height: 16px;
          color: #757575;
          @include icon('angle-down', 'solid');
        }
      }
    }
  }
}


@include media('<=tablet') {
  .opc-estimated-wrapper {
    background: #f4f4f4;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin: -21px -15px 15px;
    padding: 18px 15px;
    overflow: hidden;

    .estimated-block {
      font-size: 18px;
      float: left;
      font-weight: 700;

      .estimated-label {
        display: block;
        margin: 0 0 5px;
      }
    }

    .minicart-wrapper {
      display: inline-block;
      position: relative;
      float: right;
      top: 11px;

      .action.showcart {
        display: flex;
        align-items: center;
        &:before {
          @include icon('cart', 'solid');
          font-size: 22px;
          line-height: 28px;
        }
      }

      .action.showcart {
        background: none;
        padding: 0;

        .counter.qty {
          background: #CCAD50;
          color: #fff;
          height: 24px;
          line-height: 24px;
          border-radius: 2px;
          display: inline-block;
          margin: 0;
          margin-left: 5px;
          min-width: 18px;
          overflow: hidden;
          padding: 0 3px;
          text-align: center;
          white-space: normal;
          width: 24px;
        }
      }
    }
  }

  .checkout-index-index {

    .payment-method .payment-method-title {
      padding: 20px;
    }

    .checkout-shipping-method {
      .table-checkout-shipping-method {
        border-collapse: collapse;
        width: 100%;
        text-align: center;
      }

      .actions-toolbar {
        display: block;

        >.primary .action {
          width: 100%;
        }
      }
    }

    .custom-slide {
      bottom: 0;
      left: 0;
      min-width: 0;
      position: fixed;
      right: 0;
      top: 0;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: visibility 0s .3s, opacity .3s ease;
      transition: visibility 0s .3s, opacity .3s ease;
      left: 44px;
      z-index: 900;
    }

    .custom-slide._show {
      visibility: visible;
      opacity: 1;
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease;
    }

    .custom-slide._show {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .block-authentication .actions-toolbar {
    display: flex;
    flex-direction: column;
  }

  .custom-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    width: auto;
  }

  .custom-slide .modal-inner-wrap {
    box-sizing: border-box;
    height: auto;
    min-height: 100%;
  }

  .custom-slide._show .modal-inner-wrap {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  body._has-modal-custom .modal-custom-overlay {
    background-color: rgba(51, 51, 51, 0.55);
  }

  .checkout-payment-method .payment-methods {
    margin: 0 -15px;
  }

  .checkout-payment-method .step-title {
    padding-left: 15px;
    padding-right: 15px;
  }

  .checkout-payment-method .payment-method-title {
    padding: 15px;
  }

}
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Related Articles
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--related-articles {
  @include text--related-article-title;

  &::before {
    @include gold-strip(1px);
    margin-bottom: 60px;

    @include media('>=tablet') {
      margin-bottom: 75px;
    }
  }

  [data-element='inner'] {
    padding: 0 !important;
  }

  .pagebuilder-column-group {
    flex-direction: column !important;

    @include media('>=tablet') {
      flex-direction: row !important;
      justify-content: space-between;
    }
  }

  .pagebuilder-column {
    width: 100% !important;
    flex-direction: row !important;
    margin-bottom: 40px !important;
    padding: 0 !important;

    [data-content-type='image'] {
      flex-shrink: 0;
      width: 120px;
    }

    > div {
      padding: 0 15px !important;
    }

    p {
      margin-bottom: 12px;
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    a {
      @include button--more-cta-link-with-arrow;
    }
  }
}

/**
 * Base styling for icon elements
 */

$_icons: (
  'account': '\f007',
  'account-circle': '\f2bd',
  'accordion': '\f078',
  'arrow-left': '\f060',
  'arrow-right': '\f061',
  'back-arrow': '\f177',
  'calendar': '\f073',
  'cart': '\f07a',
  'card': '\f09d',
  'circle-question': '\f059',
  'close': '\f00d',
  'close-circle': '\f057',
  'crosshairs': '\f05b',
  'diamond': '\f219',
  'down-arrow': '\f078',
  'download': '\f354',
  'exclamation-circle': '\f06a',
  'filters': '\f3f2',
  'left-arrow': '\f053',
  'long-up-arrow': '\f176',
  'long-down-arrow': '\f175',
  'map-pin': '\f3c5',
  'menu': '\f0c9',
  'minus': '\f068',
  'plus': '\f067',
  'right-arrow-circle': '\f138',
  'right-arrow': '\f054',
  'search': '\f002',
  'signout': '\f08b',
  'times': '\f00d',
  'truck': '\f0d1',
  'up-arrow': '\f077',
  'angle-up': '\f106',
  'angle-down': '\f107',
  'check-circle': '\f058',
  'check': '\f00c',
  'tick': '\f00c',
  'phone': '\f095',
  // brands
  'facebook': '\f39e',
  'instagram': '\f16d',
  'twitter': '\f099',
);

$_icon-weights: (
  regular: 'Font Awesome Regular',
  light: 'Font Awesome Light',
  solid: 'Font Awesome Solid',
  brands: 'Font Awesome Brands',
);

@mixin icon($_icon, $_icon-style: 'regular') {
  content: map-get($_icons, $_icon);
  font-family: map-get($_icon-weights, $_icon-style);
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
}

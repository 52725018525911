@import '../tools/mixins';
@import '../tools/mixins-typography';
@import '../tools/mixins-button';
@import '../elements/icons';
@import '../settings/colours';

.blog-page-list {
  $filter-border: $color--ui--border-light;

  .block-categories {
    position: relative;
    background: $color--neutral--red-cream;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    margin-bottom: 40px;
  }

  .category-filter-container {
    @include site-wrapper;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .category-filter {
    position: relative;
    margin: 30px 0;
    width: 300px;
  }

  .category-filter__filter {
    @include text--label-heading;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: $filter-border;
    cursor: pointer;

    &::after {
      @include icon('down-arrow');
    }
  }

  .category-filter__dropdown {
    position: absolute;
    top: 70px; // height of category-filter__filter
    display: none;
    border: $filter-border;
    background: $color--ui--accordion-bg-active;
    z-index: 1;
    width: 100%;
    padding-bottom: 20px;

    &.open {
      display: block;
    }
  }

  .category-filter__dropdown-ul {
    @include reset-ul;

    .category-label {
      @include text--label-heading;
      padding: 10px 20px;
    }

    .category {
      margin: 5px 20px;
    }

    .category__link {
      @include button--amasty-filter-item;
      padding: 10px 30px;
    }
  }

  .page-title {
    @include text--heading-3;

    @include media('>=tablet') {
      @include text--heading-2;
    }

    @include media('>=desktop') {
      @include text--heading-1;
    }
  }

  .post-list-wrapper {
    margin-top: 40px;
  }

  .post-list {
    @include reset-ul;

    @include media('<tablet') {
      @include flex-grid(1, 20px, 40px);
      justify-content: center;
    }

    @include media('>=tablet', '<desktop') {
      @include flex-grid(2, 20px, 40px);
    }

    @include media('>=desktop') {
      @include flex-grid(3, 20px, 40px);
    }
  }

  .post-holder {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    max-width: 475px;

    .featured-image-container {
      position: relative;
      padding-bottom: 100%;
      overflow: hidden;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .post-details {
    padding: 20px 0;
    width: 100%;
    flex: 1;
  }

  .post-header {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .post-title-holder {
    margin-bottom: 5px;
    flex: 1;
  }

  .post-title {
    @include text--blog-listing-title;
  }

  .post-info {
    @include text--blog-listing-info;

    .label {
      display: none;
    }
  }

  .posted-date-container {
    .post-categories {
      display: none;
    }
  }

  .category-tag-container {
    .post-posed-date {
      display: none;
    }
  }

  .mfblog-autoloader {
    text-align: center;
  }

  .block-wishlist,
  .block-reorder {
    display: none;
  }
}

@import '../components/pagebuilder';
@import '../settings/colours';
@import '../tools/mixins-typography';
/**
 * Contextual styling for the PLP and similar pages
 */

.catalog-category-view, // PLP
.catalogsearch-result-index, // Search results page
.wishlist-index-index, // wishlist page
.cms-noroute-index { // 404 page
  overflow: initial;

  .page-title {
    display: none;
  }

  .page-main {
    /*
      Magento adds the class page-main to the category image on the PLP
      which results in multiple page-main classes on this page.
      This file resets the site-wrapper on .page-main and applies it to #maincontent
     */
    max-width: none;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
  }

  #maincontent {
    @include site-wrapper;
    @include emblem-background;
    position: relative;
  }

  .category-view {
    position: relative;
    overflow: hidden;

    .page-main {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .category-image {
      height: 100%;

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 33%;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color--ui--overlay-image;
      }
    }

    .category-description {
      display: block;
      padding: 86px 0 24px !important;
      max-width: 942px;
      margin: auto;
      min-height: unset;
      text-align: center;

      h1,
      h2 {
        font-size: 32px;
        line-height: 1;

        @include media('>=desktop') {
          font-size: 48px;
          line-height: 1.4;
        }
      }

      .category-description__show-more {
        @include button--padded-link;
        text-decoration: underline;
        color: $color--ui--link-text-highlight;
      }

      [data-element="inner"] {
        padding: 0 10px !important; // overrides pagebuilder styling
        margin: 0 !important; // overrides pagebuilder styling
      }

      [data-content-type="row"] {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height 200ms ease-in-out;
        display: block !important; // overrides pagebuilder styling
        padding: 0 !important; // overrides pagebuilder styling
        margin: 0 0 10px !important; // overrides pagebuilder styling
      }

      &.compact {
        [data-content-type="row"] {
          max-height: 130px; // Must be kept in sync with the config values sent to hcShowMore

          @include media('>=desktop') {
            max-height: 145px; // Must be kept in sync with the config values sent to hcShowMore
          }
        }
      }
    }
  }

  .hero-product {
    margin-top: 45px;

    @include media('>=desktop') {
      margin-top: 85px;
    }
  }

  .columns {
    display: flex;
    flex-direction: column;
    margin: 0;

    @include media('>=desktop') {
      margin: 1rem 0 0;
    }
  }

  .column.main { // PLP items
    width: 100%;
  }

  #amasty-shopby-product-list { // No class supplied by Amasty
    padding-top: 1rem;
    position: relative;
  }

  .product-items {
    @include product-grid;
  }

  .toolbar-amount {
    display: none;

    @include media('>=desktop') {
      display: block;
      position: absolute;
      top: -50px;
      left: 1rem;
    }
  }

  .toolbar-sorter {
    position: absolute;
    top: -40px;
    right: 0;
    display: flex;

    @include media('>=desktop') {
      right: 1rem;
    }

    .sorter-label {
      appearance: none;
      -webkit-appearance: none;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      text-transform: uppercase;
      padding-right: 5px;
    }

    .select-wrapper::after {
      right: 8px;
    }

    .sorter-options {
      font-size: 14px;
      border-radius: 0;
      padding: 10px 20px 10px 6px;
      background: transparent;
      border: 1px solid $color--ui--border-color-light;
      appearance: none;
      color: $color--ui--text-body;
    }

    .sorter-action {
      span {
        @include visually-hidden;
      }

      &.sort-asc::before {
        @include icon('long-up-arrow', 'solid');
      }

      &.sort-desc::before {
        @include icon('long-down-arrow', 'solid');
      }
    }
  }

  .toolbar-products {
    border-top: $color--ui--border-light;
    padding: 32px 0 30px;
  }

  .pages {
    @include pagination;
  }

  &.page-layout-2columns-left {
    @include media('>=desktop') {
      .column.main {
        display: flex;
      }

      #amasty-shopby-product-list, // Styling the id because the HTML is generate by the Amasty module
      .search.results {
        padding-top: 60px;
        flex-grow: 1;
      }

      .toolbar-amount {
        margin: 0;
        top: 6px;
        left: 0;
      }

      .toolbar-sorter {
        top: 0;
        right: 0;
      }
    }
  }
}

.catalog-category-view {
  #category-scroll-top {
    position: fixed;
    bottom: 16px;
    right: 120px;
    background: $_color-white;
    width: 64px;
    height: 64px;
    visibility: hidden;
    opacity: 0;
    outline: 1px solid $_color-mine-shaft;
    outline-offset: -5px;
    border-radius: 50%;
    text-align: center;
    padding: 17px 24px;
    z-index: 12;
    box-shadow: 3px 3px 8px gray;

    &:before {
      @include icon('up-arrow', 'solid');
    }

    &:hover {
      background: $_color-mine-shaft;
      color: $_color-white;
      outline-color: $_color-white;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}

[data-content-type="html"][data-appearance="default"],
[data-content-type="text"][data-appearance="default"] {
   a {
    text-decoration: underline !important;
   }
}

.catalogsearch-result-index {
  .page-title-wrapper {
    $headerHeight: 242px;
    display: flex;
    margin-top: $headerHeight;
    background: $color--neutral--concrete url('../images/bg-search-results-bars.png') no-repeat center center;
    background-size: cover;
    min-height: 240px;
  }

  .page-title {
    @include site-wrapper;
    padding: 30px;
    display: block;
    text-align: center;

    span {
      display: block;
    }

    .page-title__heading {
      @include text--search-title-heading;
      @include gold-text-gradient;
      margin-bottom: 8px;
    }

    .page-title__keywords {
      @include text--search-title-keywords;
      color: $color--neutral--alabaster;
      word-break: break-word;
    }
  }
}

// Empty search results and typo tolerance styling
.catalogsearch-result-index {
  // hide incorrectly added block into results
  .products-grid > .block {
    display: none;
  }

  .page-main .message.notice {
    @include text--heading-3;
    margin: 70px 0 30px;
    text-align: center;

    .block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .title {
        @include text--large;
        width: 100%;
        margin-bottom: 15px;
      }

      .item {
        margin: 5px;

        a {
          @include button-style--ghost;
        }
      }
    }
  }
}


@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins';

.checkout-index-index {
    .checkout-container {
        min-height: 100vh;
    }
    
    .header.content {
        padding: 20px;  

        .logo {
          position: unset;
          transform: unset;
        }

        @include media('<tablet') {
            align-items: flex-start;
            padding-bottom: 0;
        }
    }

    .authentication-wrapper {
        margin-top: -108px;

        @include media('<tablet') {
            margin-top: -40px;
        }
    }
}

.authentication-dropdown._show {
    .block-customer-login {
        .block-title {
            font-family: $font-family-serif;
            font-size: 21px;
            line-height: 60px;
            margin-bottom: 0;
            letter-spacing: 0;

            strong {
                font-weight: 400;
            }
        }
    
        .block-content .field {
            .label {
                font-size: 14px;
                line-height: 20px;
                color: $_color-mine-shaft;
            }
            
    
            .control input {
                border: 1px solid $_color-grey-text;
                background: $_color-white;
                border-radius: 6px;
                padding: 0 15px;
            }

            .input-text.mage-error {
                border-top: 1px solid #ff8f14;
                border-left: 1px solid #ff8f14;
                border-right: 1px solid #ff8f14;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                ~ .note {
                    display: none !important;
                }
            }
        }

        .block-content {
            .field.field-email {
                margin-bottom: 0 !important;
                box-shadow: none !important;

                ~  #login-email-error {
                    display: none !important;
                }
    
                &:has(.input-text.mage-error)  ~ #login-email-error{
                    display: block !important;
                    margin-top: 0;
                    background: #ff8f14;
                    padding-top: 8px !important;
    
                    ~#login-email-error {
                        display: none !important;
                    }
                }
            }

            .field.field-password {
                margin-bottom: 0 !important;
                box-shadow: none !important;

                ~ #login-password-error{
                    display: none !important;
                }
    
                &:has(.input-text.mage-error)  ~#login-password-error{
                    display: block !important;
                    margin-top: 0;
                    background: #ff8f14;
                    padding-top: 8px !important;
    
                    ~#login-password-error {
                        display: none !important;
                    }
                }
            }
        }
    
        .secondary {
            .action {
                font-size: 12px;
                line-height: 60px;
                letter-spacing: 0;
            }
        }
    }
}

.checkout-header-wrapper {
    background: $_color-mine-shaft;
    color: #ffffff;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.38px;
    margin-bottom: 44px;

    @include media('<tablet') {
        margin-bottom: 0;
    }

    .checkout-header-row {
        max-width: 1360px;
        margin: 0 auto;
        padding: 0 20px;

        p {
            margin: 12px 0;
            color: #ffffff;

            a {
                color: #ffffff;
            }
        }

        .pagebuilder-column {
            justify-content: center !important;
        }

        .back-button {
            flex-direction: row !important;
            align-items: center;
            justify-content: flex-start !important;
            
            a:before {
                content: '<';
                padding-right: 5px;
                font-size: 14px;
            }
        }
    }

    .header-text {
        p {
        text-align: center;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

@include media('<tablet') {
    .checkout-header-wrapper {
        font-size: 12px;
        line-height: 16px;

        .checkout-header-row {
            padding: 0 8px;

            p {
                margin: 5px 0;
            }
        }

        .pagebuilder-column.back-button {
            align-items: unset !important;
            justify-content: unset !important;

            [data-content-type="text"] {
                border-right: 1px solid white;
            }

            p {
                line-height: 26px;
                
                a {
                    font-size: 0;
                }
            }
        }
    }
}

.opc-progress-bar {
    margin: 53px 0 0;

    .opc-progress-bar-item {
        width: unset;
        margin: 0;
        line-height: 20px;

        &:before {
            display: none;
        }

        span {
            font-size: 14px;
            line-height: 20px;
            color: #B9B9B9;
            font-weight: 400;
            letter-spacing: 0;
            padding: 0;

            &:before {
                display: none;
            }

            &:after {
                content: '>';
                color: #B9B9B9;
                border: 0;
                position: relative;
                left: unset;
                width: unset;
                height: unset;
                margin: 0;
                padding: 0 12px;
                line-height: 20px;
            }
        }

        &._active {
            span {
                color: $_color-gold-blend-to;
            }
        }
    }

    .complete {
        span:after {
            content: '';
        }
    }
}

@include media('<tablet') {
    .opc-progress-bar {
        display: block;
        text-align: center;
        margin: 20px 0 6px;
        margin-top: 0;

        .opc-progress-bar-item {
            display: inline;
        }
    }

    .opc-estimated-wrapper {
        margin: 0 -15px 15px;
    }
}
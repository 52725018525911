.amshopby-slider-container {
  --slider-height: 10px;
  margin: 25px 5px 5px;

  .filter__menu & {
    margin: 10px 20px 5px;
  }

  .ui-slider-horizontal {
    position: relative;
    margin-bottom: 8px;
  }

  .ui-slider-horizontal {
    height: var(--slider-height);
  }

  .ui-slider-range {
    position: absolute;
    height: var(--slider-height);
    background: $color--ui--filter-highlight;
  }

  .amshopby-slider-tooltip {
    border: 1px solid $color--ui--bg-default;
    border-radius: 2px;
    bottom: calc(100% + 7px);
    box-sizing: border-box;
    display: flex;
    font-size: 16px;
    justify-content: center;
    letter-spacing: 1px;
    line-height: 1;
    padding: 3px;
    position: absolute;
    transition: none;
    z-index: 1;
  }

  .ui-slider-handle {
    $size: 16px;
    height: $size;
    width: $size;
    top: -3px;

    .amshopby-slider-corner {
      position: absolute;
      top: -12px;
      width: 8px;
      z-index: 2;

      &::before,
      &::after {
        $size: 6px;
        border: 1px solid;
        border-color: inherit;
        box-sizing: content-box;
        clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
        content: '';
        display: block;
        height: $size;
        width: $size;
        position: absolute;
        transform: rotate(45deg);
      }

      &::after {
        background: inherit;
      }
    }

    &:not(.ui-state-hover):not(.ui-state-active) {
        .amshopby-slider-corner,
        .amshopby-slider-tooltip {
            opacity: 0;
            visibility: hidden;
        }
    }
  }

  .amshopby-slider-tooltip {
    color: $color--ui--bg-default;
  }

  &.-improved {
    --slider-height: 4px;

    .ui-slider-handle {
      top: -6px;
    }
  }

  &.-light {
    --slider-height: 2px;

    .ui-slider-handle {
      top: -6px;
      background: $color--ui--bg-default;
      border: $color--ui--border-alpha !important; // Overrides styles from Amasty JS
    }

    .amshopby-slider-tooltip {
      border-color: $color--ui--border-color-alpha;
    }

    .amshopby-slider-corner {
      background: $color--ui--bg-default;
      border-color: $color--ui--border-color-alpha;
    }
  }
}

@import '../tools/mixins-typography';
@import '../tools/mixins-button';

.checkout-app__payment-section {
  .simplify-payment-form {
    position: relative;
  }

  $_iframe-height: 475px;
  $_iframe-top-bottom-padding: 26px;
  $_iframe-left-right-padding: 30px;
  // Iframe has internal 20px left/right padding
  $_iframe-internal-padding: 20px;

  .simplify-payment-form__iframeheader {
    @include prevent-margin-collapse;
    @include text--checkout-base-text;
    border: 2px solid $color--brand--primary;
    background-color: $color--ui--bg-default;
    border-bottom: 0;
    border-radius: $border-radius $border-radius 0 0;
    padding: $_iframe-top-bottom-padding $_iframe-left-right-padding 0;
  }

  .simplify-payment-form__iframeheader-info {
    margin: 0; // enough padding inside iframe.
  }

  .simplify-payment-form__iframewrap {
    width: 100%;
    height: $_iframe-height;
    padding: 0 ($_iframe-left-right-padding - $_iframe-internal-padding) $_iframe-top-bottom-padding;
    overflow: hidden;
    background-color: $color--ui--bg-default;
    border: 2px solid $color--brand--primary;
    border-top: 0;
    border-radius: 0 0 $border-radius $border-radius;
  }


  .simplify-payment-form__iframe {
    width: 100%;
    height: $_iframe-height - $_iframe-top-bottom-padding;
    border: 0;
    background: $color--neutral--white;
  }

  .checkout-app-payment__message {
    @include text--notices($color: $color--ui--info);
    padding: 14px 40px;
    margin-top: 20px;
    background-color: $color--ui--info-bg;
    text-align: center;
    border-radius: $border-radius-large;
  }

  .payments-section__selected-info {
    @include text--checkout-payment-text;
    margin: 0;
  }

  .payments-section__selected-info--free {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;

    .icon-tick {
      display: block;
      color: $color--neutral--white;
      background: $color--brand--primary-blend;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      margin-right: 12px;
      flex-shrink: 0;

      &::after {
        @include icon('check', 'solid');
      }
    }

    .free-text {
      flex-grow: 1;
      padding-top: 2px;
    }
  }

  .payment-section__learn-about-paypal-button {
    @include button--padded-link;
    display: block;
    margin: 12px auto 40px;
  }

  .react-checkout-final__button {
    width: 100%;
  }

  .pinpay-form__field {
    position: relative;

    .input-label {
      @include h-input-label;
      @include h-input-label-active;
    }
  }

  .pinpay-form__field-row {
    display: flex;
    justify-content: space-between;
  }

  .pinpay-form .pinpay-form__field--expiry,
  .pinpay-form .pinpay-form__field--cvc {
    width: calc(50% - 10px);
  }
}

/**
 * Styling for the totals table component (typically displayed on the cart pge)
 */

.table-totals,
.table.totals {
  @import 'aligent-scss-placeholder/components/table-totals';

  .table-caption {

  }

  .totals { // <tr> - the parent <table> also has class `totals`, but as we're already in the table at this point, so no conflict
    &:nth-last-child(2) { // Second to last option, which allows for padding to be placed before the grand totals border - if there is one
      th,
      td {

      }
    }
  }

  .mark { // <th>

  }

  .amount {

  }

  .grand {
    th,
    td {

    }
  }
}

@import '../elements/icons';
@import '../tools/mixins-typography';

.whats-inside-modal {
  .modal {
    @include media('>=tablet') {
      display: flex;
    }
  }

  .modal-inner-wrap {
    max-width: 700px;
  }

  .modal__image-container {
    display: flex;
    justify-content: center;
  }

  .modal__image {
    object-fit: contain;
    width: 220px;
    height: 220px;
  }

  .modal__description-container {
    flex: 1;

    @include media('>=tablet') {
      padding-left: 20px;
    }

    p { // no class on pagebuilder element
      margin-top: 0;
    }
  }

  .modal__name {
    @include text--heading-4;
      margin: 20px 0;

    @include media('>=tablet') {
      margin: 0 0 20px;
    }
  }

  .modal__link {
    @include text--list-heading-link;
    margin-top: 20px;
    display: block;
  }

  .modal-header {
    position: static; // Allows the .action-close element to be positioned relative to modal container
  }

  .action-close {
    position: absolute;
    top: 18px;
    right: 20px;

    &::before {
      // Icon must be placed on the before element for it to work in firefox mobile
      @include icon('close', 'light');
      font-size: 22px;
    }
  }
}

.checkout-app__billing-form {
  margin-bottom: 30px;

  .stored-addresses {
    margin: 20px 0;
  }

  .stored-addresses__new-address {
    margin-top: 12px;
    text-align: center;
  }

  .stored-addresses__new-address-button {
    @include button--padded-link;
  }

  .stored-address__edit {
    @include button--padded-link;
  }

  .new-address__actions {
    display: flex;
  }

  .new-address__action {
    @include button--checkout-primary;
    flex-grow: 1;
    margin-right: 15px;
  }

  .new-address__cancel {
    @include button--checkout-ghost;
    flex-grow: 1;
  }
}

@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
@import '../tools/z-index';
/**
 * Global messages contextual styling
 */

.page.messages {
  $_padding-y: 14px;

  @include text--page-messages;
  @include zIndex('notices');
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: $border-radius-large $border-radius-large 0 0;
  overflow: hidden;
  z-index: 999999; // To appear on top of help widget

  .message {
    position: relative;
    height: 0; // revealed in hc-page-messages.js

    &:hover {
      cursor: pointer;
    }

    .message__text {
      padding: $_padding-y 30px;

      @include media('>=md') {
        padding: $_padding-y 40px;
      }

      @include media('>=lg') {
        padding: $_padding-y 50px;
      }
    }

    &::after {
      @include button-style--icon;
      @include icon('close', 'light');
      font-size: 22px;
      position: absolute;
      top: 15px;
      right: 15px;

      @include media('>=lg') {
        right: 25px;
      }
    }

    a,
    &::after {
      color: inherit;
    }

    &.error {
      background: $color--ui--error-bg;
      color: $color--ui--error;
    }

    &.success {
      background: $color--ui--success-bg;
      color: $color--ui--success;

      // void shopping cart link
      a {
        pointer-events: none;
      }
    }

    &.notice {
      background: $color--ui--notice-bg;
      color: $color--ui--notice;
    }

    &.warning {
      background: $color--ui--warning-bg;
      color: $color--ui--warning;
    }
  }
}

@import '../tools/mixins-button';
@import '../tools/icons';

.faq-index-index,
.faq-search-index,
.faq-category-index,
.faq-article-index {
  .page-title {
    margin-top: 50px;
    text-align: center;
  }

  .aw-no-display {
    display: none;
  }

  .aw_bold {
    font-weight: 700;
  }

  .aw-container {
    padding: 0;

    @include media('>=tablet') {
      @include flex-grid(2, 20px);
    }

    &.columns-to-center {
      justify-content: center;
    }
  }

  .main h1 {
    display: none;
  }

  .column.main {
    margin-bottom: 50px;
  }

  .search-form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px auto 50px;

    @include media('>=tablet') {
      margin-top: 60px;
    }
  }

  .aw-faq-search .search-form .field.aw-search-field { // need specificity to override form.scss
    flex: 1;
    height: 50px;
    margin-bottom: 0;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .action.primary {
    @include button-style--primary;
    margin-left: 10px;

    // easier to set <lg as only changes then
    // rather than double up resetting hidden
    @include media('<lg') {
      width: 50px;
      padding-left: 0;
      padding-right: 0;

      &::before {
        @include icon('search', 'solid');
      }

      .search-text {
        display: none;
      }
    }
  }

  .aw-page-container {
    max-width: 920px;
    margin: auto;
  }

  #aw-faq__helpfulness { // No class added by aheadworks module
    max-width: 920px;
    margin: auto;
  }

  #aw__helpfulness-voting { // No class added by aheadworks module
    margin: 0 20px;

    .like {
      &::after {
        content: '|';
        display: inline-block;
        margin: 0 10px;
      }
    }
  }

  .aw-list-style {
    padding-left: 22px;

    li {
      margin-bottom: 10px;
    }
  }

  .results-list {
    margin-top: 50px;
    width: 100%;
  }
}

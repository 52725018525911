/**
 * Dropdown switcher styling (e.g. currency, stores)
 */

.switcher {
  position: relative;

  .dropdown {
    .switcher-dropdown {
      display: none;
    }

    &.active {
      .switcher-dropdown {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

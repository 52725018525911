@import '../elements/icons';
@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins-button';

.checkout-app-shopping-bag .items {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
  margin-bottom: 25px;

  .hidden-button-container {
    height: 0;
    overflow: hidden;
  }
}

.checkout-app-shopping-bag-item-summary {
  display: flex;
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
}

.checkout-app-shopping-bag-item-summary__product-image {
  display: block;
  position: relative;
  width: 80px;
  padding-right: 15px;
  flex-shrink: 0;

  @include media('>md') {
    width: 140px;
  }

  @include media('>=desktop', '<1250px') {
    width: 80px;
  }

  a {
    display: block;
    background-color: $color--ui--bg-checkout;
  }

  img {
    display: block;
    text-align: center;
    height: auto;
    max-width: 100%;
    mix-blend-mode: darken; // blend image with checkout white
  }
}

.checkout-app-shopping-bag-item-summary__actions {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
}

.checkout-app-shopping-bag-item-summary__product-details {
  flex-grow: 1;
  min-width: 175px;
}

$remove-button-offset: 30px;

.checkout-app-shopping-bag-item-summary__promo {
  @include text--checkout-title-text;
  width: calc(100% - #{$remove-button-offset});
}

.checkout-app-shopping-bag-item-summary__title {
  @include text--checkout-base-text;
  width: calc(100% - #{$remove-button-offset});
}

.checkout-app-shopping-bag-item-summary__headline {
  @include text--checkout-headline-text;
  width: calc(100% - #{$remove-button-offset});
}

.checkout-app-shopping-bag-item-summary__attributes {
  @include text--checkout-configuration-text;
  text-transform: uppercase;
  width: calc(100% - #{$remove-button-offset});
}

.checkout-app-shopping-bag-item-summary__subtitle {
  @include text--checkout-configuration-text;
  text-transform: uppercase;
  width: calc(100% - #{$remove-button-offset});
}

.checkout-app-shopping-bag-item-summary__price {
  @include text--checkout-item-price;
  margin-top: 8px;
  margin-bottom: 13px;
  display: flex;

  .item-price {
    margin-right: 10px;
  }

  .item-price__onSale {
    text-decoration: line-through;
  }

  .free-gift-qty {
    @include text--checkout-free-gift-quantity;
    margin-left: 30px;
  }
}

.checkout-app-shopping-bag-item-summary__configurables {
  display: flex;
  flex-wrap: wrap;
}

.checkout-app-shopping-bag-item-summary__option {
  .select-component,
  .input-component {
    margin: 0;
    width: 100%;
  }

  .select-label {
    text-transform: capitalize;
  }

  .select-input {
    min-width: 80px;
  }
}

.checkout-app-shopping-bag-item-summary__option {
  width: 120px;
  margin: 0 10px 10px 0;

  .select-container,
  .input-container {
    margin: 0;
  }
}

.checkout-app-shopping-bag-item-summary__options--qty {
  order: 2;
  width: 80px;
}


.checkout-app-shopping-bag-item-summary__option-label {
  display: none;
}

.checkout-app-shopping-bag-item-summary__action--remove-item {
  padding: 0 10px 10px;
  color: $color--neutral--grey;
  background: transparent;

  &:hover {
    background: transparent;
  }

  &::after {
    @include icon('close', 'light');
  }
}

.checkout-app-shopping-bag-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  background: $color--neutral--white;
  border-top: 1px solid $color--ui--border-color-light;
  z-index: 1;

  @include media('>=desktop') {
    position: static;
    padding: 0;
    background: none;

    .checkout-app-delivery-section & {
      display: none;
    }
  }

  .move-section-text {
    font-weight: 300;
  }
}

.checkout-app-shopping-bag-scroll-container {
  @include media('>=desktop') {
    // Setting max height limits the size of the items container so that the totals sit on the bottom of the view on load
    // totals-height = 50px, header-height = 159px, checkout-tabs-height = 90px
    max-height: calc(100vh - 50px - 159px - 90px);
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 14px;
      background: $color--ui--bg-checkout;
    }

    &::-webkit-scrollbar-thumb {
      background: $color--neutral--mercury;
      border-radius: 7px;
      border: 4px solid $color--ui--bg-checkout;
    }

    &::-webkit-scrollbar-track {
      background: $color--ui--bg-checkout;
    }
  }
}

.items-summary__continue-button {
  @include button-style--outline;
  width: 100%;

  @include media('>=desktop') {
    display: none;
  }
}

.delivery-submit__button {
  @include button--checkout-primary;
  font-size: 16px;
  letter-spacing: 0.8px;
  width: 100%;

  &.delivery-submit__button--finished {
    @include media('>=desktop') {
      display: none;
    }
  }
}

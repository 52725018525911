.dispatch-date__container {
  position: relative;
  margin: 46px 0 33px;

  .checkout-app__subtitle {
    margin-bottom: 15px;
  }
}

.date-component {
  display: flex;
  margin-bottom: 12px;
}

.date-container {
  @include input-style(
    $focus-class: '.date-container--focused',
    $error-class: '.date-container--validation-error'
  );
  flex-grow: 1;

  .date-label {
    @include h-input-label;
  }

  &.date-container--focused,
  &:not(.date-container--empty) {
    .date-label {
      @include h-input-label-active;
    }
  }

  .date-control {
    @include h-input-wrap;
    position: relative;
  }

  .form-input {
    @include h-input-reset;
  }
}

.dispatch-date__icon {
  @include button-style--icon;
  width: $min-button-size;

  &::after {
    @include icon('calendar');
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color--neutral--grey-alpha;
    font-size: 26px;
  }
}

.dispatch-date__choices {
  display: flex;
}

.dispatch-date__field-asap-container {
  margin-right: 23px;
  margin-bottom: 17px;
}

.dispatch-date__field-choose-date-container {
  margin-bottom: 17px;
}

.dispatch-date__remove-button {
  @include button-style--clear;
  position: absolute;
  top: 0;
  right: 0;
  padding: $_input-offset / 2 $_input-offset;
  height: $_input-height;
  border-radius: 0 $_input-offset $_input-offset 0;
  background-color: transparent;
  line-height: 1;
  z-index: 1;

  &::after {
    @include icon('close', 'light');
  }
}

.dispatch-date__message {
  @include text--checkout-base-text;
}

.dispatch-date__message-info {
    color: $color--neutral--mine-shaft;
}

.dispatch-date__message-text {
   color: $color--neutral--grey;
}

.dispatch-date__message-text--error {
   color: $color--ui--invalid;
}

@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Link Cards
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--link-cards {
  $_spacing: 10px;

  padding: 0 0 30px !important;

  @include media('>=desktop') {
    padding: 30px 0 140px;
  }

  [data-content-type='column-group'] {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: transparent url('../images/emblem-740-890.png');
      width: 740px;
      height: 890px;
      right: 270px;
      top: -300px;
      transform: translateX(100%);
      opacity: 0.05;
      z-index: -1;
    }

    @include media('<tablet') {
      @include flex-grid(1, $_spacing, $_spacing, !important);
    }

    @include media('>=tablet', '<desktop') {
      @include flex-grid(2, $_spacing, $_spacing, !important);
    }

    @include media('>=desktop') {
      @include flex-grid(4, $_spacing, $_spacing, !important);
    }
  }

  // Remove the Haighs crest from adjacent siblings so the crests don't overlap
  & + .pb-block--link-cards {
    [data-content-type='column-group']::before {
      background: none;
    }
  }

  [data-content-type='column'] {
    position: relative;
    background: $color--neutral--white;
    flex-direction: row !important;
    padding: 15px !important;
    min-height: 150px;
    align-items: center;
    box-shadow: $color--ui--shadow-soft-box;

    @include media('<tablet') {
      justify-content: space-evenly !important;
    }

    // margin used as animation to reveal gold gradient bg as border
    &::before {
      @include pos-abs-fill;
      @include transition('opacity');
      content: '';
      background: $color--brand--primary-blend;
      margin: -1px;
      opacity: 0;
      z-index: -1;
    }

    &:hover,
    &:focus-within {
      &::before {
        opacity: 1;
      }

      a {
        outline: none;
      }
    }
  }

  [data-content-type='heading'] {
    margin: 60px 0;
    padding: 0 20px;
    text-align: center !important;
  }

  [data-content-type='text'] {
    @include text--curated-collections;
  }

  [data-content-type='image'] {
    display: flex;
    justify-content: flex-end;
    max-width: 130px;
    height: 100px;

    img {
      max-width: none !important;
    }
  }

  // expand link to trigger on entire component
  [data-content-type='buttons'],
  [data-content-type='button-item'],
  [data-element='link'] {
    @include pos-abs-fill;
  }
}

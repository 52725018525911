@import '../elements/icons';
@import '../settings/colours';
@import '../tools/mixins-typography';

$input-offset: 24px;
$input-offset-top: 7px;
$input-height: 44px;

$_input-height: $input-height;
$_input-offset: $input-offset;
$_input-offset-top: $input-offset-top;
$_border-radius: $border-radius-large;
$_error-transition-time: 600ms;
$wrapper-error-class: '.has-error';

@mixin input-style(
  $input-height: $_input-height,
  $focus-class: ':focus',
  $error-class: ':invalid',
  $color: $color--ui--input-text,
  $background: $color--ui--input-bg,
  $border-radius: $_border-radius
) {
  position: relative;
  background-color: $background;
  color: $color;
  border: 0;
  height: $input-height;
  line-height: $input-height;
  border-radius: $border-radius;
  padding: 0;
  -webkit-appearance: none;
  font-weight: 300;
  transition: box-shadow $_error-transition-time;
  overflow: hidden;

  &#{$focus-class} {
    outline: none;
    box-shadow: $color--ui--input-border-focus; // box-shadow to extrude border
    background: $color--ui--input-bg-focus;
  }

  // must be seperate class so IE11 doesn't break
  &:focus-within {
    outline: none;
    box-shadow: $color--ui--input-border-focus; // box-shadow to extrude border
    background: $color--ui--input-bg-focus;
  }

  &#{$error-class} {
    box-shadow: $color--ui--input-border-error;
    color: $color--ui--error-bg;
  }

  &#{$wrapper-error-class} {
    box-shadow: $color--ui--input-border-error;
    color: $color--ui--error-bg;
  }

  &::placeholder {
    color: $color--ui--input-text-placeholder;
    font-weight: 300; // no typography set as this allows inherited typography properties
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
}

@mixin select-style() {
  position: relative;

  &::after {
    @include icon('angle-down', 'light');
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 0;
    font-size: 20px;
    pointer-events: none;
  }
}

/////////////////////////////////
//   CUSTOM HAIGHS FORM ELEMENTS
/////////////////////////////////

@mixin h-input-label(
  $input-height: $_input-height,
  $input-offset: $_input-offset,
  $line-height: $base-line-height,
  $color: $color--ui--input-text-placeholder,
) {
  @include text--input-label(
    $color: $color
  );
  position: absolute;
  top: $input-height / 2 - 14px;
  left: $input-offset;
  line-height: $line-height;
  transition: all 0.2s ease-in-out;
  transform-origin: top left;
  z-index: 1; // lift over autofill container
  user-select: none;
  pointer-events: none;
}

@mixin h-input-wrap(
  $input-height: $_input-height
) {
  height: $input-height;
  line-height: $input-height;
}

@mixin h-input-label-active {
  transform: scale(0.75) translateY(-11px);
}

@mixin h-input-reset(
  $input-offset: $_input-offset,
  $input-offset-top: $_input-offset-top
) {
  display: block;
  width: 100%;
  height: inherit;
  line-height: inherit;
  font-weight: inherit;
  padding: $input-offset-top $input-offset 0;
  background: transparent;
  border-radius: 0;
  border: 0;
  -webkit-appearance: none;

  &:invalid,
  &:focus {
    box-shadow: none;
    color: $color--ui--text-body;
    outline: none;
  }
}

@mixin h-mage-error(
  $input-height: $_input-height
) {
  @include text--error;
  text-align: center;
  border-radius: 0 0 $_border-radius $_border-radius;
  background: $color--ui--invalid;
  color: $color--ui--error;
  margin-top: 8px - $input-height;
  margin-bottom: 20px;
  padding: 22px 24px 5px;
  max-height: 0;

  &.open,
  &.animating {
    display: block !important; // overrides display none set by magento and allows the error to animate out
  }
}

@mixin h-select-reset {
  @include h-input-reset;
  min-width: 50px;
  padding-top: 4px;
}

@mixin styled-checkbox-after(
  $rounded: 0,
  $size: 26px,
  $padding: 3px
) {
  position: absolute;

  @if $rounded == 0 {
    @include icon('check', 'solid');
    top: 5px;
    left: 4px;
    color: $color--ui--button-radio-text-selected;
  } @else {
    display: inline-block;
    flex-shrink: 0;
    top: $padding;
    left: $padding;
    width: $size - 2 * $padding;
    height: $size - 2 * $padding;
    border: 1px solid transparent; // use border as placeholder for box-shadow
    box-shadow: 0 0 0 2px $color--neutral--white;
    border-radius: $rounded;
    background: $color--brand--primary-blend;
    content: '';
  }
}

@mixin styled-checkbox(
  $label-class: '.input-label',
  $input-class: '.form-input',
  $rounded: 0,
  $size: 26px,
  $padding: 3px
) {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  #{$input-class} {
    @include visually-hidden;
  }

  #{$label-class} {
    @include text--input-checkbox-label;
    display: flex;
    position: relative;
  }

  #{$label-class}::before {
    content: '';
    display: inline-block;
    flex-shrink: 0;
    width: $size;
    height: $size;
    margin-right: 12px;
    border: 1px solid transparent; // use border as placeholder for box-shadow
    box-shadow: 0 0 0 1px $color--brand--tertiary;
    border-radius: $rounded;
  }

  #{$input-class}:checked + #{$label-class}::before {
    box-shadow: 0 0 0 1px $color--neutral--black;
  }

  #{$input-class}:focus + #{$label-class}::before {
    background-color: $color--neutral--cream;
    box-shadow: 0 0 0 1px $color--neutral--black;
  }

  #{$input-class}:checked + #{$label-class}::after {
    @include styled-checkbox-after(
      $rounded: $rounded,
      $size: $size,
      $padding: $padding
    );
  }

  #{$input-class}:checked:focus + #{$label-class}::before {
    background: none;
  }
}

@mixin h-textarea (
  $control-selector: '.control',
  $input-selector: 'textarea',
  $height: 100px
) {
  #{$control-selector} {
    height: $height;
    padding-top: 20px;
    line-height: 22px;
  }

  #{$input-selector} {
    height: 100%;
  }
}

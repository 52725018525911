@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Browse Promos 2 Up
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--browse-promos {
  $_border: $color--ui--border-medium;

  @include pb-reset-elements;

  display: flex;
  flex-direction: column;
  padding: 0 30px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  [data-content-type='column-group'] {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0 !important;

    &:first-child {
      flex-direction: column;

      @include media('>=tablet') {
        flex-direction: row;
      }
    }

    &:last-child {
      flex-direction: column-reverse;
      border-top: $_border !important;

      @include media('>=tablet') {
        flex-direction: row-reverse;
      }
    }
  }

  [data-content-type='column'] {
    width: 100% !important;
    justify-content: center !important;
    align-items: center;
    text-align: center;

    &:first-child {
      padding: 10px !important;
    }
  }

  [data-content-type='heading'] {
    @include text--heading-2;
    margin-bottom: 20px;
  }

  [data-content-type='button-item'] {
    @include button--more-cta-link-with-arrow--light-uppercase(
      $anchor-selector: '> a'
    );
  }

  @include media('>=tablet') {
    margin: 30px auto;

    [data-content-type='column-group'] {
      padding: 20px 0 !important;
    }
  }

  @include media('>=desktop') {
    flex-direction: row !important;
    max-width: 1280px;
    padding: 0 !important;

    [data-content-type='column-group'] {
      padding: 15px 4% !important; // 4% ~70px scale on shrink
      width: 50% !important;
      height: 300px;

      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        flex-direction: row-reverse;
        border-top: 0 !important;
        border-left: $_border !important;
        padding-right: 0 !important;
      }
    }
  }
}

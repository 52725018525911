/**
 * stockists-search styling
 */

.stockists-search {
  @import 'aligent-scss-placeholder/components/stockists-search';

  .stockists-search__heading {

  }

  .stockists-search__text-input {

  }

  .stockists-search__button {

  }

  .stockists-search__loader {

  }
}

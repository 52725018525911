@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins-animation';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
@import '../components/pagebuilder';
/**
 * Styling for the footer
 */

.page-footer {
  @include pb-reset-elements;

  .footer__trust,
  .footer__main,
  .footer__base,
  .footer__accept-cookies {
    width: 100%;
  }

  // page builder block: footer-trust-messages
  // !important used to override page builder inline styles
  .footer__trust {
    @include text--large;
    position: relative;
    background-color: $color--ui--bg-light;
    padding: 100px 0 60px;
    text-align: left !important;
    overflow: hidden;

    &::before {
      @include pos-abs-center;
      content: '';
      background: url('../images/emblem-740-890.png') no-repeat center center;
      background-size: 100%;
      width: 267px;
      height: 321px;
      opacity: 0.02;
    }

    [data-content-type="column-group"] {
      @include pb-columns(2, 60px, 60px);
    }

    [data-content-type='heading'] {
      @include text--lead-text;
      text-align: left !important;
    }

    @include media('<tablet') {
      @include text--medium;

      [data-content-type="column-group"] {
        @include pb-columns(1, 60px, 60px);
      }

      &,
      [data-content-type='heading'] {
        text-align: center !important;
      }

      [data-content-type='text'] {
        padding: 0 15px !important;
      }
    }
  }

  .footer__main {
    background-color: $color--ui--bg-light-3;
    padding: 20px 0 30px;
  }

  .footer__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;

    @include media('>=tablet') {
      flex-direction: row;
      justify-content: space-between;
      align-items: normal;
      min-height: 160px;
      margin: 0;
    }
  }

  .footer__header__logo {
    display: flex;
    align-items: center;

    @include media('>=tablet') {
      margin: 0;
    }
  }

  .footer__header__content {
    text-align: center;
  }

  .footer__social-links {
    .footer-social-images {
      .pagebuilder-column-group {
        justify-content: center;
        margin: 10px 0 20px !important;

        @include media('>=tablet') {
          margin: 0 0 10px !important;
        }
      }

      .pagebuilder-column {
        width: unset !important;
      }

      .social-image {
        margin: 0;
        padding: 10px;

        img {
          height: 22px;
          width: 22px;
        }

        @include media('<=tablet') {
          img {
            height: 30px;
            width: 30px;
          }
        }

        .pagebuilder-mobile-hidden {
          display: block !important;
        }

        .pagebuilder-mobile-only {
          display: none !important;
        }

        &:hover {
          .pagebuilder-mobile-hidden {
            display: none !important;
          }
  
          .pagebuilder-mobile-only {
            display: block !important;
          }
        }
      }

      .pagebuilder-button-primary {
        @include button-style--secondary;
      }
    }
  }

  .footer__listed-links {
    margin: 22px -10px 32px;
  }

  .footer__base {
    @include text--tiny;
    background-color: $color--ui--bg-dark;
    padding: 20px 0;
    color: $color--ui--text-medium-on-dark;
    text-transform: uppercase;

    .content {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 20px 0;

      @include media('>=tablet') {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
      }
    }
  }

  .footer__certified {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    padding: 0 20px;
    min-width: 220px;

    .cert-link {
      @include transition('opacity');
      display: flex;
      opacity: 0.66;

      &:not(:last-child) {
        margin: 0 20px 0 0;
      }

      &:hover {
        opacity: 1;
      }
    }

    .cert-link__image {
      height: 50px;
    }

    @include media('>=tablet') {
      margin: 0;
    }
  }

  .footer__links [data-content-type='button-item'] {
    display: block;
    width: 100%;

    [data-element='link'] {
      @include link('dark', 'medium');
    }

    &:not(:last-child) {
      margin: 0 0 20px;
    }

    @include media('>=tablet') {
      display: inline-block;
      width: auto;

      &:not(:last-child) {
        margin: 0 20px 0 0;
      }
    }
  }

  .footer__accept-cookies {
    @include zIndex('accept-cookies');
    @include transition('background');
    position: fixed;
    bottom: 0;
    background: $color--ui--bg-dark;
    border-top: $color--ui--border-medium;
    overflow: hidden;
    display: none; // hc-cookies: revealed if not accepted

    .content {
      padding: 20px 0;
      align-items: center;
    }

    .accept-cookies__message {
      @include transition('color');
      @include text--accept-cookies;
      color: $color--ui--text-light-on-dark;
      flex: 1;
      text-align: center;
    }

    .accept-cookies__button {
      @include button--accept-cookies;
      margin-top: 20px;
    }

    &:hover {
      cursor: pointer;
      background: $color--neutral--masala;

      .accept-cookies__message {
        color: $color--neutral--white;
      }
    }

    @include media('>=tablet') {
      .content {
        flex-direction: row;
      }

      .accept-cookies__message {
        text-align: left;
      }

      .accept-cookies__button {
        margin-top: 0;
      }
    }
  }
}

/**
 * Styling for the free shipping indicator
 *
 * This isn't a common component, however as it's been bundled into a very easy to use package, it's likely that
 * more clients might want it
 */

.free-shipping-indicator {
  @import 'aligent-scss-placeholder/components/free-shipping-indicator';

  .progress-label {

  }

  .meter-container {

  }

  .meter {

  }
}

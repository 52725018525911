@import "../settings/animation";
@import "../tools/mixins-button";

.accordion {
    overflow: hidden;

    &.accordion--expanded {
        .accordion__toggle::after {
            transform: rotate(180deg);
        }

        .accordion__contents {
            visibility: visible;
        }
    }

    .accordion__toggle {
        @include button-defaults;
        display: flex;
        text-transform: none;
        padding-left: 0;

        &::after {
            @include icon("angle-down", "light");
            transition: transform $transition-duration ease-in-out;
            margin-left: 16px;
            font-size: 24px;
        }
    }

    .accordion__contents {
        transition: max-height $transition-duration ease-in-out;
        visibility: hidden;
    }
}

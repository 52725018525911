/**
 * opc-summary-wrapper styling
 */

.opc-summary-wrapper {
  @import 'aligent-scss-placeholder/components/opc-summary-wrapper';

  &._show {

  }

  .action-close {

    &::before {

    }

    span {

    }
  }

  .opc-block-summary {
    > .title { // <span>

    }
  }

  .product-item { // <li>

    &:first-child {

    }
  }

  .product {

  }

  .product-item-details {

  }

  .product-item-inner {

  }

  .product-item-name-block {

  }
}

/**
* Styling for the home page
*/

.cms-home {
  .page-wrapper .page-main {
    max-width: none;
    margin: 0;
    padding: 0;
  }
}

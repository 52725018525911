@use 'tools/mixins-form-elements' as *;

/**
 * Styling for select elements
 */

.select-wrapper {
  @include select-style;
}

select {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
}

@import '../tools/mixins-form-elements';
@import '../settings/colours';

$validation-border-radius: $border-radius-large;

.select-component,
.input-component {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;

  .input-label-required {
    margin-left: 2px;
  }
}

.validation-message {
  @include h-mage-error;
  font-weight: 500;
  max-height: unset;

  .is--valid & {
    background: $_color-gold-blend;
    color: $color--brand--tertiary;
  }
}

.email-container,
.password-container,
.tel-container,
.text-container {
  @include input-style(
    $focus-class: '.input-container--focused',
    $error-class: '.input-container--validation-error'
  );
  margin-bottom: 20px;

  .input-label {
    @include h-input-label;
  }

  &.input-container--focused,
  &:not(.input-container--empty) {
    .input-label {
      @include h-input-label-active;
    }
  }

  .input-control {
    @include h-input-wrap;
  }

  .form-input {
    @include h-input-reset;
  }

}

.textarea-container {
  $_min-height: 100px;
  $_input-offset: 24px;

  @include input-style(
    $focus-class: '.textarea-container--focused',
    $error-class: '.textarea-container--validation-error'
  );

  margin-bottom: 20px;
  height: auto;
  min-height: $_min-height;

  .textarea-label {
    @include h-input-label;
  }

  &.textarea-container--focused,
  &:not(.textarea-container--empty) {
    .textarea-label {
      @include h-input-label-active;
    }
  }

  .textarea-control {
    @include h-input-wrap;
  }

  .form-input {
    @include h-input-reset(
      $input-offset-top: 0
    );
    resize: none;
  }

  @include h-textarea(
    $control-selector: '.textarea-control',
    $input-selector: '.form-input'
  );
}

.select-container {
  @include input-style(
    $focus-class: '.select-container--focused',
    $error-class: '.validation-error'
  );
  margin-bottom: 20px;

  .select-label {
    @include h-input-label;
    @include h-input-label-active; // select always active state
  }

  .select-wrapper {
    @include h-input-wrap;

    &::after {
      top: 55%;
      right: 24px;
    }
  }

  .select-input {
    @include h-select-reset;
    padding-right: 40px;
  }
}

.checkbox-container {
  @include styled-checkbox;
}

.radio-container {
  @include styled-checkbox($rounded: 50%);
}

.checkout-app-shipping-methods {
  position: relative;
}

.shipping-methods-container {
  margin: 15px 0 50px;
}

.shipping-methods {
  list-style: none;
  margin: 0;
  padding: 0;
}

.shipping-methods-message {
  @include text--checkout-payment-text;
}

.shipping-method {
  @include styled-checkbox(
    $input-class: '.radio',
    $label-class: '.shipping-method__label',
    $rounded: 50%
  );

  padding-bottom: 1rem;
  border-bottom: 1px solid $color--ui--border-color-light;

  &:last-child {
    border: 0;
    padding: 0;
    margin: 0;
  }

  .shipping-method__label {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
  }

  .shipping-label {
    order: 1;
  }

  .shipping-price {
    @include text--checkout-shipping-price;
    flex-grow: 1;
    text-align: right;
    order: 2;
  }

  .shipping-description {
    width: 100%;
    padding-left: 34px;
    order: 3;
  }

}

.billing-address-container,
.delivery-address-container {
  border-bottom: $color--ui--border-light;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-left: 36px;

  .radio-container {
    margin-bottom: 0;
  }

  .input-label {
    margin-left: -36px;
    cursor: pointer;
  }
}

.address-container__last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

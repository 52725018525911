@import '../tools/mixins-animation';
@import '../tools/mixins-typography';
/**
 * Styling for html/body
 */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body {
  @include text--base;
  @include transition('margin-top');

  // offsets for sticky header
  $header-height: 111px;
  $header-height--tablet: 121px;
  $header-height--desktop: 147px;

  margin-top: $header-height;

  @include media('>=tablet') {
    margin-top: $header-height--tablet;
  }

  @include media('>=desktop') {
    margin-top: $header-height--desktop;
  }
}

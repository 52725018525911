@import '../elements/icons';
@import '../settings/layout';
@import '../tools/mixins';

.react-checkout .custom-message {
    @include warning-message($inner-selector: '.custom-message__inner');

    &.custom-message--mobile {
        display: block;
        order: 1;

        @include media('>=tablet') {
            display: none;
        }
    }

    &.custom-message--hidden {
        display: none;
    }

    .custom-message__inner {
        padding: 12px 8px;

        @include media('>=tablet') {
            padding: 20px 16px;
        }
    }

    .custom-message__message {
        margin: 3px 0;

        // Remove top margin of contents from pagebuilder WYSIWYG text
        :first-child {
          margin-top: 0;
        }

        // Remove bottom margin of contents from pagebuilder WYSIWYG text
        :last-child {
          margin-bottom: 0;
        }
    }

    .custom-message__content {
        flex: 1;
    }

    .custom-message__close-button {
        padding: 0 10px 10px;
        color: $color--neutral--grey;
        background: transparent;

        &:hover {
            background: transparent;
        }

        &::after {
            @include icon('close', 'light');
        }
    }
}

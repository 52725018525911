@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../settings/animation';

$_width: 370px;
$_circle-indicator-size: 29px;
$_outer-inline-padding: 15px;
$_final-position-translate-x: -24px;
$_final-position-translate-y: 5px;

@mixin circle-indicator($background) {
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    height: $_circle-indicator-size;
    width: $_circle-indicator-size;
    border-radius: $_circle-indicator-size / 2;
    background: $background;
  }
}

.flatpickr-calendar {
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 15px 0;
  transform: translate3d($_final-position-translate-x, $_final-position-translate-y, 0);
  animation: none;
  direction: ltr;
  font-size: 14px;
  line-height: 24px;
  border-radius: 20px;
  position: absolute;
  width: $_width;
  box-sizing: border-box;
  touch-action: manipulation;
  background: $color--ui--bg-default;
  box-shadow: $color--ui--shadow-date-picker;
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown $transition-duration $transition-timing-function;
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}


.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar::before,
.flatpickr-calendar::after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost::before,
.flatpickr-calendar.rightMost::after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar::before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar::after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after {
  bottom: 100%;
}

.flatpickr-calendar.arrowBottom::before,
.flatpickr-calendar.arrowBottom::after {
  top: 100%;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: $color--ui--text-body;
  fill: $color--ui--text-body;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 55px;
  padding: 20px 15px 15px;
  z-index: 3;
  color: $color--ui--text-body;
  fill: $color--ui--text-body;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}


.numInputWrapper {
  position: relative;
  height: auto;
  margin-left: 5px;

  input {
    display: inline-block;
    width: 100%;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-ms-clear {
      display: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
    }
  }
}

.flatpickr-current-month {
  font-size: 135%;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month .cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month .cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 62px;
  display: inline-block;
}

.flatpickr-current-month .cur-year {
  @include text--base;
  appearance: none;
  -moz-appearance: textfield;
  background: transparent;
  border: 0;
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  @include text--base;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  position: relative;
  vertical-align: initial;
  width: auto;

  &:hover {
    background: $color--ui--input-bg;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
}

.flatpickr-weekdays {
  @include text--tag;
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
  margin-top: 5px;
  padding: 0 $_outer-inline-padding;
  border-bottom: $color--ui--border-light;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

.flatpickr-weekday {
  cursor: default;
  background: transparent;
  margin: 0 0 10px;
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;

  // This hides everything except for the first letter of the weekday
  text-orientation: upright;
  writing-mode: tb;
  height: 16px;
  overflow: hidden;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 8px $_outer-inline-padding 0;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.flatpickr-day {
  $_day-size-mobile: 35px;
  $_day-size: 30px;
  $_one-seventh: 100% / 7;

  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 400;
  width: $_one-seventh;
  flex-basis: $_one-seventh;
  max-width: $_one-seventh;
  height: $_day-size-mobile;
  line-height: $_day-size-mobile;
  margin: 0;
  position: relative;
  text-align: center;

  @include media('>=lg') {
    height: $_day-size;
    line-height: $_day-size;
  }
}

.flatpickr-day:hover:not(.flatpickr-disabled) {
  @include circle-indicator($color--ui--input-bg);
  cursor: pointer;
  outline: 0;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @include circle-indicator($color--brand--primary-blend);
  color: $color--ui--text-body;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: $color--neutral--french-grey;
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: $color--neutral--french-grey;
}

.flatpickr-day.week.selected {
  border-radius: 0;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper .flatpickr-day,
.flatpickr-weekwrapper .flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: 0;
}

.flatpickr-innerContainer {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}

.flatpickr-time::after {
  content: '';
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.numInputWrapper {
  &:hover {
    background: $color--ui--input-bg;

    .arrowUp,
    .arrowDown {
      opacity: 1;
    }
  }

  .arrowUp,
  .arrowDown {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      position: relative;
      top: 40%;
    }
  }

  .arrowUp {
    top: 0;
    border-bottom: 0;

    &::after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid $color--ui--border-color;
    }
  }

  .arrowDown {
    top: 50%;

    &::after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $color--ui--border-color;
    }
  }
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
}

.flatpickr-time .flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time .flatpickr-minute,
.flatpickr-time .flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d($_final-position-translate-x, $_final-position-translate-y - 20, 0);
  }

  to {
    opacity: 1;
    transform: translate3d($_final-position-translate-x, $_final-position-translate-y, 0);
  }
}

@import '../settings/colours';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
@import '../elements/icons';
@import '../tools/z-index';
/**
 * Styling for the MiniCart
 */

// minicart's toggle button context in header is header__main
// then switches to header__top-links on desktop
.header-sticky {
  .header__main .minicart-toggle__counter {
    @include transition('top');
    left: 8px;
    top: -17px;
  }

  &.hc-sticky-scroll--sticky {
    .header__main .minicart-toggle__counter {
      top: -10px;
    }
  }
}

/**
 * MiniCart Toggle
 */
.minicart-toggle {
  .minicart-toggle__button {
    pointer-events: none; // disable button until hc-minicart.js is loaded to prevent default (go to cart)

    &::before {
      @include icon('cart', 'solid');
    }

    .text {
      display: none;
    }
  }

  .minicart-toggle__counter {
    $_circle-size: 22px;

    @include text--tiny;
    font-weight: 700;
    position: absolute;
    background: $color--brand--primary-blend;
    color: $color--neutral--black;
    border-radius: 50%;
    width: $_circle-size;
    height: $_circle-size;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -14px;
    left: 8px;

    .counter-number {
      @include revert-text-gradient;
    }

    &.empty {
      display: none;
    }

    [data-role='loader'] {
      display: none;
    }
  }
}

@mixin minicart-gutters {
  padding-left: $gutter-tiny;
  padding-right: $gutter-tiny;

  @include media('>=lg') {
    padding-left: $gutter-medium;
    padding-right: $gutter-medium;
  }
}

/**
 * MiniCart Menu
 */
.minicart-wrapper {
  @include zIndex('minicart');
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;

  // override inline style to allow minicart state transitions
  .ui-dialog,
  .ui-dialog-content {
    display: block !important;
    pointer-events: none;
  }

  &:not(.is-touch-supported) {
    input {
      // overrides when on non-touch-supported device to keep center alignment
      // when input stepper navigation is added
      padding: 0 0 0 14px !important;
    }
  }

  .minicart__curtain {
    @include curtain;
    z-index: -1;
  }

  .minicart__content {
    @include transition('transform');
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $color--neutral--white;
    box-shadow: $color--ui--shadow-sticky-right;
    width: calc(100% - 40px);
    max-width: 420px;
    height: 100%;
    overflow: hidden;
    transform: translateX(110%); // 110% of the mobile menu width so shadow isn't visible at edge
  }

  .minicart__header {
    @include minicart-gutters;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    flex-shrink: 0;
  }

  .minicart__header__close {
    [data-action='close'] {
      @include button--back-arrow--reverse;
      font-size: 23px;
      color: $color--neutral--grey;
    }
  }

  .minicart__header__title {
    font-weight: 300;
    text-transform: uppercase;
  }

  .minicart__header__item-count {
    $_circle-size: 32px;

    @include text--small;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color--brand--primary-blend;
    width: $_circle-size;
    height: $_circle-size;
    border-radius: 50%;
  }

  .minicart__subtotal {
    @include minicart-gutters;
    background: $color--neutral--red-cream;

    .subtotal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;

      .label {
        @include text--tiny;
        text-transform: uppercase;
      }

      .amount {
        @include text--minicart-price;
      }
    }
  }

  .minicart__cart-items {
    $_product-image-size: 125px;
    $_product-image-size--small-mobile: 80px;

    @include minicart-gutters;
    padding-top: 20px;
    padding-bottom: 20px;

    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .minicart-items {
      margin: 0;
      padding: 0;
    }

    .product-item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .product {
      display: flex;
      width: 100%;
    }

    .product-item-details__special {
      @include text--medium;
    }

    .product-image-wrapper {
      /**
       * minicart/checkout item summary product image does not include borders on idle or hover
       * overrides product-item.scss
       */
      &,
      &:hover {
        background: none;
      }
    }

    .product-item-photo {
      width: $_product-image-size--small-mobile;
      margin-right: 10px;

      @include media('>=md') {
        flex-shrink: 0;
      }

      @include media('>lg') {
        width: $_product-image-size;
      }
    }

    .product-image-container {
      height: auto; // overrides product-item.scss
    }

    // override inline style
    .product-image-photo {
      width: 100% !important;
      height: auto !important;
    }

    .product-item-details {
      position: relative;
      margin: 0;
      justify-content: flex-start;

      .cart-item-qty {
        $_qty-input-height: 35px;

        @include input-style;
        width: 55px;
        height: $_qty-input-height;
        line-height: $_qty-input-height;
        text-align: center;
        padding: 0;
      }

      .update-cart-item {
        @include button-style--primary;
        @include text--small;
        font-weight: 700;
        padding: 5px 10px;
        position: absolute;
        left: 0;
        width: 110px;
      }

      .actions {
        .primary {
          display: none;
        }

        .secondary {
          position: absolute;
          top: 0;
          right: 0;
          line-height: 1em;

          .action {
            @include button-style--icon;

            &::before {
              @include icon('close', 'light');
              color: $color--neutral--grey;
            }

            span {
              @include visually-hidden;
            }
          }
        }
      }
    }

    .product-item-name {
      @include text--minicart-product-item-name;
      padding-right: 25px;
    }

    .product-item__options {
      @include text--minicart-product-item-options;
      color: $color--neutral--grey;
      margin-top: 3px;

      [data-bind='text: option.value'] {
        word-break: break-word;
      }
    }

    .product-item__options__item:first-child {
      margin-right: -0.3em; // pull back due to extra space rendered
    }

    .product-item-pricing {
      display: flex;
      margin-top: 13px;

      .price-container {
        display: flex;
        align-items: center;
        margin-right: 25px;
        min-width: 55px;
      }

      .price {
        @include text--minicart-price;
      }

      .details-qty {
        display: flex;
        align-items: center;

        .label {
          @include text--small;
          text-transform: uppercase;
          margin-right: 4px;
        }
      }

      .details-qty--gift {
        .label {
          margin-right: 15px;
        }
      }
    }
  }

  .minicart__cart-items__empty-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    height: 100%;
    text-align: center;
  }

  .minicart__actions {
    @include minicart-gutters;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: $color--ui--border-light;

    .action.primary {
      @include button-style--primary;
      width: 100%;
    }
  }

  &.toggle-minicart--active {
    pointer-events: auto;
    display: block;

    .ui-dialog,
    .ui-dialog-content {
      pointer-events: auto;
    }

    .minicart__curtain {
      @include curtain--active;
    }

    .minicart__content {
      transform: translateX(0);
    }
  }
}

@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Promo Links
 * Page Builder Block - !important used frequently to override inline styles
 */

// increase specificity to override pagebuilder.scss#27
.column.main > [data-content-type='row'].pb-block--promo-links > [data-element='inner'] {
  @include site-wrapper(!important);
}

.pb-block--promo-links {
  $_border: $color--ui--border-medium !important;

  @include pb-reset-elements;
  background: $color--neutral--red-cream;

  [data-content-type='column'] {
    text-align: center;
  }

  [data-content-type='heading'] {
    @include text--home-hero-promo-links--heading;
  }

  [data-content-type='text'],
  [data-content-type='buttons'],
  [data-content-type='button-item'] {
    @include text--home-hero-promo-links;
  }

  [data-content-type='text'] {
    p {
      @include first-last-no-end-margins(!important);
    }
  }

  @include media('<tablet') {
    padding: 35px 0 !important;

    [data-content-type='column-group'] {
      @include pb-columns(1, 40px);
    }

    [data-content-type='column']:not(:first-child) {
      border-top: $_border;
    }
  }

  @include media('>=tablet') {
    padding: 15px 0 !important;

    [data-content-type='column-group'] {
      @include pb-columns(3, 0, 40px);
    }

    [data-content-type='column'] {
      padding-top: 10px !important;
      padding-bottom: 20px !important;
      text-align: left;

      &:not(:first-child) {
        border-left: $_border;
      }
    }
  }
}

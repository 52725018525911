/**
 * Styling for the site wide header
 */

.page-header {
  $announcement-background-color: $color--brand--announcement;
  $announcement-font-size: 13px;
  $announcement-font-color: $color--neutral--white;

  .announcement {
    position: relative;
    line-height: 15px;
    padding: 1em 0;
    width: 100%;
    background: $announcement-background-color;
    color: $announcement-font-color;
    font-size: $announcement-font-size;
    overflow: hidden;

    &.compact {
      .html-announcement {
        max-height: 34px;
      }

      .announcement-button{
        color: $announcement-font-color;
        &::after {
          transform: rotate(-180deg);
        }
      }
    }

    .html-announcement {
      padding: 0 35px;
      text-align: center;
      max-height: 500px;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;

      a { // Styling PageBuilder content
        color: $announcement-font-color;
      }

      p { // Styling PageBuilder content
        margin: 1px 0;
      }
    }

    .announcement-button {
      position: absolute;
      padding: 10px;
      right: 0;
      top: 0;
      background: transparent;

      &::after {
        @include button-style--icon;
        @include icon('up-arrow');
        font-size: 22px;
        transition: 0.2s;
        color: $announcement-font-color;
      }
    }

    .announcement__show-more {
      @include button--padded-link;
      text-decoration: underline;
      color: $announcement-font-color;
    }
  }
}


iframe#launcher,
iframe[title='Number of unread messages'],
iframe[name='Messaging window'] {
  z-index: 12 !important;
}

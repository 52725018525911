@import '@aligent/frontend-toolkit/scss/mixins/reset-button';
@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-typography';
@import '../elements/icons';
/**
 * Button mixins
 */

@mixin button-defaults {
  @include reset-button;
  @include transition('background, border, box-shadow, color');
  @include text--button;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 16px 30px;
  border-radius: 6px;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

/**
 * Button styles
 */
@mixin button-style--default {
  @include button-defaults;
  background: $color--ui--button-default-bg;
  color: $color--ui--button-default-text;

  &:hover,
  &:focus {
    background: $color--ui--button-default-bg-hover;
    color: $color--ui--button-default-text-hover;
  }

  &:active {
    background: $color--ui--button-default-bg-active;
    color: $color--ui--button-default-text-active;
  }
}

@mixin button-style--primary {
  @include button-defaults;
  background: $color--ui--button-primary-bg;
  color: $color--ui--button-primary-text;

  &:hover,
  &:focus {
    background: $color--ui--button-primary-bg-hover;
    color: $color--ui--button-primary-text-hover;
  }

  &:active {
    background: $color--ui--button-primary-bg-active;
    color: $color--ui--button-primary-text-active;
  }
}

@mixin button-style--secondary {
  @include button-defaults;
  background: $color--ui--button-secondary-bg;

  // Anchor to style PageBuilder button's child anchor
  &,
  a {
    color: $color--ui--button-secondary-text;

    &:hover,
    &:focus {
      background: $color--ui--button-secondary-bg-hover;
      color: $color--ui--button-secondary-text-hover;
    }

    &:active {
      background: $color--ui--button-secondary-bg-active;
      color: $color--ui--button-secondary-text-active;
    }
  }
}

@mixin button-style--secondary--alt {
  @include button-style--secondary;
  @include text-heading-style(
    $letter-spacing: 0.75px
  );
  background: $color--ui--bg-dark;
  border-radius: 0;
  text-transform: none;

  // Anchor to style PageBuilder button's child anchor
  &,
  a {
    color: $color--neutral--red-cream;
  }
}

@mixin button-style--ghost {
  @include button-defaults;
  border: $color--ui--border;

  &:hover {
    background: $color--neutral--alabaster;
  }

  &:active {
    @include gold-text-gradient;
  }
}

@mixin button-style--outline {
  @include button-defaults;
  background: $color--ui--button-outline-bg;
  border: 1px solid $color--ui--button-outline-border-color;
  color: $color--ui--button-outline-text;

  &:hover,
  &:focus {
    background: $color--ui--button-outline-bg-hover;
    border-color: $color--ui--button-outline-border-color-hover;
    color: $color--ui--button-outline-text-hover;
  }

  &:active {
    background: $color--ui--button-outline-bg-active;
    border-color: $color--ui--button-outline-border-color-active;
    color: $color--ui--button-outline-text-active;
  }
}

@mixin button-style--clear {
  @include button-defaults;
  background: transparent;
  color: $color--ui--button-default-text;

  &:hover,
  &:focus {
    background: transparent;
    color: $color--ui--button-default-text-hover;
  }

  &:active {
    background: transparent;
    color: $color--ui--button-default-text-hover;
  }
}

@mixin button-style--link {
  @include button-defaults;
  @include link;
  min-width: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

@mixin button-style--icon {
  @include button-defaults;
  margin: 0;
  padding: 0;
  border-radius: 0;
  line-height: 1em;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    min-width: $min-button-size;
    min-height: $min-button-size;
    transform: translate3d(-50%, -50%, 0);
  }
}

/**
 * Button all types
 */
@mixin button--accordion-level-0 {
  @include button-style--default;
  @include text--accordion-level-0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center !important;
  background: $color--ui--accordion-bg;
  width: 100% !important;
  height: 60px;
  margin: 0 !important;
  padding: 0 20px !important;
  border-radius: 0;

  .pagebuilder-button-primary {
    @include text--accordion-level-0;
  }

  &::after {
    @include icon('accordion');
    @include gold-text-gradient;
    @include transition('transform');
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(50%, -50%);
  }
}

@mixin button--accordion-level-0--active {
  &,
  &:hover,
  &:focus {
    background: $color--ui--accordion-bg-active;
  }

  &::after {
    transform: translate(50%, -50%) rotate(180deg);
  }
}

@mixin button--amasty-filter-item {
  @include button-style--default;
  width: 100%;
  border-radius: 0;
  font-weight: 400;
  text-transform: none;
  text-align: left;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background: transparent;
  }
}

@mixin button--more-cta {
  @include button-style--link;
  @include text--more-cta-link;
  background: transparent;
  text-align: left;
}

@mixin button--more-cta-link-with-arrow (
  $anchor-selector: '&'
) {
  @include text--base;
  white-space: nowrap;

  #{$anchor-selector}::after {
    @include icon('right-arrow-circle', 'solid');
    margin-left: 10px;
    font-size: 14px;
  }
}

@mixin button--more-cta-link-with-arrow--light-uppercase (
  $anchor-selector: '&'
) {
  @include button--more-cta-link-with-arrow($anchor-selector);
  font-weight: 300;
  text-transform: uppercase;
}

@mixin button--menu-top-level-link {
  @include button-style--link;
  @include text--list-heading-link;
  width: 100%;
  margin: 0 0 23px !important;
  text-align: left;
}

@mixin button--menu-list-heading {
  @include button-style--link;
  @include text--list-heading-link;
  width: 100%;
  margin: 0 0 23px !important;
  text-align: left;
  font-family: $font-family-heading;
  font-size: 20px;
  text-transform: none;
  letter-spacing: 0;
}

@mixin button--menu-list-item {
  @include button-style--link;
  @include text--list-item-link;
  width: 100%;
  margin: 0 0 15px !important;
  text-align: left;
}

@mixin button--menu-link {
  @include button-style--default;
  @include text--lead-text--regular;
  background: transparent;
  width: 100%;
  border-radius: $border-radius-small;
  margin: 0;
}

@mixin button--icon-text {
  @include button-style--link;
  @include text--icon-text;
  display: flex;
  align-items: center;
  margin: 3px 0;

  &::before {
    @include gold-text-gradient;
    font-size: 20px;
    margin-right: 10px;
  }
}

@mixin button--icon-text--actions {
  @include button--icon-text;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.73px;
  margin: 0;
  line-height: 22px;

  &::before {
    @include revert-text-gradient;
    font-size: 22px;
  }
}

@mixin button--radio {
  @include button-style--default;
  @include text--radio-button;
  border-radius: 0;
  border: $color--ui--border-medium;
  text-align: center;
  min-width: 80px;
  padding: 10px 0;
  margin: 0 10px 10px 0;

  @include media('>=tablet') {
    padding: 16px 0;
    min-width: 95px;
  }

  &,
  &:hover,
  &:focus {
    background: $color--ui--button-radio-bg;
    color: $color--ui--button-radio-text;
  }
}

@mixin button--radio--selected {
  font-weight: 700;

  &,
  &:hover,
  &:focus {
    background: $color--ui--button-radio-bg-selected;
    color: $color--ui--button-radio-text-selected;
  }
}

@mixin button--cross {
    @include button-style--icon;
    font-size: 18px;

    &::before {
        @include icon('close', 'light')
    }
}

@mixin button--back-arrow {
  @include button-style--icon;
  font-size: 18px;

  &::before {
    @include icon('back-arrow', 'light');
  }
}

@mixin button--back-arrow--reverse {
  @include button--back-arrow;

  &::before {
    transform: scale(-1);
  }
}

@mixin button--store-locator-action {
  @include button-style--link;
  @include text--store-locator-action;
}

@mixin button--ghost-button {
  @include button-style--ghost;
  @include text--ghost-button;
  display: inline-block;
  height: 38px;
  min-width: 156px;
  padding: 0 25px;
}

@mixin button--accept-cookies {
  @include button-style--link;
  @include text--accept-cookies;
  display: flex;
  align-items: center;

  > span {
    @include gold-text-gradient;
    margin: 0 20px;
  }

  &::after {
    @include gold-text-gradient;
    @include icon('check-circle', 'solid');
    @include transition('none');
    font-size: 21px;
  }

  &:hover,
  &:focus {
    &::after {
      @include gold-text-gradient;
    }
  }
}

@mixin button--checkout-primary {
  @include button-style--primary;
  @include text--checkout-button;
  height: 44px;
  padding: 10px 22px;
}

@mixin button--checkout-ghost {
  @include button-style--ghost;
  @include text--checkout-button;
  height: 44px;
  padding: 10px 25px;
}

@mixin button--checkout-total-modifier {
  @include button-style--link;
  @include text--checkout-total-modifier;
  text-transform: uppercase;
}

@mixin button--padded-link {
  @include button-style--link;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: $color--brand--primary;
  text-transform: none;

  &:hover,
  &:active,
  &:focus {
    color: darken($color--brand--primary, 15%);
  }
}

@mixin button--action {
  @include button-style--ghost;
  padding: 8px 16px;
  border-radius: $border-radius-small;

  &:hover {
    color: $color--ui--link-text-hover;
  }
}

@mixin button--action--small {
  @include button--action;
  @include text--small;
  margin: 0 5px 5px 0;
  padding: 5px 10px;
  border: 1px solid $color--ui--border-color-light;
}

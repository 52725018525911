@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-animation';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
@import '../tools/z-index';
/**
 * Contextual styling for modals
 */

.modals-wrapper { // This container wraps all modals, as well as the modals overlay
  @include modal (
    $curtain-selector: '.modal-popup::before',
    $container-selector: '.modal-popup',
    $content-selector: '.modal-inner-wrap',
    $close-selector: '.action-close',
    $heading-selector: '.modal-title',
    $active-class: '._show',
    $active-selector: '.modal-popup',
    $active-curtain-selector: '&::before',
    $important-z-index: !important // overrides inline z-index: 1
  );

  .modal-popup::before {
    content: ''; // add content for curtain
  }

  .modal-title {
    margin-bottom: 20px;
  }

  .modal-content {
    @include text--medium;
    margin-bottom: 30px;
  }

  .action-close {
    span {
      @include visually-hidden;
    }
  }

  .modal-footer {
    .action-primary {
      @include button-style--primary;
      width: 100%;
    }

    .action-secondary {
      @include button-style--ghost;
      width: 100%;
      margin-bottom: 10px;

      @include media('>=md') {
        margin-bottom: 0;
        margin-right: 20px;
      }

      @include media('>=lg') {
        margin-right: 40px;
      }
    }

    @include media('>=md') {
      display: flex;
    }
  }

  .modals-overlay {
    display: none; // hide magento curtain
  }
}

@import '../settings/layout';
@import '../tools/mixins-typography';
@import '../tools/mixins-animation';
@import '../elements/icons';
@import 'magento-checkout';

/**
 * Styling for the checkout page
 */

.checkout-index-index {
  $_max-width: $checkout-max-width + $gutter-medium * 2;
  position: relative;
  margin: 0;
  padding: 0;
  background-color: $color--ui--bg-checkout;

  .header {
    position: relative;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    max-width: $_max-width;
    margin: auto;

    @include media('>=tablet') {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 34px;
    }

    .logo {
      display: block;
      max-width: 112px;
      margin-bottom: 8px;

      @include media('>=tablet') {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 133px;
        margin-bottom: 0;
      }
    }

    .logo__img {
      display: block;
      width: 100%;
      height: auto;
    }

    .shipping-info {
      @include transition('opacity, margin');
      text-transform: uppercase;
      font-size: 13px;
    }

    .back-to-shopping-container {
      display: flex;
      align-items: center;
      position: absolute;
      left: 15px;

      @include media('>=tablet') {
        position: relative;
        left: unset;
      }
    }

    .back-to-shopping-button {
      padding: 5px 0;
    }

    .back-to-shopping-button__text {
      display: none;
      font-size: 14px;
      font-weight: normal;
      text-transform: none;

      @include media('>=small-tablet') {
        display: block;
      }

      &::before {
        @include icon('arrow-left');
        margin-right: 4px;
      }
    }
  }

  .page-title-wrapper {
    display: none;
  }

  .page-wrapper .page-main {
    max-width: $_max-width;
  }

  .checkout-footer {
    max-width: $_max-width;
    border-top: $color--ui--border-light;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px 50px 70px;

    @include media('>=desktop') {
      padding-bottom: 22px;
    }

    .copyright {
      @include text--tiny;
      text-transform: uppercase;
      background: inherit;
      padding: 0;
    }
  }
}



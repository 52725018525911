@import '../settings/colours';
/**
 * Styling for the Image Gallery on the PDP
 */

.product.media {
  $dot-size: 6px;
  $dot-size--active: 8px;
  $dot-color: $color--ui--slider-indicator-bg;
  $dot-color--active: $color--ui--slider-indicator-bg-active;
  padding: 0;

  .fotorama__stage {
    max-height: 394px;

    @include media('>=tablet') {
      max-height: unset;
    }
  }

  .fotorama__nav__shaft {
    display: flex;
    justify-content: center;
  }

  .fotorama__nav__frame {
    margin: 0;
  }

  .fotorama__dot {
    border-radius: 50%;
    background-color: $dot-color;
    height: $dot-size;
    width: $dot-size;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .fotorama__active>.fotorama__dot {
    border: 0; // Overrides fotorama style
    background-color: $dot-color--active;
    height: $dot-size--active;
    width: $dot-size--active;
  }

  .fotorama__arr {
    width: 35px;

    @include media('>=tablet') {
      width: 80px;
    }
  }
}

@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins-button';


// general totals styles
.checkout-app__totals-summary {
  .price-display {
    @include text--checkout-totals-price;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .price-display__label {
    width: 100%;
    &.tax {
      font-size: 12px;
    }
  }
}


// order tab subtotals and delivery subtotals
.checkout-app__subtotals {
  position: relative;
  padding: 10px 0;

  .price-display__price {
    @include text--checkout-grandtotal-price;
    line-height: inherit; // fixes spacing
  }

  .react-checkout-loading__block {
    height: 100%;
    top: 0;
  }

  .order-totals__subtotal-info {
    @include text--checkout-totals-label;
    margin-right: 8px;
  }

  .order-totals__subtotal-light {
    @include text--checkout-totals-label-info;
  }
}

// payment tab totals
.checkout-app__totals {
  position: relative;
  padding: 26px 30px;
  border: $color--ui--border-light;
  background-color: $color--ui--bg-default;
  margin: 15px 0 50px;
  border-radius: 10px;

  .react-checkout-loading__block {
    height: 100%;
  }

  .price-display__label--control {
    display: flex;
    justify-content: space-between;

    .remove-modifier {
      margin-right: 10px;
      padding: 2px 40px;
      font-size: 0; // used to hide the 'X' text and then show the after content

      &::after {
        @include button--checkout-total-modifier;
        position: absolute;
        top: 5px;
        right: 12px;
        content: 'REMOVE';
      }
    }

    .tax-text {
      font-size: 9px;
    }
  }

  .order-totals__grand_total {
    @include text--checkout-grandtotal-price;
    margin-top: 13px;
    padding-top: 13px;
    border-top: $color--ui--border-light;
  }
}

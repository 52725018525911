/**
 * Outline file for afterpay overrides
 */

body { // Using <body> as these overrides in the imported file aren't necessarily going to all have the same parent class names
  @import 'aligent-scss-placeholder/overrides/afterpay';

  #afterpaypayovertime-method {
    div:last-child {
      // This was used to add display:none to this empty div that is added inside the Afterpay payment method on the checkout
      // purely to clear floats, but I don't know which floats that is
    }
  }


  // The following block will fix an issue that will occur as the page loads, and mage-init has yet to grab all modals
  // The issue is the images that are displayed in the Afterpay modal will flash up on screen
  #afterpay-modal-popup {
    display: none;
  }

  .modals-wrapper {
    #afterpay-modal-popup {
      display: inherit;
    }
  }
  // End of afterpay modal flashing fix
}

@import '../settings/layout';
/**
 * Promo Banner
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--promo-banner {
  margin-top: 5px !important;
  margin-bottom: 50px !important;

  @include media('>=lg') {
    margin-top: 35px !important;
    margin-bottom: 60px !important;
  }

  @include media('>=tablet') {
    margin-top: 65px !important;
    margin-bottom: 100px !important;
  }

  @include media('>=desktop') {
    margin-top: 120px !important;
    margin-bottom: 150px !important;
  }
}

.pb-block--promo-banner-plp {
  margin-bottom: 70px !important;
}

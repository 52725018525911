@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins-pagebuilder';
/**
 * Links Row
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--links-row {
  background: $color--neutral--red-cream;

  &,
  [data-content-type='column'] {
    padding: 0 !important;
  }

  [data-content-type='buttons'] {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
    padding: 20px 0 !important;

    @include media('>=tablet') {
      flex-direction: row;
      padding: 0 !important;
    }
  }

  [data-content-type='button-item'] {
    padding: 11px 20px;
    text-transform: uppercase;
  }
}

@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
@import '../tools/mixins-form-elements';
/**
 * Contextual styling for the customer log-in, sign-up and password reset pages
 * !important used to override selector: form .field:not(.choice):not(.note):not(.qty)
 */

.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword {
  .login-container {
    $_spacing: 30px;

    @include media('<tablet') {
      @include flex-grid(1, 30px);
    }
    @include media('>=tablet') {
      @include flex-grid(2, 50px);
    }

    .block {
      .block-title {
        padding-bottom: 8px;
        border-bottom: $color--ui--border-light;

        h2 {
          @include media('<tablet') {
            font-size: 20px;
            letter-spacing: 0;
            line-height: 1;
          }
        }
      }
    }

    .warning-message-wrapper {
      @include warning-message();

      @include media('>=tablet') {
        letter-spacing: 0;
      }
    }
  }

  .form-create-account {
    .legend {
      width: 100%;
      padding-bottom: 8px;
      border-bottom: $color--ui--border-light;

      h2 {
        @include media('<tablet') {
          font-size: 20px;
          letter-spacing: 0;
          line-height: 1;
        }
      }
    }
  }

  .form {

  }

  .block-title {

  }

  .block-content {

  }

  .captcha-image {

    .action {

    }
  }

  .fieldset {
    padding: 0;

    &.create.info {
      margin-bottom: 30px;
    }
  }

  .legend {
    @include text--heading-3;
  }

  .fieldset.login {
    margin-top: 1em; // match p tags in new customers section
  }

  .field {
    &.note {
      margin-bottom: 1em;

      @include media('>=tablet') {
        letter-spacing: 0;
      }
    }

    &.captcha {
      // push siblings down to allow space for pos:abs captcha
      margin-bottom: 110px !important;
      @include media('>=small-tablet') {
        margin-bottom: 80px !important;
      }

      .nested {
        .field {
          background: transparent;
          padding: 0;
          margin: 10px 0;
          text-align: center;
          box-shadow: none !important; // override captcha field being highlighted from ancestor focus

          @include media('>=tablet') {
            text-align: left;
          }
        }

        .captcha-reload {
          transform: translate3d(0, -20px, 0);
        }
      }
    }

    .password-strength-meter-container {
      display: none; // Prevent it displaying until the it is moved
    }
  }

  .field-tooltip {
      position: relative;
      width: 90%;

      #tooltip-label {
          font-size: 0; // Hide text "Tooltip" on icon span

          &::before {
              content: none; // Remove fake checkbox styling
          }
      }

      .field-tooltip-action {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 26px;
          height: 26px;

          &::after {
              @include icon('circle-question', 'light');
              font-size: 24px;
          }

          &[aria-expanded="false"] + .field-tooltip-content {
              display: none;
          }
      }

      .field-tooltip-content {
          $caret-height: 10px;
          $caret-edge: 12px;
          $tooltip-border-color: #666666;
          $tooltip-color: #EEEEEE;

          position: absolute;
          top: calc(-1 * #{$caret-height});
          left: 42px;
          right: 0;
          border: 1px solid $tooltip-border-color;
          padding: 10px 16px;
          z-index: 1;
          background-color: $tooltip-color;

          // Tooltip caret common styles
          &::before, &::after {
              position: absolute;
              top: 10px;
              content: '';
              width: 0;
              height: 0;
              border-top: $caret-edge solid transparent;
              border-bottom: $caret-edge solid transparent;
          }

          // Tooltip caret
          &::after {
              left: calc(-1 * #{$caret-height});
              border-right: $caret-height solid $tooltip-color;
          }

          // Tooltip caret border
          &::before {
              left: calc(-1 * #{$caret-height} - 1px);
              border-right: $caret-height solid #333333; // Darker than tooltip border because line is thinner
          }
      }
  }

  .password-strength-meter-container {
    padding-left: $input-offset;
    margin-top: -18px;
    margin-bottom: 20px;

    @include media('>=tablet') {
      letter-spacing: 0;
    }
  }

  .actions-toolbar {
    display: table;
    margin: 0 auto;

    @include media('>=lg') {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin: 1rem 0;
    }

    @include media('>=tablet') {
      justify-content: space-between;
    }
  }

  .action {
    @include media("<lg") {
      display: block;
      width: 100%;
    }
  }

  .primary {
    padding: 0 0 15px;

    .action {
      @include button-style--primary;
    }

    @include media('>=lg') {
      padding: 0 15px 0 0;
    }
  }

  .secondary {
    @include media('>=lg') {
      padding-top: 0;
    }

    .action {
      @include button-style--ghost;
    }
  }
}

.customer-account-login,
.customer-account-create {
  .page-title-wrapper {
    margin-bottom: 30px;
  }

  .block-content {
    p {
      @include media('>=tablet') {
        letter-spacing: 0;
      }
    }
  }
}

@import '../settings/layout';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
@import '../elements/icons';
/**
 * Styling for the social links template
 */

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;

  .social-link {
    @include button-style--icon;
    font-size: 30px;
    padding: 10px;

    &::before {
      min-width: 1em;
    }

    &--facebook::before {
      @include icon('facebook', 'brands');
    }

    &--instagram {
      font-size: 34px;

      &::before {
        @include icon('instagram', 'brands');
      }
    }

    &--twitter::before {
      @include icon('twitter', 'brands');
    }

    &:hover::before {
      @include gold-text-gradient;
    }

    @include media('>=tablet') {
      font-size: 20px;

      &--instagram {
        font-size: 22px;
      }
    }
  }
}

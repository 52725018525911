@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins';
@import '../tools/mixins-button';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Hero Banner
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--hero-banner {
  position: relative;

  // override desktop background position
  @include media('<=tablet') {
    background-position: center center !important;
  }

  @include media('<=small-tablet') {
    background-position: top center !important;
  }

  .pb-block--hero-banner__content {
    z-index: 1;
    min-height: calc(85vh - 120px); // header height mobile

    @include media('>tablet') {
      min-height: calc(85vh - 220px); // header height tablet
    }

    @include media('>desktop') {
      min-height: 520px;
    }

    // default mobile layout
    @include media('<=tablet') {
      justify-content: flex-start !important;
      padding: 50px 30px 30px !important;
      text-align: center;
    }

    @include media('<lg') {
      padding: 30px 0 !important;

      h1 {
        @include text--heading-2;
      }
    }

    @include media('<md') {
      padding: 20px 0 !important;

      h1, h2 {
        @include text--heading-3;
      }

      [data-content-type='text'] span {
        font-size: $base-font-size !important;
      }
    }
  }

  [data-content-type='heading'] + [data-content-type='text'] {
    margin-top: 13px !important;
  }

  [data-content-type='text'] {
    p {
      @include first-last-no-end-margins(!important);
    }
  }

  [data-content-type='buttons'] {
    width: 100%;
    padding: 0 !important;
    margin-top: 10px !important;

    @include media('>md') {
      margin-top: 30px !important;
    }

    @include media('>tablet') {
      margin-top: 40px !important;
    }
  }

  [data-content-type='button-item'] {
    width: 100%;
    max-width: 320px;
  }

  @include media ('<=tablet') {
    [data-content-type='column'] {
      // hide non-content column desktop layout elements
      &:not(.pb-block--hero-banner__content) {
        display: none !important;
      }

      &.pb-block--hero-banner__content {
        width: 100% !important;
      }
    }
  }
}

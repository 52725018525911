/**
 * block-shipping styling
 */

.block.shipping {
  @import 'aligent-scss-placeholder/components/block-shipping';

  .content {

  }

  .legend { // This is a duplicate value of the title, so not too much point having it repeated

  }

  .fieldset {
    > .field { // There are .field class names all through this component, so have to be specific with which ones are targetted

    }
  }

  .select {

  }

  .methods { // <dl> Available shipping methods

    .item-title {

    }

    .item-options {

    }
  }
}

@import '../tools/mixins-typography';
@import '../tools/mixins';

.hero-product {
  @include clearfix;

  .hero-product__name {
    @include text--heading-4;
    margin-bottom: 25px;

    @include media('>=lg') {
      @include text--heading-3;
      margin-bottom: 25px;
    }

    @include media('>=tablet') {
      float: right;
      width: 40%;
      margin-bottom: 25px;
    }

    @include media('>=desktop') {
      @include text--heading-2;
      margin-bottom: 20px;
    }
  }

  .hero-product__image-container {
    position: relative;
    margin-bottom: 25px;

    @include media('>=tablet') {
      float: left;
      width: 60%;
      padding-right: 30px;
    }

    @include media('>=desktop') {
      margin-bottom: 0;
      padding-right: 70px;
    }
  }

  .product-item-photo {
    display: block;
    width: 100%;
  }

  .product-image-container {
    width: 100% !important; // overrides inline style
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 100%;
  }

  .product-image-wrapper {
    @include pos-abs-fill;
    padding-bottom: 0 !important; // overrides inline style
    display: block;
    height: 100%;
    width: 100%;
  }

  .product-image-photo {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .hero-product__details {
    margin-bottom: 25px;

    @include media('>=tablet') {
      float: right;
      width: 40%;
    }
  }

  .swatch-container {
    margin-bottom: 18px;
  }

  .old-price {
    @include text--product-card-old-price;
    padding-left: 8px;

    .price {
      text-decoration: line-through;
    }
  }

  .price-container {
    margin-bottom: 25px;
  }

  .actions .tocart.primary { // need specificity to override _product-info-main.scss
    width: 100%;

    @include media('>=tablet') {
      width: 200px;
    }
  }

  .hero-product__description {
    clear: both;

    p { // no class on pagebuilder text
      margin-top: 0;
    }

    @include media('>=desktop') {
      float: right;
      clear: none;
      width: 40%;
    }
  }
}

@import '../settings/colours';
@import '../tools/mixins-typography';
@import '../tools/mixins-button';
@import '../tools/mixins';

.product-item {
  $image-border-width: 1px;
  display: flex;
  margin-bottom: 70px;
  position: relative;

  .product-item-info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .product-image-container {
    display: block;
    position: relative;
    padding-bottom: 100%;
    width: 100% !important; // Overrides inline style
  }

  .product-image-wrapper {
    @include border-as-background-container($image-border-width, $color--ui--border-color-alpha);
    padding-bottom: $image-border-width !important; // Overrides inline style
    position: absolute;
    inset: 0;

    &:hover,
    &:active {
      @include border-as-background-container($image-border-width, $color--brand--primary-blend);
    }
  }

  .product-image-photo {
    display: block;
    background: $color--ui--bg-default;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .product-item-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
  }

  .product-item-name {
    @include text-style(
      $font-family: $font-family-serif,
      $font-size: 20px,
      $letter-spacing: 0.6px
    );
    margin: 0;
  }

  .price-final_price {
    margin-top: 8px;
    text-align: left;
  }

  .price-label {
    @include text--product-card-price-label--from-label;
    display: block;
  }

  .price {
    @include text--product-card-price;
  }

  .normal-price {
    @include text--product-card-price;
  }

  .special-price {
    @include text--product-card-price;
    display: block;
    margin-bottom: 5px;

    .price-label {
      display: none;
    }
  }

  .old-price {
    min-height: 28px; // Saves space for price to avoid page jank when selecting swatches

    .price-label {
      @include text--product-card-old-price-label;
      display: inline;
    }

    .price {
      @include text--product-card-old-price;
      text-decoration: line-through;
    }
  }

  .swatch-container {
    margin: 20px 0 5px;
    min-height: 34px; // Saves space while the swatches load
  }

  .swatch-attribute-options {
    $swatch-option-border-width: 2px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .swatch-option {
      @include border-as-background($swatch-option-border-width, $color--ui--border-color-light);
      font-size: 12px;
      line-height: 1;
      position: relative;
      min-width: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 8px;
      margin: 0 6px 6px 0;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
      }

      &.selected {
        @include border-as-background($swatch-option-border-width, $color--brand--primary-blend);
        font-weight: 700;
      }

      &[option-label='-'] {
        display: none;
      }
    }
  }

  .product-item-inner {
    margin-top: 5px;
    display: flex;
  }

  .action.tocart {
    @include button-style--primary;
    font-size: 14px;
    padding: 10px 26px;
  }

  .link-to-pdp {
    @include button--ghost-button;
  }

  .link-to-pdp__text-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stock-status.unavailable,
  .actions-primary .unavailable {
    display: flex;
    flex-wrap: wrap;
    gap: 0 0.8rem;
    color: $color--ui--out-of-stock-text;
    padding-top: 5px;
  }

  .in-store-only-link {
    @include button--ghost-button;
    margin-top: 0.8rem;
    padding-top: 3px;
  }

  .swatch-input {
    display: none;
  }

  .dinkus {
    @include pos-abs-fill;
    pointer-events: none;
    right: -5px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 98px;
      right: 0;
      height: 10px;
      width: 5px;
      background: $color--brand--primary-blend;
      z-index: -1;
    }
  }

  .dinkus__ribbon {
    background: $_color-gold-blend;
    position: absolute;
    top: 31px;
    right: -36px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 160px;
    text-align: center;
    transform: rotate(45deg);
  }

  .dinkus__text {
    @include text--dinkus;
  }
}

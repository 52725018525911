/**
 * Styling for the block collapsible nav element, typially used in the My Account section
 */

.block-collapsible-nav {
  @import 'aligent-scss-placeholder/components/block-collapsible-nav';

  // Use negative margin to span across the full screen width
  margin: 0 calc(-#{$gutter-tiny}) 0 calc(-#{$gutter-tiny});

  @include media('>=md') {
    margin: 0 calc(-#{$gutter-small}) 0 calc(-#{$gutter-small});
  }

  @include media('>=tablet') {
    margin: 0 30px 0 0;
    height: 100%;
  }

  .block-collapsible-nav-title {
    $vertical-padding: 15px;

    @include media('<tablet') {
      text-align: left;
      padding: $vertical-padding $gutter-tiny;
      border: 1px solid #EAEAEA;
    }

    @include media('>=md') {
      padding: $vertical-padding $gutter-small;
    }

    @include media('>=tablet') {
      padding: 0;
    }

    &::after {
      @include icon('angle-down', 'light');
      color: $_color-gold-dark;
      font-size: 26px;
      right: 30px;
    }

    &.active {
      @include media('<tablet') {
        border-bottom: 0;
      }

      &::after {
        @include icon('angle-up', 'light');
      }
    }
  }

  .block-collapsible-nav-content {
    @include media('<tablet') {
      border: 0;
    }

    @include media('>=tablet') {
      height: auto;
    }
  }

  .items {
    @include reset-ul;
    margin-bottom: 50px;
  }

  .item {
    &:hover {
      border-left: 3px solid $_color-harvest-gold;
    }

    @include media('<tablet') {
      &:not(:last-child) {
        border-bottom: 1px solid #EAEAEA;
      }
    }

    .delimiter {
      @include media('>=tablet') {
        display: block;
        margin: 10px 0;
        border-bottom: $color--ui--border-alpha-2;
      }
    }

    a,
    strong {
      @include media('<tablet') {
        padding: 15px 0;
      }
    }

    // Override style from 'aligent-scss-placeholder/components/block-collapsible-nav'
    a strong {
      padding: 0;
    }
  }
}

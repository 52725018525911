@import '../settings/layout';
@import '../tools/mixins';
/**
 * Contextual styling for each page
 */

.page-main {
  $padding-top: 20px;
  @include site-wrapper;

  @include media('>=md') {
    padding-top: $padding-top;
  }

  @include media('>=tablet') {
    padding-top: $padding-top;
  }
}

.column.main {
  margin-top: 20px;
}

// remove spacing
.cms-home,
.cms-page-view,
.catalog-category-view,
.catalogsearch-result-index,
.blog-page-list,
.stockists-index-index,
.stockists-store-view,
.checkout-index-index {
  .page-wrapper {
    padding-top: 0;
  }

  .column.main {
    margin-top: 0;
  }

  .page-main {
    padding-top: 0;
  }
}

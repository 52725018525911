@import '../settings/layout';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-typography';
/**
 * Listed Links
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--listed-links {
  [data-content-type='button-item'] {
    @include text--list-item-link;
    width: 100%;
    vertical-align: top;

    [data-element='link'] {
      padding: 3px 10px;
    }

    &:first-child {
      @include text--list-heading-link;
      width: 100% !important; // first item as list heading

      [data-element='link'] {
        padding: 3px 10px 8px;
      }
    }

    .pagebuilder-button-link,
    .pagebuilder-button-primary {
      display: block;
      text-align: left !important;
    }
  }

  @include media('<tablet') {
    [data-content-type='column-group'] {
      @include pb-columns(1, 30px);
      flex-direction: column;


      [data-element='link'] {
        text-align: center !important;
      }
    }
  }

  // Hide subheadings on <desktop
  @include media('<desktop') {
    [data-content-type='column-group'] {
      justify-content: space-between;

      .pagebuilder-column {
        width: auto !important;
      }
    }

    [data-content-type='button-item']:not(:first-child) {
      display: none !important;
    }
  }
}

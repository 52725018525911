@import '../tools/mixins';
@import '../tools/mixins-button';

.blog-post-view {
  .post-holder {
    padding: 0;
  }

  .page-title {
    padding: 50px 0 20px;
    display: block;
    text-align: center;
  }

  .post-info {
    text-align: center;
  }

  .post-holder {
    border: 0;
  }

  .product-items {
    text-align: left;
    margin: 0;
    padding: 0;

    @include media('>=tablet', '<desktop') {
      @include flex-grid(2, 48px, 70px);
    }

    @include media('>=desktop') {
      @include flex-grid(3, 32px, 70px);
    }
  }

  .product-item {
    .product-image-photo {
      height: 100%;
      margin: 0;
    }
  }

  .actions-secondary {
    display: none;
  }

  .post-content {
    .post-description {
      .pagebuilder-mobile-hidden,
      .pagebuilder-mobile-only {
        margin: auto;
      }

      p {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  [data-content-type='video'] {
    display: flex;
    justify-content: center;

    .pagebuilder-video-inner {
      width: 100%;
    }

    .pagebuilder-video-container {
      position: relative;
      width: 100%;
      padding-bottom: 9 / 16 * 100% !important;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .block-wishlist,
  .block-reorder,
  .block-categories,
  .block-tagclaud,
  .addthis_toolbox {
    display: none;
  }

  /**
   * Styles specific to recipe pages
   */
  .recipe-container {
    .post-info {
      display: none;
    }

    .recipe-download-link {
      p {
        margin-top: 0;
      }
    }
  }

  /**
   * Styles specific to blog pages
   */
  .blog-container {
    .post-posed-date {
      .label {
        display: none;
      }
    }

    .post-categories {
      display: none;
    }
  }
}

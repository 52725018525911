@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins-typography';

.order-gift-message {
  margin: 30px 0;

  .gift-message {
    @include text--small;
    margin-top: 10px;

    td {
      padding-top: 5px;
    }

    p {
      margin: 5px 0;
    }
  }

  .gift-message__label {
    color: $color--neutral--silver-chalice;
    font-weight: 600;
  }

  .gift-message__value {
    background: $color--neutral--red-cream;
    padding: 10px;
  }

  .gift-message__from-to td {
    @include media('<small-tablet') {
      display: block;
    }

    @include media('>=small-tablet') {
      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }
    }
  }

  .gift-message__message .gift-message__value {
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}

@import '../settings/colours';
@import '../tools/mixins';
@import '../tools/mixins-pagebuilder';
@import '../tools/mixins-button';
/**
 * Video Promo
 * Page Builder Block - !important used frequently to override inline styles
 */

.pb-block--video-promo {
  text-align: center;

  [data-content-type='row'] {
    @include site-wrapper(!important);
  }

  [data-content-type='text'] {
    @include text--home-paragraph-text;
    max-width: 800px;
    margin: auto !important;
    padding: 0 20px !important;

    @include media('>=md') {
      font-size: 19px;
    }
  }

  [data-content-type='button-item'] {
    @include button--more-cta-link-with-arrow--light-uppercase(
      $anchor-selector: '> a'
    );
  }

  .pagebuilder-video-container {
    position: relative;
    padding-bottom: 100% * $ratio-9-16;
    margin-top: 35px;
  }

  [data-element='video'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@import '../settings/layout';
@import '../tools/mixins-typography';
@import '../tools/mixins-button';
@import '../settings/colours';

.account-section {
  position: relative;
  margin-bottom: 30px;

  .account__email-wrapper {
    margin-bottom: 18px;
  }

  .account__email-prompt {
    @include text--checkout-base-text;
    margin: 20px 0;
  }

  .input-component {
    margin: 0;
  }

  .account__register-later-message {
    @include text--checkout-sub-text;
    color: $color--neutral--grey;
    margin: -15px 0 0; // due to email validation message margin.
  }

  .account-newsletter-container {
    @include text--checkout-base-text;
    line-height: 26px;
    margin: 0;
  }

  .account__privacy-policy {
    @include text--checkout-sub-text;
    color: $color--neutral--grey;
    margin: 0;
    padding-left: 32px;
    margin-bottom: 20px;

    a {
      color: $color--neutral--grey;
      text-decoration: underline;
    }
  }

  .react-checkout-loading__block {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .account__password-warning {
    position: relative;
    margin: 20px 0;

    &::before {
      // Before element used to create gradient border
      $border-width: 1px;
      border-radius: 10px;
      content: '';
      background: $color--brand--primary-blend;
      position: absolute;
      top: -$border-width;
      right: -$border-width;
      bottom: -$border-width;
      left: -$border-width;
      z-index: 0;
    }

    .warning-icon {
      font-size: 27px;
      margin-right: 8px;
    }

    .warning-icon::before {
      @include icon('exclamation-circle');
      flex-shrink: 0;
      color: $color--brand--primary;
    }
  }

  .account__password-warning-inner-wrapper {
    @include text--checkout-base-text;
    position: relative;
    background: $color--ui--bg-default;
    border-radius: 9px;
    padding: 25px 28px;
    display: flex;
    z-index: 1;
  }

  .account__reset-password-button {
    @include button--checkout-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    @include media('>=lg', '<desktop') {
      margin-right: 14px;
      width: 50%;
    }

    @include media('>=1200px') {
      margin-right: 14px;
      width: 50%;
    }
  }

  .account__reset-password-prompt {
    .account__guest-continue {
      padding: 0 15px;

      @include media('>=lg', '<desktop') {
        width: 50%;
      }

      @include media('>=1200px') {
        width: 50%;
      }
    }
  }

  .account__actions {
    display: flex;
    flex-direction: column;

    @include media('>=lg', '<desktop') {
      flex-direction: row;
    }

    @include media('>=1200px') {
      flex-direction: row;
    }
  }

  .checkout-account-info {
    font-size: 0.9rem;
    margin: 0 1rem 1rem;
    color: $color--ui--out-of-stock-text;
  }

  .account__login-section {
    margin: 20px 0 15px;
  }

  .account-login-button {
    @include button--checkout-primary;
    margin-bottom: 15px;

    @include media('>=lg', '<desktop') {
      margin-right: 14px;
      width: 40%;
    }

    @include media('>=1200px') {
      margin-right: 14px;
      width: 40%;
    }
  }

  .account__continue-button {
    @include button--checkout-ghost;
    width: 100%;

    &.account__continue-button--can-proceed {
      @include button--checkout-primary;
    }

    &:disabled:hover {
      color: $color--ui--button-primary-text;
    }
  }

  .account__guest-continue {
    @include button--checkout-ghost;
    padding: 10px 15px;
    margin-bottom: 15px;

    @include media('>=lg', '<desktop') {
      width: 60%;
    }

    @include media('>=1200px') {
      width: 60%;
    }
  }

  .account__forgot-password {
    @include button--padded-link;
    display: block;
    text-align: center;
  }
}

.account--logged-in {
  position: relative;

  .react-checkout-loading__block {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .account__welcome-back {
    @include text--checkout-welcome-back;
    margin-top: 20px;
  }

  .account__welcome-back-info {
    @include text--checkout-base-text;
    margin: 0 0 45px;
  }

  .account__logged-in-info {
    padding-right: 6px;
  }

  .account__logout-link {
    @include button--padded-link;
  }
}

.checkout-app-form__delivery {
  .stored-addresses,
  .deliver-manual-address {
    margin: 15px 0 50px;
  }

  .stored-addresses__new-address {
    margin-top: 12px;
    text-align: center;
  }

  .stored-addresses__new-address-button {
    @include button--padded-link;
  }

  .stored-address__edit {
    @include button--padded-link;
  }

  .new-address__actions {
    display: flex;
  }

  .new-address__action {
    @include button--checkout-primary;
    flex-grow: 1;
    margin-right: 15px;
  }

  .new-address__cancel {
    @include button--checkout-ghost;
    flex-grow: 1;
  }
}


.current-billing-address__checkbox {
  @include styled-checkbox(
    $input-class: '.current-billing-address__checkbox-input',
    $label-class: '.current-billing-address__checkbox-label'
  );
  margin-top: 20px;
}

@import '../tools/mixins';
@import '../tools/mixins-typography';

/**
 * Account Pages contextual styling
 */

body.account { // The create account form has the class name `account` as well, so need to scope this class to the body element
  #maincontent {
    @include site-wrapper;
    @include emblem-background;

    position: relative;
  }

  .columns {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @include media('>=tablet') {
      flex-direction: row;
    }
  }

  .sidebar-main { // account navigation
    @include media('>=tablet') {
      margin-right: 30px;
    }
  }

  .column.main {
    margin-top: 0;

    @include media('>=tablet') {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .send-date {
    @include text--medium;
    font-weight: 600;
    text-transform: uppercase;
    background: $color--neutral--gallery;
    padding: 8px 16px;
    margin-bottom: 10px;
  }

  .page-title-wrapper {
    margin-bottom: 24px;
  }

  .page-main {
    .action {
      @include button--action--small;

      &.primary {
        @include button-style--primary;
      }

      &.back {
        @include button-style--secondary;
      }

      &.previous,
      &.next {
        padding: 0;
        border: 0;
      }
    }

    .legend {
      @include text--heading-3;

      @include media('<tablet') {
        font-size: 24px;
      }
    }
  }

  .fieldset {
    @include media('<tablet') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .block {
    margin-bottom: 50px;
  }

  .block-title {
    @include text--heading-3;
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: $color--ui--border-light;

    h2 {
      @include media('<tablet') {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 1;
      }
    }

    > *:first-child {
      margin-right: 5px;
    }

    .action {
      margin-top: 10px;
      transform: translateY(-5px);
    }
  }

  .box-title {
    @include text--heading-5;
    margin-bottom: 0.5em;
  }

  .box-content {
    margin-bottom: 5px;
    padding-right: 10px;
  }

  .box {
    margin: 20px 0;

    p {
      margin-bottom: 0;
    }
  }

  address {
    font-style: normal;
  }

  .table-caption {
    @include visually-hidden;
  }

  form.my-orders-search-advanced {
    .field {
      .input-text {
        padding: 0 24px;
      }
    }
  }

  .order-history-message {
    @include warning-message($inner-selector: '.order-history-message-inner');
  }

  .block-order-details-view {
    margin-top: 50px;

    .block-content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      dl {
        margin-top: 0;
      }

      .box {
        width: 100%;
      }

      @include media('>=small-tablet') {
        .box {
          width: 50%;
        }
      }

      @include media('>=desktop') {
        flex-wrap: nowrap;
      }
    }
  }

  .order-details-items {
    overflow: hidden;
  }

  .order-links {
    list-style: none;
    padding: 0;

    .item {
      margin: 0 0 10px;

      // Items Ordered has strong not anchor tag
      > * {
        @include button-style--ghost;
        display: block;
      }

      strong {
        font-weight: inherit;
      }

      &.current > * {
        @include button-style--primary;
        display: block;
      }
    }

    @include media('>=small-tablet') {
      display: flex;

      .item {
        margin: 0 10px 0 0;
      }
    }
  }

  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;

    > table {
      min-width: 700px;
    }
  }

  .order-items,
  .order-items-shipment {
    margin: 0 -10px 20px;
  }

  .product-item-name {
    font-weight: 700;
  }

  .table-additional-addresses-items,
  .table-order-items,
  .table-subscriptions {
    @include text--account-table;
    width: 100%;
    border-collapse: collapse;

    thead,
    tbody {
      border-bottom: 2px solid $color--ui--border-color;
    }

    tbody tr:not(:last-child) .col {
      border-bottom: 1px solid $color--ui--border-color;
    }

    tfoot {
      th,
      td {
        padding: 0 10px;
        text-align: right;
      }

      .subtotal,
      .grand_total {
        th,
        td {
          padding-top: 20px;
        }
      }

      .grand_total {
        @include text--large;
        font-weight: 600;

        strong {
          font-weight: inherit;
        }
      }
    }

    .col {
      vertical-align: top;
      padding: 10px;

      &.price,
      &.qty,
      &.subtotal {
        text-align: right;
      }

      &.actions {
        padding-bottom: 15px;
        border-bottom: $color--ui--border-alpha-2;
      }
    }

    // These classes are added by magento but are receiving styles from global styling in _mixins-pagebuilder
    // Changing the pagebuilder classes would be difficult since they're manually written into pagebuilder blocks
    // in admin, so overriding here is preferable
    .col-0,
    .col-1,
    .col-2,
    .col-3,
    .col-4 {
       display: table-row;

      > * {
         display: table-cell;
         width: 100% !important; // important needed to override _mixins-pagebuilder
         padding: 0 10px !important; // important needed to override _mixins-pagebuilder
      }
    }

    .item-options dt {
      font-weight: 600;
    }

    .items-qty {
      list-style: none;
      margin-top: 0;
      padding: 0;

      .content {
        display: inline;
      }
    }
  }

  .order-products-toolbar {
    padding: 0 20px;
  }

  .pager {
    width: 100%;
  }

  .toolbar-amount,
  .limiter {
    display: none;
  }

  .pages {
    @include pagination;
  }

  .payment-method {
    .content {
      margin-left: 0;
    }

    .data.table {
      text-align: left;
    }
  }

  /**
   * Order Items & Shipment Pages
   */
  &.sales-order-view,
  &.sales-order-shipment,
  &.sales-order-invoice {
    .sidebar-main {
      display: none;
    }

    .page-title {
      display: inline-block;
      margin-right: 20px;
    }

    .order-status {
      display: inline-block;
      background: $color--neutral--mercury;
      margin: 10px 0;
      padding: 8px 14px;
      border-radius: $border-radius-small;

      @include media('>=small-tablet') {
        transform: translateY(-12px);
      }
    }

    .order-date {
      @include text--heading-3;

      .label,
      strong {
        display: none;
      }
    }

    .order-actions-toolbar .actions {
      display: flex;
      justify-content: space-between;
      width: 100%;

      a {
        @include button--action;
      }
    }
  }

  &.sales-order-view {
    .order-title {
      display: none;
    }
  }
}

@import '../tools/mixins-typography';
@import '../tools/mixins';
/**
 * Contextual styling for the PDP
 */

.catalog-product-view {
  .page-wrapper {
    @include media('<tablet') {
      padding-top: 0;

      .main {
        margin-top: 0;
      }
    }
  }

  .page-title-wrapper {
    .page-title {
      @include text--heading-4;
      padding-top: 2px;
      margin-bottom: 25px;

      @include media('>=tablet') {
        @include text--heading-3;
        font-size: 38px;
        margin-bottom: 30px;
      }
    }
  }

  .column.main {
    // The main container of the PDP specific elements; image, info, detailed info
  }

  .product-top-container {
    margin-bottom: 78px;

    @include media('>=tablet') {
      display: flex;
      margin: 78px 0;

      .product.media {
        width: 50%;
        padding-right: 30px;
      }

      .product-info-main {
        width: 50%;
      }
    }

    @include media('>=desktop') {
      .product.media {
        width: 60%;
        padding-right: 70px;
      }

      .product-info-main {
        width: 40%;
      }
    }
  }

  .product-info-main {
    margin-top: 12px;

    @include media('>=tablet') {
      margin-top: 0;
    }
  }

  &.page-product-giftcard {
    .product-top-container {
      @include media('>=tablet') {
        .product.media {
          width: 45%;
        }

        .product-info-main {
          width: 55%;
        }
      }

      @include media('>=desktop') {
        .product.media {
          padding-right: 70px;
        }
      }
    }

    .giftcard-price-wrapper {
      @include text--heading-2;
      padding-left: 16px;
      min-height: 48px;
      margin: 42px 0 25px;
    }
  }

  .gallery-placeholder__image {
    display: none;
  }
}

.ingredients-wrapper {
  label,
  .advisory,
  .precautionary  {
    font-weight: bold;
  }
}
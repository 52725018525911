@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins-button';
@import '../tools/mixins-typography';
/**
 * Newsletter Subscription Page
 */

.newsletter-subscribe {
  $_input-height: 50px;

  .newsletter-subscribe__heading {
    margin-bottom: 20px;
  }

  form {
    .field-wrapper {
      max-width: 500px;
    }

    .field {
      height: $_input-height !important; // !important to override .field:not(.choice):not(.note):not(.qty) in form.scss
    }

    .label {
      top: 11px !important; // !important to override .field:not(.choice):not(.note):not(.qty) in form.scss
    }

    .control {
      line-height: $_input-height !important; // !important to override .field:not(.choice):not(.note):not(.qty) in form.scss
      height: $_input-height !important; // !important to override .field:not(.choice):not(.note):not(.qty) in form.scss
    }

    button[type='submit'] {
      @include button-style--primary;

      @include media('<small-tablet') {
        width: 100%;
      }
    }

    @include media('>=small-tablet') {
      display: flex;

      .field-wrapper {
        width: 100%;
        margin-right: 10px;
      }
    }
  }
}

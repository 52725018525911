/**
 * checkout-shipping-method styling
 */

.checkout-shipping-method {
  @import 'aligent-scss-placeholder/components/checkout-shipping-method';

  .table-checkout-shipping-method {

    th,
    td {

    }

    .radio {

    }
  }

  .actions-toolbar {

  }
}

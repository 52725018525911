/**
 * Styling for the logo component
 */

.logo {
  .logo__img {
    width: auto;
    height: 60px;
  }
}

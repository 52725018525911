/**
 * discount-code styling
 */

.discount-code {
  @import 'aligent-scss-placeholder/components/discount-code';

  .payment-option-title, // Used in checkout
  .title { // Used in cart

  }

  .payment-option-content, // Used in checkout
  .content { // Used in cart

  }

  .label {

  }

  .field {

  }

  .primary {

  }

  .secondary {

  }

  .action {


    span {

    }
  }
}

/**
 * Contextual styling for the site footer
 */

.page-footer {
  margin-top: 70px;

  .footer {

  }

  .footer-columns-inner-container {
    // Custom container added through Layout XML
  }

  .footer-column + .footer-column {
    // Custom container added through Layout XML
  }

  .footer__socials-newsletter-container {
    // Custom container added through Layout XML
  }

  .aligent-social-links-container {
    // Custom container added through Layout XML
  }

  .back-to-top {
    // Custom container added through Layout XML
  }
}

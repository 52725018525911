@use 'setting/colours' as *;
@use 'elements/icons' as *;
@use 'tools/z-index' as *;
@use '@aligent/frontend-toolkit/scss/mixins/reset-button' as *;

.react-checkout-app__messages {
  @include zIndex('notices');
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.react-checkout-app__message {
  @include text--notices($color: $color--ui--info);
  position: relative;
  display: flex;
  justify-content: center;
  padding: 14px 40px;
  background-color: $color--ui--info-bg;
  text-align: center;

  &:last-child {
    border-radius: 0 0 $border-radius-large $border-radius-large;
  }
}

.react-checkout-app__message--error {
  background-color: $color--ui--error-bg;
  color: $color--ui--error;
}

.react-checkout-app__message--success {
  background-color: $color--ui--success-bg;
  color: $color--ui--success;
}

.react-checkout-app__messages--button {
  @include reset-button;
  padding: 0;
  color: inherit;
  position: absolute;
  top: 15px;
  right: 25px;

  &:hover,
  &:focus {
    @include reset-button;
  }

  &::after {
    @include icon('close', 'light');
  }
}

/**
 * checkout-signin-modal styling
 */

.checkout-signin-modal {
  @import 'aligent-scss-placeholder/components/checkout-signin-modal';

  &._show {

  }

  .action-close {

    &::before {

    }

    span {

    }
  }

  .field {

  }

  .actions-toolbar {

  }

  .secondary {

    .action {


      &:hover {

      }
    }
  }
}

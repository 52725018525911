@import '../tools/mixins-typography';
@import '../tools/mixins-button';
@import '../elements/icons';
@import '../tools/mixins';
/**
 * Styling for the Main information section on the pdp
 * The section containing product name, price, add to cart etc.
 */

.product-item-actions {
  .product-info-main {
    .quantity-control__control {
      .input-text {
        &.qty {
          width: 35px;
          height: 35px;
        }
      }

      @include media('>=desktop') {
        margin-bottom: 20px;
      }
    }

    .input-control {
      width: 26px;
      height: 26px;
      font-size: 18px;
    }
  }
}

.product-info-main {
  .breadcrumbs {
    display: none;

    @include media('>=tablet') {
      display: block;
      padding: 0;
      margin-bottom: 1.1rem;

      .items {
        display: block;
      }

      .item {
        display: inline;
        font-size: 13px;
        text-transform: capitalize;
        white-space: nowrap;

        &::before {
          content: '/';
        }

        &:first-child::before {
          content: '';
        }
      }
    }
  }

  .product-options-wrapper {
    margin-bottom: 22px;

    @include media('>desktop') {
      margin-bottom: 30px;
    }

    &.hidden {
      display: none;
    }
  }

  .swatch-opt {
    min-height: 91px; // To eliminate loading jitter
  }

  .swatch-attribute-label {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
  }

  .swatch-attribute-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    .swatch-option {
      @include button--radio;
      padding: 12px 8px;

      @include media('>=tablet') {
        padding: 16px 10px;
      }

      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }

    .swatch-option.selected {
      @include button--radio--selected;
    }
  }

  .swatch-attribute-selected-option {
    display: none;
  }

  .super-attribute-select {
    display: none;
  }

  .quantity-add-to-cart-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .quantity-control {
    margin-right: 20px;

    @include media('>=tablet') {
      margin-right: 30px;
    }

    @include media('>=desktop') {
      margin-right: 40px;
    }
  }

  .quantity-control__label {
    @include text--label-heading;
    font-size: 16px;
  }

  .quantity-control__control {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 12px 0 25px;

    .mage-error[for='qty'] {
      position: absolute;
      bottom: -24px;
    }
  }

  .input-text.qty {
    $input-size--mobile: 45px;
    $input-size--desktop: 50px;
    @include text--base;
    @include hide-number-input-spinners;
    order: 1;
    text-align: center;
    width: $input-size--mobile;
    height: $input-size--mobile;
    border: $color--ui--border-medium;
    margin: 0 16px;

    @include media('>=lg') {
      width: $input-size--desktop;
      height: $input-size--desktop;
    }
  }

  .input-control {
    $button-size--mobile: 30px;
    $button-size--desktop: 36px;
    @include button-style--icon;
    height: $button-size--mobile;
    width: $button-size--mobile;
    color: $color--ui--text-light-on-dark;
    background-color: $color--ui--bg-dark;
    font-size: 20px;
    text-align: center;
    border-radius: 50%;

    @include media('>=lg') {
      height: $button-size--desktop;
      width: $button-size--desktop;
    }

    &:disabled {
      background-color: rgba($color--ui--bg-dark, 0.4);
      cursor: default;
    }
  }

  .input-control--decrement {
    order: 0;

    &::before {
      @include icon('minus', 'light');
    }
  }

  .input-control--increment {
    order: 2;

    &::before {
      @include icon('plus', 'light');
    }
  }

  div.mage-error[for='qty'] {
    border-radius: 22px;
    margin-top: -25px;
    padding-top: 10px !important; // overrides inline style set by animation
    padding-bottom: 8px !important; // overrides inline style set by animation
  }

  .custom-attribute-display {
    @include text--custom-attribute-display;
    margin-top: 40px;

    @include media('>=desktop') {
      margin-top: 60px;
    }

    .attribute-value--bold {
      font-weight: 700;
    }
  }

  .custom-attribute-display__link {
    @include text--custom-attribute-display-link;
  }

  .actions {
    .tocart {
      @include button-style--primary;
      font-size: 14px;
      padding: 12px 33px;
      margin-bottom: 25px;

      @include media('>=lg') {
        font-size: 16px;
        padding: 16px 40px;
      }
    }
  }

  .product-info-price {
    margin-bottom: 25px;

    @include media('>=desktop') {
      margin-bottom: 30px;
    }
  }

  .special-price {
    padding: 0;

    .price-label {
      display: none;
    }

    .price-wrapper {
      @include text--pdp-price;
    }
  }

  .normal-price {
    @include text--pdp-price;

    .price-label {
      display: none;
    }
  }

  .old-price {
    .price-final_price {
      @include text--heading-5;
      display: block;
      margin: 0;
      text-transform: uppercase;

      .price {
        text-decoration: line-through;
      }
    }
  }

  .price-final_price[data-role='priceBox'] {
    @include text--pdp-price;
  }

  .actions {
    .product-info-price {
      // Hides duplicate
      display: none;
    }
  }

  .product-info-stock-sku {
    .available {
      display: none;
    }

    .unavailable {
      margin-bottom: 25px;
    }

    .out-of-stock-message {
      display: block;
    }
  }

  .stores-link {
    @include button--ghost-button;
    margin-top: 20px;
    padding-top: 3px;
  }
}
